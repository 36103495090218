import DraggableFlatList, {
    RenderItemParams,
    ScaleDecorator,
} from 'react-native-draggable-flatlist'
import React, { useEffect, useState } from 'react'
import { Text, View } from 'react-native'

import collect from 'collect.js'
import { styled } from 'dripsy'

const move = (from, to, arr) => {
    while (from < 0) {
        from += arr.length
    }
    while (to < 0) {
        to += arr.length
    }
    if (to >= arr.length) {
        let k = to - arr.length
        while (k-- + 1) {
            arr.push(undefined)
        }
    }
    arr.splice(to, 0, arr.splice(from, 1)[0])
    return arr
}

const StyledListItemContainer = styled(View)({
    flex: 1,
    backgroundColor: '#fff',
    marginLeft: 10,
    marginRight: 10,
    borderRadius: 5,
})

const StyledStatus = styled(View)({
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 25,
})

const StyledStatusText = styled(Text)({
    fontWeight: '600',
    fontSize: 20,
})

interface BBUIListContainerSortableListProps {
    children?: any
    testID?: string
    data?: any
    extraData?: any
    loading?: boolean
    isFetching?: boolean
    emptyMessage?: string
    count?: number
    refreshing?: boolean
    onRefresh?: () => void
    header?: ({}) => any
    footer?: ({}) => any
    placeholder?: ({ item, index }: { item: any; index: number }) => any
    onDragEnd: (plucked: any) => void
}

const BBUIListContainerSortableList: React.FC<BBUIListContainerSortableListProps> = ({
    data,
    children,
    isFetching,
    count,
    loading,
    testID,
    emptyMessage,
    refreshing,
    onRefresh,
    header = () => null,
    footer = () => null,
    placeholder = ({ item, index }) => null,
    onDragEnd,
}) => {
    const [dataScenes, setScenes] = useState(data)
    useEffect(() => {
        // Runs after the first render() lifecycle
        setScenes(data)
    }, [])
    return (
        <DraggableFlatList
            extraData={dataScenes}
            data={dataScenes}
            keyExtractor={(item, index) => `draggable-item-${item.id}`}
            contentContainerStyle={{ overflow: 'visible' }}
            renderItem={({ item, drag, isActive }: RenderItemParams<any>) => {
                return (
                    <ScaleDecorator>
                        {children({ item, drag, isActive })}
                    </ScaleDecorator>
                )
            }}
            refreshing={refreshing}
            onRefresh={onRefresh}
            ListEmptyComponent={
                <StyledStatus>
                    <StyledStatusText>{emptyMessage}</StyledStatusText>
                </StyledStatus>
            }
            renderPlaceholder={({ item, index }) =>
                placeholder({ item, index })
            }
            onDragEnd={({ data, from, to }) => {
                let moved = move(from, to, dataScenes)
                setScenes(moved)
                const collection = collect(moved)
                const plucked = collection.pluck('id')
                onDragEnd(plucked)
            }}
            ListHeaderComponent={header({})}
            ListFooterComponent={footer({})}
        />
    )
}

export default BBUIListContainerSortableList
