// Take a {label: 'test', value: 'test'} to ['test']
const BBUIGetTagsForEdit = (tags, isFullTags = false) => {
    let allTags = []
    if (isFullTags) {
        tags.map(item => {
            allTags.push({
                label: item.name,
                value: item.id,
            })
        })
    } else {
        tags.map(item => {
            allTags.push(item.id)
        })
    }
    return allTags
}

export default BBUIGetTagsForEdit
