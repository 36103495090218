import { BBUIPageModal } from '../..'
import BBUIPromptForm from '../bbui-prompt-form/bbui-prompt-form'
import React from 'react'
import useBBUINiceModalStore from '../bbui-nice-modal-store/bbui-nice-modal-store'

interface Props {
    componentId?: string
    title: string
    message?: string
    callback?: (text: string) => void
    type?: 'default' | 'plain-text' | 'secure-text' | 'login-password'
    defaultValue?: string
    keyboardType?:
        | 'default'
        | 'number-pad'
        | 'decimal-pad'
        | 'numeric'
        | 'email-address'
        | 'phone-pad'
        | 'url'
}

const BBUIPromptModal: React.FC<Props> = ({
    componentId = '',
    title,
    message = '',
    callback = text => {},
    type = 'default',
    defaultValue = '',
    keyboardType = 'default',
}) => {
    const { getModal, removeModal, hideModal } = useBBUINiceModalStore()
    const modal = getModal(componentId)
    const modalRef = React.useRef(null)

    return (
        <BBUIPageModal
            isActive
            isOpen={modal.visible}
            onAfterClose={() => {
                removeModal(componentId)
            }}
            onRequestClose={() => {
                hideModal(componentId)
            }}
            modalRef={modalRef}
            isScrollable={false}>
            <BBUIPromptForm
                componentId={componentId}
                title={title}
                message={message}
                callback={callback}
                type={type}
                defaultValue={defaultValue}
                keyboardType={keyboardType}
            />
        </BBUIPageModal>
    )
}
export default BBUIPromptModal
