import React from 'react'
import { Alert, Linking } from 'react-native'

const BBUIOpenUrlInBrowser = url => {
    Linking.canOpenURL(url).then(supported => {
        if (supported) {
            Linking.openURL(url)
        } else {
            Alert.alert(
                'Error opening url',
                `Please just type in or paste ${url} in your browser of choice!`,
                [
                    {
                        text: 'Copy & close',
                        onPress: () => {
                            // Clipboard.setString(url);
                        },
                    },
                ],
                { cancelable: false },
            )
        }
    })
}

export default BBUIOpenUrlInBrowser
