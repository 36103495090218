import Modal from 'react-modal'
import React from 'react'
import { View } from 'react-native'
import { styled } from 'dripsy'

const StyledContainer = styled(View)({
    flex: 1,
    backgroundColor: '$background',
})

export interface BBUIPageModalProps {
    isActive: boolean
    isOpen: boolean
    onAfterOpen?: any
    onAfterClose?: any
    onRequestClose?: any
    zIndex: string
    children: any
    modalRef?: any
    isScrollable?: boolean
}

const BBUIPageModal = ({
    isActive = true,
    isOpen,
    onAfterOpen = () => {},
    onAfterClose = () => {},
    onRequestClose = () => {},
    zIndex = '1000',
    children,
    modalRef = null,
    isScrollable = true,
}): any => {
    if (!isActive) return null

    return (
        <Modal
            ariaHideApp={false}
            isOpen={isOpen}
            onAfterOpen={onAfterOpen}
            onAfterClose={onAfterClose}
            onRequestClose={onRequestClose}
            style={{
                content: {
                    border: 0,
                    borderRadius: 0,
                    top: '0%',
                    left: '0%',
                    right: '0%',
                    bottom: '0%',
                    padding: '0',
                },
                overlay: {
                    zIndex: zIndex,
                },
            }}>
            {isScrollable ? (
                <div
                    id="scrollParent"
                    style={{
                        height: '100%',
                        overflow: 'auto',
                    }}
                    ref={modalRef}>
                    <StyledContainer>{children}</StyledContainer>
                </div>
            ) : (
                <StyledContainer>{children}</StyledContainer>
            )}
        </Modal>
    )
}

export default BBUIPageModal
