import { SafeAreaView, Text, TouchableOpacity, View } from 'react-native'

import BBUIPage from '../bbui-page/bbui-page'
import Icon from 'react-native-vector-icons/FontAwesome5'
import React from 'react'
import { styled } from 'dripsy'

interface StyleProps {
    backgroundColor: string
}
const StyledPageContent = styled(SafeAreaView)({
    flex: 1,
    backgroundColor: '$background',
})

const Container = styled(View)({
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
})

const BoxContainer = styled(View)({
    flex: 1,
    flexDirection: 'column',
    marginLeft: 10,
    marginRight: 10,
    backgroundColor: '#fff',
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
})
const Box = styled(View)({
    padding: 20,
})
const BoxTitle = styled(Text)({
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 1,
})
const BoxDescription = styled(Text)({
    fontSize: 15,
    fontWeight: '500',
    color: '#333',
})

const GoBackButton = styled(TouchableOpacity)({
    flexDirection: 'row',
    paddingTop: 10,
    borderColor: '#e6e6e6',
    borderTopWidth: 1,
    paddingBottom: 10,
    paddingLeft: 20,
    paddingRight: 20,
    alignItems: 'center',
})
const GoBackButtonLeft = styled(View)({
    width: 30,
    justifyContent: 'center',
    alignItems: 'center',
})
const GoBackButtonIcon = styled(View)({})
const GoBackButtonRight = styled(View)({
    paddingLeft: 10,
})
const GoBackButtonText = styled(Text)({
    fontSize: 17,
    fontWeight: '600',
})

interface Props {
    title?: string
    description?: string
    onRefreshPress?: () => void
    onBackPress?: () => void
    isRefreshing: boolean
}

const BBUIFormErrorLoading: React.FC<Props> = ({
    title = 'Error Loading',
    description = 'Try again later...',
    onRefreshPress,
    onBackPress,
    isRefreshing = false,
}) => {
    return (
        <BBUIPage isScrollable={false}>
            {({}) => {
                return (
                    <StyledPageContent>
                        <Container>
                            <BoxContainer>
                                <Box>
                                    <BoxTitle>{title}</BoxTitle>
                                    <BoxDescription>
                                        {description}
                                    </BoxDescription>
                                </Box>
                                <GoBackButton
                                    onPress={
                                        isRefreshing === false
                                            ? onRefreshPress
                                            : () => {}
                                    }>
                                    <GoBackButtonLeft>
                                        <GoBackButtonIcon>
                                            <Icon
                                                name="sync"
                                                size={25}
                                                color="#333"
                                            />
                                        </GoBackButtonIcon>
                                    </GoBackButtonLeft>
                                    <GoBackButtonRight>
                                        <GoBackButtonText>
                                            {isRefreshing
                                                ? `Refreshing...`
                                                : `Refresh`}
                                        </GoBackButtonText>
                                    </GoBackButtonRight>
                                </GoBackButton>
                                <GoBackButton onPress={onBackPress}>
                                    <GoBackButtonLeft>
                                        <GoBackButtonIcon>
                                            <Icon
                                                name="chevron-left"
                                                size={25}
                                                color="#333"
                                            />
                                        </GoBackButtonIcon>
                                    </GoBackButtonLeft>
                                    <GoBackButtonRight>
                                        <GoBackButtonText>
                                            Go Back
                                        </GoBackButtonText>
                                    </GoBackButtonRight>
                                </GoBackButton>
                            </BoxContainer>
                        </Container>
                    </StyledPageContent>
                )
            }}
        </BBUIPage>
    )
}

export default BBUIFormErrorLoading
