import React from 'react'

// Check if the user is logged in
const BBUIIsLoggedIn = async () => {
    try {
        const value = localStorage.getItem('@BigBearAccounts:token')
        if (value !== null) {
            // We have data!!
            return true
        }
        return false
    } catch (error) {
        // Error getting data
        return false
    }
}

export default BBUIIsLoggedIn
