import React, { useEffect, useMemo, useState } from 'react'
import { Text, View } from 'react-native'

import BBUICustomFlatList from '../bbui-custom-flatlist/bbui-custom-flatlist'
import { styled } from 'dripsy'

const StyledListItemContainer = styled(View)({
    flex: 1,
    backgroundColor: '#fff',
    marginLeft: 10,
    marginRight: 10,
    borderRadius: 5,
})

const StyledStatus = styled(View)({
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 10,
})

const StyledStatusText = styled(Text)({
    fontWeight: '600',
    fontSize: 20,
})

interface BBUIListContainerPaginationProps {
    children?: any
    estimatedItemSize?: number
    testID?: string
    data?: any
    extraData?: any
    fetchMore?: any
    loading?: boolean
    dataKey?: string
    isFetching?: boolean
    isFetchingMore?: string | boolean
    canFetchMore?: any
    dataKeyPaginating?: (data) => void
    emptyMessage?: string
    emptyComponent?: any
    count?: number
    refreshing?: boolean
    onRefresh?: () => void
}

const BBUIListContainerPagination = ({
    dataKey,
    dataKeyPaginating,
    data,
    children,
    fetchMore,
    isFetching,
    isFetchingMore,
    canFetchMore,
    count,
    loading,
    testID,
    emptyMessage,
    emptyComponent,
    refreshing,
    onRefresh,
}: BBUIListContainerPaginationProps) => {
    const [page, setPage] = useState(2)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        setPage(1)
        setIsLoading(false)
    }, [])

    const pageData = useMemo(() => {
        const allPages = data?.pages || []
        const allData = allPages.reduce((acc, page) => {
            const dataArr = page?.data?.flat() || []
            const filteredArr = dataArr.filter(
                (item, index) =>
                    dataArr.findIndex(obj => obj.id === item.id) === index,
            )
            return [...acc, ...filteredArr]
        }, [])

        return allData
    }, [data?.pages, fetchMore])

    const handleEndReached = () => {
        if (!isLoading && canFetchMore) {
            setIsLoading(true)
            fetchMore()
                .then(() => {
                    console.log('timeout', page)
                    setPage(page => page + 1)
                    setIsLoading(false)
                })
                .catch(() => {
                    setIsLoading(false)
                })
        }
    }

    return (
        <BBUICustomFlatList
            testID={testID}
            data={pageData}
            onEndReached={handleEndReached}
            onEndReachedThreshold={0.2}
            keyExtractor={(item: { id: string }, index) =>
                `${item.id}-${index}`
            }
            ListFooterComponent={
                refreshing && canFetchMore ? (
                    <StyledStatus>
                        <StyledStatusText>
                            {loading ? 'Loading...' : null}
                        </StyledStatusText>
                    </StyledStatus>
                ) : null
            }
            contentContainerStyle={{ overflow: 'auto' }}
            renderItem={({ item, index }: any) => {
                return (
                    <View style={{ flex: 1 }}>{children({ item, index })}</View>
                )
            }}
            refreshing={refreshing}
            onRefresh={onRefresh}
            emptyMessage={emptyMessage}
            ListEmptyComponent={
                <>
                    {emptyComponent ? (
                        emptyComponent
                    ) : (
                        <div>
                            <p>{emptyMessage}</p>
                        </div>
                    )}
                </>
            }
            canFetchMore={canFetchMore}
            page={page}
        />
    )
}

export default BBUIListContainerPagination
