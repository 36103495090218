import React from 'react'

interface BBUIFormOnSuccessProps {
    onAlert?: () => void
    onFormReset?: () => void
    onRefresh?: () => void
    onNavigate?: () => void
}

const BBUIFormOnSuccess = ({
    onAlert = () => {},
    onFormReset = () => {},
    onRefresh = () => {},
    onNavigate = () => {},
}: BBUIFormOnSuccessProps) => {
    onAlert()
    onFormReset()
    onRefresh()
    onNavigate()
}

export default BBUIFormOnSuccess
