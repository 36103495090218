import BBUIAlertModal from '../bbui-alert-modal/bbui-alert-modal'
import BBUIPush from '../bbui-push/bbui-push'
import React from 'react'

interface BBUIAlertErrorProps {
    title: string
    text: string
    okButtonText?: string
    onOkPress?: () => void
    router: any
    addModal: any
}

const BBUIAlertError = ({
    title,
    text,
    okButtonText = 'OK',
    onOkPress = () => {},
    router,
    addModal,
}: BBUIAlertErrorProps) => {
    BBUIPush({
        path: BBUIAlertModal,
        isModal: true,
        router,
        addModal,
        state: {
            title,
            message: text,
            buttons: [
                {
                    testID: 'ok-button',
                    text: okButtonText,
                    onPress: () => {
                        onOkPress()
                    },
                },
            ],
            options: { cancelable: false },
        },
    })
}

export default BBUIAlertError
