import { Theme } from '@dripsy/core'
import { theme } from './theme'

// make sure you don't mutate the original theme
export const boxinvyTheme: Theme = { ...theme }

boxinvyTheme.colors = {
    $background: '#815a2a',
    $authButtonText: '#fff',
    $authButton: '#815a2a',
}
