import React from 'react'
import { FlatList, View, Text } from 'react-native'
import { styled } from 'dripsy'

const StyledListItemContainer = styled(View)({
    flex: 1,
    backgroundColor: '#fff',
    marginLeft: 10,
    marginRight: 10,
    borderRadius: 5,
})

const StyledStatus = styled(View)({
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 25,
})

const StyledStatusText = styled(Text)({
    fontWeight: '600',
    fontSize: 20,
})

interface BBUIListContainerListProps {
    children?: any
    testID?: string
    data?: any
    extraData?: any
    loading?: boolean
    dataKey?: string
    isFetching?: boolean
    dataKeyPaginating?: (data) => void
    emptyMessage?: string
    count?: number
    refreshing?: boolean
    onRefresh?: () => void
}

class BBUIListContainerList extends React.Component<BBUIListContainerListProps> {
    render() {
        let {
            dataKey,
            dataKeyPaginating,
            data,
            children,
            isFetching,
            count,
            loading,
            testID,
            emptyMessage,
            refreshing,
            onRefresh,
        } = this.props
        return (
            <FlatList
                data={data}
                keyExtractor={(item: { id: string }) => item.id}
                contentContainerStyle={{ overflow: 'visible' }}
                renderItem={({ item, index }: any) => {
                    return (
                        <React.Fragment key={index}>
                            {children({ item, index })}
                        </React.Fragment>
                    )
                }}
                refreshing={refreshing}
                onRefresh={onRefresh}
                ListEmptyComponent={
                    <StyledStatus>
                        <StyledStatusText>{emptyMessage}</StyledStatusText>
                    </StyledStatus>
                }
            />
        )
    }
}

export default BBUIListContainerList
