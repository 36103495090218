import React, { useEffect } from 'react'

import dynamic from 'next/dynamic'

const DynamicComponentWithNoSSR = dynamic(
    () => import('../revolve-editor/revolve-editor'),
    {
        ssr: false,
    },
)

interface Props {
    hasChanged: (body, html) => void
    template?: String | Boolean
    templateUrl?: String
    uploadUrl: String
    variables: String[]
    type?: String
}

const BBUIEmailEditor: React.FC<Props> = ({
    hasChanged,
    template,
    templateUrl,
    uploadUrl,
    variables,
    type,
}) => {
    return (
        <DynamicComponentWithNoSSR
            hasChanged={hasChanged}
            template={template}
            templateUrl={templateUrl}
            uploadUrl={uploadUrl}
            variables={variables}
            type={type}
        />
    )
}

export default BBUIEmailEditor
