import { FlatList, Text, TouchableOpacity, View } from 'react-native'
import React, { useEffect } from 'react'

import { styled } from 'dripsy'

const StyledContainer = styled(View)(
    (props: { hasTopRounded: boolean; hasTopPadding: boolean }) => ({
        marginLeft: 10,
        marginRight: 10,
        borderTopLeftRadius: props.hasTopRounded ? 4 : 0,
        borderTopRightRadius: props.hasTopRounded ? 4 : 0,
        marginTop: props.hasTopPadding ? 10 : 0,
        backgroundColor: '#fff',
    }),
)

const StyledPill = styled(TouchableOpacity)((props: { active: boolean }) => ({
    padding: 20,
    borderBottomWidth: 3,
    borderColor: props.active ? '#444' : '#fff',
}))

const StyledPillText = styled(Text)({
    color: '#444',
    fontSize: 13,
    fontWeight: '700',
})

interface Action {
    testID?: string
    id: string
    title: string
    onPress: () => void
    active?: boolean
    isEnabled?: boolean
}

interface Props {
    testID?: string
    listRef?: React.RefObject<FlatList<Action>>
    initialIndex?: number
    actions: Action[]
    hasTopRounded?: boolean
    hasTopPadding?: boolean
}

const BBUIActionTabs: React.FC<Props> = ({
    testID = 'action-tabs',
    listRef = null,
    initialIndex = 0,
    actions,
    hasTopRounded = true,
    hasTopPadding = true,
}) => {
    if (listRef === null) {
        listRef = React.useRef()
    }
    useEffect(() => {
        if (listRef.current) {
            setTimeout(() => {
                try {
                    listRef?.current?.scrollToIndex({ index: initialIndex })
                } catch (e) {}
            }, 100)
        }
    }, [])
    return (
        <StyledContainer
            hasTopRounded={hasTopRounded}
            hasTopPadding={hasTopPadding}>
            <FlatList
                testID={testID}
                ref={listRef}
                data={actions}
                keyExtractor={(item: { id: string }) => item.id}
                horizontal
                pagingEnabled={false}
                showsHorizontalScrollIndicator={false}
                renderItem={({ item, index }: any) => {
                    if (item.isEnabled !== undefined && !item.isEnabled)
                        return null
                    return (
                        <StyledPill
                            testID={item.testID}
                            key={index}
                            onPress={() => {
                                item.onPress()
                                setTimeout(() => {
                                    listRef?.current?.scrollToIndex({ index })
                                }, 100)
                            }}
                            active={item.active}>
                            <StyledPillText>{item.title}</StyledPillText>
                        </StyledPill>
                    )
                }}></FlatList>
        </StyledContainer>
    )
}
export default BBUIActionTabs
