import Hashids from 'hashids'

const boxinvyHashids = new Hashids('BoxinvyHashIds', 13)
const doorknockerpalHashids = new Hashids('DoorKnockerPalHashIds', 13)
const wealtorHashids = new Hashids('Wealtor', 13)
const bigbearhealthHashids = new Hashids('BigBearHealthHashIds', 13)
const emailcoveHashids = new Hashids('EmailCoveHashIds', 13)
const mealinvyHashids = new Hashids('BigBearMealinvyHashIds', 13)
const bigbearmomentsHashids = new Hashids('BigBearMomentsHashIds', 13)
const goalpenseHashids = new Hashids('Goalpense', 13)
const panelbayHashids = new Hashids('eDdDfsVjso8NfPy4GfB8', 13)
const reviewpalHashids = new Hashids('4gcVHPzRKQcZMv9bimsJ', 13)

interface Props {
    id: string
    app?:
        | 'boxinvy'
        | 'doorknockerpal'
        | 'bigbearhealth'
        | 'emailcove'
        | 'mealinvy'
        | 'bigbearmoments'
        | 'goalpense'
        | 'panelbay'
        | 'wealtor'
        | 'reviewpal'
}

const BBUIEncode = (id, app = '') => {
    if (app === 'boxinvy') {
        return boxinvyHashids.encode(id)
    }
    if (app === 'doorknockerpal') {
        return doorknockerpalHashids.encode(id)
    }
    if (app === 'wealtor') {
        return wealtorHashids.encode(id)
    }
    if (app === 'bigbearhealth') {
        return bigbearhealthHashids.encode(id)
    }
    if (app === 'emailcove') {
        return emailcoveHashids.encode(id)
    }
    if (app === 'mealinvy') {
        return mealinvyHashids.encode(id)
    }
    if (app === 'bigbearmoments') {
        return bigbearmomentsHashids.encode(id)
    }
    if (app === 'goalpense') {
        return goalpenseHashids.encode(id)
    }
    if (app === 'panelbay') {
        return panelbayHashids.encode(id)
    }
    if (app === 'wealtor') {
        return wealtorHashids.encode(id)
    }
    if (app === 'reviewpal') {
        return reviewpalHashids.encode(id)
    }
    return id
}

export default BBUIEncode
