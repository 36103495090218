import BBUIAlertModal from '../bbui-alert-modal/bbui-alert-modal'
import BBUIBack from '../bbui-back/bbui-back'
import BBUIPush from '../bbui-push/bbui-push'
import { NextRouter } from 'next/router'
import React from 'react'

interface BBUIBackFormProps {
    componentId: string | undefined
    isFormDirty: boolean
    onTrashPress?: () => void
    isModal?: boolean
    addModal?: any
    hideModal?: any
    router: NextRouter
}

const BBUIBackForm = async ({
    componentId,
    isFormDirty,
    onTrashPress = () => {},
    isModal = false,
    addModal,
    hideModal,
    router,
}: BBUIBackFormProps) => {
    if (isFormDirty) {
        BBUIPush({
            path: BBUIAlertModal,
            isModal: true,
            addModal,
            router,
            state: {
                title: 'Are you sure?',
                message:
                    'You have some unsaved data you might want to save the form!',
                buttons: [
                    {
                        testID: 'dont-leave-button',
                        text: "Don't leave",
                        onPress: () => {},
                        style: 'cancel',
                    },
                    {
                        testID: 'erase-unsaved-data-button',
                        text: 'Erase Unsaved Data',
                        onPress: () => {
                            onTrashPress()
                            if (!isModal) {
                                BBUIBack(componentId, router)
                            }
                        },
                    },
                ],
                options: { cancelable: false },
            },
        })
    } else {
        if (isModal) {
            onTrashPress()
        } else {
            BBUIBack(componentId, router)
        }
    }
}

export default BBUIBackForm
