import React from 'react'
import { styled } from 'dripsy'
import { TouchableOpacity, Text } from 'react-native'

const StyledButton = styled(TouchableOpacity)({
    backgroundColor: 'white',
    alignItems: 'center',
    paddingTop: 13,
    paddingBottom: 13,
    marginLeft: 10,
    marginRight: 10,
    marginBottom: 10,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
})

const StyledButtonText = styled(Text)({
    fontSize: 18,
})

interface Props {
    onPress: () => void
}

// this is used on list views

const BBUIContentActionButton: React.FC<Props> = ({ onPress = () => {} }) => {
    return (
        <StyledButton onPress={onPress}>
            <StyledButtonText>Actions</StyledButtonText>
        </StyledButton>
    )
}

export default BBUIContentActionButton
