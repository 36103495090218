import { Alert } from 'react-native'
import BBUIAlertModal from '../bbui-alert-modal/bbui-alert-modal'
import BBUIPromptModal from '../bbui-prompt-modal/bbui-prompt-modal'
import BBUIPush from '../bbui-push/bbui-push'
import { NextRouter } from 'next/router'
import React from 'react'

interface BBUIPromptProps {
    componentId?: string
    title: string
    message?: string
    callback?: (text: string) => void
    type?: 'default' | 'plain-text' | 'secure-text' | 'login-password'
    defaultValue?: string
    keyboardType?:
        | 'default'
        | 'number-pad'
        | 'decimal-pad'
        | 'numeric'
        | 'email-address'
        | 'phone-pad'
        | 'url'
    router: NextRouter
    addModal: any
}

const BBUIPrompt = ({
    componentId,
    title,
    message,
    callback,
    type = 'default',
    defaultValue = '',
    keyboardType = 'default',
    router,
    addModal,
}: BBUIPromptProps) => {
    BBUIPush({
        path: BBUIPromptModal,
        isModal: true,
        addModal,
        router,
        state: {
            title,
            message,
            callback,
            type,
            defaultValue,
            keyboardType,
        },
    })
}

export default BBUIPrompt
