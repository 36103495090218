import BBUIGetAuthToken from '../bbui-get-auth-token/bbui-get-auth-token'
import Cookies from 'js-cookie'
import React from 'react'
import axios from 'axios'

interface BBUIAxiosQueryProps {
    url: string
    method:
        | 'get'
        | 'GET'
        | 'delete'
        | 'DELETE'
        | 'head'
        | 'HEAD'
        | 'options'
        | 'OPTIONS'
        | 'post'
        | 'POST'
        | 'put'
        | 'PUT'
        | 'patch'
        | 'PATCH'
        | 'purge'
        | 'PURGE'
        | 'link'
        | 'LINK'
        | 'unlink'
        | 'UNLINK'
    params?: any
    data?: any
    isTwoFactor?: boolean
}

const BBUIAxiosQuery = async ({
    url,
    method,
    params,
    data,
    isTwoFactor = false,
}: BBUIAxiosQueryProps) => {
    let token = BBUIGetAuthToken()

    if (isTwoFactor) {
        token = Cookies.get('BigBearAccount2')
    }

    const instance = axios.create({
        baseURL: process.env.NEXT_PUBLIC_APP_BASE_URL,
        timeout: 13000,
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
            accept: 'application/json',
            authorization: token ? `Bearer ${token}` : '',
        },
        withCredentials: true,
    })

    return instance.request({
        url,
        params,
        data,
        method,
    })
}

export default BBUIAxiosQuery
