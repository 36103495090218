import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { styled } from 'dripsy'
import { Text, TextInput, View } from 'react-native'

const Container = styled(View)({
    flex: 0,
    marginLeft: 10,
    marginRight: 10,
    marginBottom: 10,
})

const TextInputContainer = styled(View)({})
const TextInputField = styled(TextInput)({
    backgroundColor: '#fff',
    padding: 10,
    borderWidth: 1,
    borderColor: '#a2b09d',
    borderRadius: 5,
    fontSize: 15,
    color: '#363636',
})

const ErrorContainer = styled(View)({})
const ErrorText = styled(Text)({
    paddingTop: 10,
    fontSize: 15,
    color: 'red',
})

const TitleContainer = styled(View)({
    paddingBottom: 10,
})
const TitleText = styled(Text)({
    fontSize: 18,
    fontWeight: 'bold',
    color: '#333',
})

interface Props {
    testID?: string
    title: string
    fieldName: string
    isMultiline?: boolean
    keyboardType?:
        | 'default'
        | 'numeric'
        | 'email-address'
        | 'ascii-capable'
        | 'numbers-and-punctuation'
        | 'url'
        | 'number-pad'
        | 'phone-pad'
        | 'name-phone-pad'
        | 'decimal-pad'
        | 'twitter'
        | 'web-search'
        | 'visible-password'
    update: (value: any) => void
}

const BBUIFormTextInputArray: React.FC<Props> = ({
    testID = 'input',
    title,
    fieldName,
    isMultiline = false,
    keyboardType = 'default',
    update,
}) => {
    const {
        control,
        formState: { errors },
    } = useFormContext()
    return (
        <Container>
            <TitleContainer>
                <TitleText>{title}</TitleText>
            </TitleContainer>
            <TextInputContainer>
                <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                        <TextInputField
                            testID={testID}
                            multiline={isMultiline}
                            onBlur={onBlur}
                            onChangeText={newValue => {
                                update(newValue)
                            }}
                            value={value}
                            keyboardType={keyboardType}
                        />
                    )}
                    name={fieldName}
                />
            </TextInputContainer>
            <ErrorContainer>
                {errors[fieldName] && (
                    <ErrorText ellipsizeMode="tail" numberOfLines={2}>
                        {errors[fieldName]?.message}
                    </ErrorText>
                )}
            </ErrorContainer>
        </Container>
    )
}

export default BBUIFormTextInputArray
