import * as yup from 'yup'

import { BBUIAlertError, BBUIAxiosQuery, BBUIPage } from '../..'
import { Controller, useForm } from 'react-hook-form'
import {
    NativeMethods,
    Text,
    TextInput,
    TouchableOpacity,
    View,
} from 'react-native'
import React, { useRef } from 'react'

import { AuthPaths } from '../../index'
import qs from 'qs'
import { styled } from 'dripsy'
import useBBUINiceModalStore from '../bbui-nice-modal-store/bbui-nice-modal-store'
import { useMutation } from '@tanstack/react-query'
import { useRouter } from 'next/router'
import { yupResolver } from '@hookform/resolvers/yup'

const Container = styled(View)({
    flex: 1,
})
const Header = styled(View)({
    marginTop: 15,
    marginBottom: 15,
    marginLeft: 10,
    marginRight: 10,
})
const Title = styled(Text)({
    fontSize: 35,
    fontWeight: 'bold',
    color: '#FFF',
})
const Description = styled(Text)({
    fontSize: 16,
    color: '#FFF',
})
const UsernameTitle = styled(Text)({
    fontSize: 16,
    marginBottom: 10,
})
const Username = styled(TextInput)({
    backgroundColor: '#e5e6e4',
    borderRadius: 5,
    padding: 14,
    marginBottom: 10,
    borderColor: '#6e6f6d',
    borderWidth: 1,
    fontSize: 20,
})
const PasswordTitle = styled(Text)({
    fontSize: 16,
    marginBottom: 10,
})
const Password = styled(TextInput)({
    backgroundColor: '#e5e6e4',
    borderRadius: 5,
    padding: 15,
    borderColor: '#6e6f6d',
    borderWidth: 1,
    marginBottom: 10,
})
const Content = styled(View)({
    flex: 1,
    backgroundColor: 'white',
    borderTopLeftRadius: 25,
    borderTopRightRadius: 25,
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 20,
    paddingRight: 20,
    marginLeft: 10,
    marginRight: 10,
})
const Footer = styled(View)({
    backgroundColor: 'white',
    borderTopWidth: 1,
    borderTopColor: '#e5e6e4',
    borderBottomLeftRadius: 25,
    borderBottomRightRadius: 25,
    paddingTop: 5.1,
    paddingBottom: 20,
    paddingLeft: 20,
    paddingRight: 20,
    marginLeft: 10,
    marginRight: 10,
})
const LoginButton = styled(TouchableOpacity)({
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '$authButton',
    borderRadius: 10,
    padding: 15,
})
const LoginButtonText = styled(Text)({
    color: '$authButtonText',
    fontSize: 17,
})
const ForgotButton = styled(TouchableOpacity)({
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '$authButton',
    borderRadius: 10,
    marginTop: 15,
    padding: 15,
})
const ForgotButtonText = styled(Text)({
    color: '$authButtonText',
    fontSize: 17,
})
const RegisterButton = styled(TouchableOpacity)({
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '$authButton',
    borderRadius: 10,
    marginTop: 15,
    padding: 15,
})
const RegisterButtonText = styled(Text)({
    color: 'white',
    fontSize: 17,
})

const LegalButtons = styled(View)({
    flex: 1,
    flexDirection: 'row',
})

const TosButton = styled(TouchableOpacity)({
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '$authButton',
    borderRadius: 10,
    marginTop: 15,
    padding: 15,
    marginRight: 10,
})
const TosButtonText = styled(Text)({
    color: '$authButtonText',
    fontSize: 17,
})
const PrivacyButton = styled(TouchableOpacity)({
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '$authButton',
    borderRadius: 10,
    marginTop: 15,
    padding: 15,
})
const PrivacyButtonText = styled(Text)({
    color: '$authButtonText',
    fontSize: 17,
})

const FormErrorText = styled(Text)({
    color: 'red',
    fontSize: 17,
    marginBottom: 10,
})

const schema = yup.object().shape({
    code: yup.string().required('Code is a required field'),
})

interface Props {
    componentId: string
    appName: string
    appUrl: string
    year: string
    productId: number
    onSuccess: any
    loggedInComponentName: string
    registerComponentName: string
}

const BBUITwoFactorPage: React.FC<Props> = ({
    componentId,
    appName,
    appUrl,
    year = '2022',
    productId,
    onSuccess,
    loggedInComponentName = 'AuthLoading',
    registerComponentName = 'Register',
}) => {
    const { addModal } = useBBUINiceModalStore()

    const router = useRouter()

    const query = window.location.search
    const queryParsed = qs.parse(query)

    const loginUser = useMutation(
        async ({
            code,
        }: AuthPaths['/bigbearaccounts/api/v1/2fa/two-factor-challenge']['post']['requestBody']['application/json']) => {
            const data: AuthPaths['/bigbearaccounts/api/v1/2fa/two-factor-challenge']['post']['responses']['200']['application/json'] = await BBUIAxiosQuery(
                {
                    url: `/bigbearaccounts/api/v1/2fa/two-factor-challenge`,
                    data: {
                        code,
                    },
                    method: 'POST',
                    isTwoFactor: true,
                },
            )
            return data.data
        },
    )
    const {
        control,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            code: '',
        },
    })
    const username: { current: NativeMethods } = useRef(null)
    const password: { current: NativeMethods } = useRef(null)
    const onSubmit = data => {
        loginUser.mutate(
            {
                code: data.code,
            },
            {
                onSuccess: (
                    res: AuthPaths['/bigbearaccounts/api/v1/2fa/two-factor-challenge']['post']['responses']['200']['application/json']['data'],
                ) => {
                    onSuccess({
                        username: data.username,
                        password: data.password,
                        accept_token: queryParsed.inviteId,
                        deny_token: queryParsed.deny_token,
                        product_id: queryParsed.product_id,
                        redirect: queryParsed.redirect,
                    })
                },
                onError: err => {
                    BBUIAlertError({
                        title: ':(',
                        text: 'Two factor code is incorrect',
                        okButtonText: 'OK',
                        addModal,
                        router,
                    })
                },
            },
        )
    }
    return (
        <BBUIPage testID="scrollView" isScrollable={false} hasFormLogin>
            {({}) => {
                return (
                    <Container>
                        <Header>
                            <Title>Two-Factor Authentication</Title>
                            <Description>
                                Enter an authenticator app code or a recovery
                                code:
                            </Description>
                        </Header>
                        <Content>
                            <UsernameTitle>Code</UsernameTitle>
                            <Controller
                                control={control}
                                rules={{
                                    required: true,
                                }}
                                render={({
                                    field: { onChange, onBlur, value },
                                }) => (
                                    <Password
                                        autoFocus={true}
                                        testID="otp-input"
                                        // ref={password}
                                        keyboardType="number-pad"
                                        autoCorrect={false}
                                        // @ts-ignore
                                        autoComplete={'one-time-code'}
                                        textContentType="oneTimeCode"
                                        autoCapitalize={'none'}
                                        returnKeyType="done"
                                        onBlur={onBlur}
                                        onChangeText={onChange}
                                        value={value}
                                    />
                                )}
                                name="code"
                            />
                            {errors.code && (
                                <FormErrorText>
                                    {errors.code.message}
                                </FormErrorText>
                            )}

                            {!loginUser.isLoading ? (
                                <LoginButton
                                    testID="otp-button"
                                    onPress={handleSubmit(onSubmit)}>
                                    <LoginButtonText>Log in</LoginButtonText>
                                </LoginButton>
                            ) : (
                                <LoginButton>
                                    <LoginButtonText>
                                        Loading...
                                    </LoginButtonText>
                                </LoginButton>
                            )}
                        </Content>
                        <Footer></Footer>
                    </Container>
                )
            }}
        </BBUIPage>
    )
}

export default BBUITwoFactorPage
