import { Theme } from '@dripsy/core'
import { theme } from './theme'

// make sure you don't mutate the original theme
export const wealtorTheme: Theme = { ...theme }

wealtorTheme.colors = {
    $background: '#581C2F',
    $authButtonText: '#fff',
    $authButton: '#581C2F',
}
