import * as yup from 'yup'

import { BBUIForm, BBUIFormTextInput } from '../..'
import { FormProvider, useForm } from 'react-hook-form'
import React, { useState } from 'react'

import BBUIPage from '../bbui-page/bbui-page'
import BBUIPromptTopbar from '../bbui-prompt-topbar/bbui-prompt-topbar'
import useBBUINiceModalStore from '../bbui-nice-modal-store/bbui-nice-modal-store'
import { useRouter } from 'next/router'
import { yupResolver } from '@hookform/resolvers/yup'

const schema = yup.object().shape({})

interface Props {
    componentId?: string
    title: string
    message?: string
    callback?: (text: string) => void
    type?: 'default' | 'plain-text' | 'secure-text' | 'login-password'
    defaultValue?: string
    keyboardType?:
        | 'default'
        | 'number-pad'
        | 'decimal-pad'
        | 'numeric'
        | 'email-address'
        | 'phone-pad'
        | 'url'
}

const BBUIPromptForm: React.FC<Props> = ({
    componentId = '',
    title,
    message = '',
    callback = text => {},
    type = 'default',
    defaultValue = '',
    keyboardType = 'default',
}) => {
    const router = useRouter()
    const { addModal, hideModal } = useBBUINiceModalStore()

    const [isLeavingForm, setIsLeavingForm] = useState(false)

    const methods = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            text: defaultValue,
        },
        mode: 'onChange',
    })

    const onSubmit = data => {
        let values = data

        callback(values.text)

        hideModal(componentId)
    }

    return (
        <FormProvider {...methods}>
            <BBUIPage
                isScrollable
                hasForm
                keyboardShouldPersistTaps="always"
                isModal
                topChildren={
                    <>
                        <BBUIPromptTopbar
                            componentId={componentId}
                            onSubmit={onSubmit}
                            isLoading={false}
                            title={title}
                        />
                    </>
                }>
                {({}) => {
                    return (
                        <>
                            <BBUIForm>
                                <BBUIFormTextInput
                                    testID="bbui-prompt-form-text-input"
                                    title={title}
                                    fieldName="text"
                                />
                            </BBUIForm>
                        </>
                    )
                }}
            </BBUIPage>
        </FormProvider>
    )
}

export default BBUIPromptForm
