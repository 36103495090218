import { Theme } from '@dripsy/core'
import { theme } from './theme'

// make sure you don't mutate the original theme
export const panelbayTheme: Theme = { ...theme }

panelbayTheme.colors = {
    $background: '#EB4D02',
    $authButtonText: '#fff',
    $authButton: '#EB4D02',
}
