import { Text, TouchableOpacity, View } from 'react-native'

import Icon from 'react-native-vector-icons/FontAwesome5'
import React from 'react'
import { styled } from 'dripsy'

const Container = styled(TouchableOpacity)({
    flex: 1,
    flexDirection: 'row',
    padding: 20,
    paddingLeft: 10,
    paddingRight: 10,
    borderBottomWidth: 1,
    borderBottomColor: '#ebebeb',
})
const IconContainer = styled(View)({})
const LeftContainer = styled(View)({
    marginRight: 10,
    width: 50,
    alignItems: 'center',
})
const RightContainer = styled(View)({
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
})
const TitleContainer = styled(View)({})
const TitleText = styled(Text)({
    fontWeight: '500',
    fontSize: 19,
})
const DescriptionContainer = styled(View)({
    marginTop: 2,
})
const DescriptionText = styled(Text)({
    fontSize: 14,
    color: '#444',
    fontWeight: '400',
})

interface Props {
    testID?: string
    icon: string
    title: string
    description: string
    onPress: () => void
    isVisible?: boolean
}

const BBUIContentHomeItem: React.FC<Props> = ({
    testID = '',
    icon,
    title,
    description,
    onPress,
    isVisible = true,
}) => {
    if (!isVisible) {
        return null
    }

    return (
        <Container testID={testID} onPress={onPress}>
            <LeftContainer>
                <IconContainer>
                    <Icon name={icon} size={40} color="#000" />
                </IconContainer>
            </LeftContainer>
            <RightContainer>
                <TitleContainer>
                    <TitleText>{title}</TitleText>
                </TitleContainer>
                <DescriptionContainer>
                    <DescriptionText>{description}</DescriptionText>
                </DescriptionContainer>
            </RightContainer>
        </Container>
    )
}

export default BBUIContentHomeItem
