import { Theme } from '@dripsy/core'
import { theme } from './theme'

// make sure you don't mutate the original theme
export const goalpenseTheme: Theme = { ...theme }

goalpenseTheme.colors = {
    $background: '#ea621f',
    $authButtonText: '#fff',
    $authButton: '#ea621f',
}
