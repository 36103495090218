import React from 'react'
import { styled } from 'dripsy'
import { View } from 'react-native'

// Make the form components into a horizontal stack

const Container = styled(View)({
    flex: 1,
    flexDirection: 'row',
})

interface Props {
    children: React.ReactNode
}

const BBUIHorizontalFormGroup: React.FC<Props> = ({ children }) => {
    return <Container>{children}</Container>
}

export default BBUIHorizontalFormGroup
