const ApiCodeMessages = {
  100: {
    type: "error",
    name: "module_not_found",
    title: "Module Not Found",
    message: "The module was not found!",
    fieldType: "alert",
  },
  101: {
    type: "error",
    name: "phone_number_not_correct",
    title: "Phone Number Not Correct",
    message: "Phone Number Not Correct",
    fieldType: "alert",
  },
  102: {
    type: "error",
    name: "crm_call_not_found",
    title: "Call Not Found",
    message: "Call Not Found",
    fieldType: "alert",
  },
  103: {
    type: "error",
    name: "over_quota_images",
    title: "Over Quota",
    message: "You're over your limit on images!",
    fieldType: "alert",
  },
  104: {
    type: "error",
    name: "over_quota_expenses",
    title: "Over Quota",
    message: "You're over your limit on expenses!",
    fieldType: "alert",
  },
  105: {
    type: "error",
    name: "over_quota_accounts",
    title: "Over Quota",
    message: "You're over your limit on accounts!",
    fieldType: "alert",
  },
  106: {
    type: "error",
    name: "over_quota_categories",
    title: "Over Quota",
    message: "You're over your limit on categories!",
    fieldType: "alert",
  },
  107: {
    type: "error",
    name: "over_quota_goals",
    title: "Over Quota",
    message: "You're over your limit on goals!",
    fieldType: "alert",
  },
  108: {
    type: "error",
    name: "over_quota_deals",
    title: "Over Quota",
    message: "You're over your limit on deals!",
    fieldType: "alert",
  },
  109: {
    type: "error",
    name: "user_is_unauthorized",
    title: "Unauthorized",
    message: "You're unauthorized to access this!",
    fieldType: "alert",
  },
  110: {
    type: "error",
    name: "already_subscribed",
    title: "Subscribed",
    message: "You're already subscribed to this product!",
    fieldType: "alert",
  },
  111: {
    type: "error",
    name: "invite_token_not_found",
    title: "Not Found",
    message: "This invite token was not found!",
    fieldType: "alert",
  },
  112: {
    type: "error",
    name: "user_is_not_in_team",
    title: "Not In Team",
    message: "User is not in team!",
    fieldType: "alert",
  },
  113: {
    type: "error",
    name: "device_not_found",
    title: "Device Not Found",
    message: "This device was not found!",
    fieldType: "alert",
  },
  114: {
    type: "error",
    name: "over_quota_devices",
    title: "Over Quota",
    message: "You're over your limit on devices!",
    fieldType: "alert",
  },
  115: {
    type: "error",
    name: "qrcode",
    title: "Duplicate Barcode",
    message: "This barcode is assigned to another record already!",
    fieldType: "field",
  },
  116: {
    type: "error",
    name: "image_not_found",
    title: "Not Found",
    message: "This image was not found!",
    fieldType: "alert",
  },
  117: {
    type: "error",
    name: "location_not_found",
    title: "Not Found",
    message: "This location was not found!",
    fieldType: "alert",
  },
  118: {
    type: "error",
    name: "over_quota_locations",
    title: "Over Quota",
    message: "You're over your limit on locations!",
    fieldType: "alert",
  },
  119: {
    type: "error",
    name: "panel_not_found",
    title: "Not Found",
    message: "This panel was not found!",
    fieldType: "alert",
  },
  120: {
    type: "error",
    name: "over_quota_panels",
    title: "Over Quota",
    message: "You're over your limit on panels!",
    fieldType: "alert",
  },
  121: {
    type: "error",
    name: "item_not_found",
    title: "Not Found",
    message: "This item was not found!",
    fieldType: "alert",
  },
  122: {
    type: "error",
    name: "over_quota_items",
    title: "Over Quota",
    message: "You're over your limit on items!",
    fieldType: "alert",
  },
  123: {
    type: "error",
    name: "cannot_link_same_panel_to_itself",
    title: "Validation Error",
    message: "You cannot link the same panel to itself!",
    fieldType: "alert",
  },
  124: {
    type: "error",
    name: "cannot_link_same_item_to_itself",
    title: "Validation Error",
    message: "You cannot link the same item to itself!",
    fieldType: "alert",
  },
  125: {
    type: "error",
    name: "current_password_does_not_match",
    title: "Validation Error",
    message: "Current password does not match",
    fieldType: "alert",
  },
  126: {
    type: "error",
    name: "moment_not_found",
    title: "Not Found",
    message: "This moment was not found!",
    fieldType: "alert",
  },
  127: {
    type: "error",
    name: "over_quota_moments",
    title: "Over Quota",
    message:
      "You're over you quota for moment. You should consider upgrading you membership!",
    fieldType: "alert",
  },
  128: {
    type: "error",
    name: "interaction_not_found",
    title: "Not Found",
    message: "This interaction was not found!",
    fieldType: "alert",
  },
  129: {
    type: "error",
    name: "people_not_found",
    title: "Not Found",
    message: "This person was not found!",
    fieldType: "alert",
  },
  130: {
    type: "error",
    name: "conversation_not_found",
    title: "Not Found",
    message: "This conversation was not found!",
    fieldType: "alert",
  },
  131: {
    type: "error",
    name: "over_quota_conversations",
    title: "Over Quota",
    message: "You have reached your subscription conversation quota!",
    fieldType: "alert",
  },
  132: {
    type: "error",
    name: "conversation_cannot_update",
    title: "Not Allowed",
    message: "You can not update this conversation!",
    fieldType: "alert",
  },
  133: {
    type: "error",
    name: "missing_cove_namespace_id",
    title: "Validation Error",
    message: "Missing Cove Namespace ID",
    fieldType: "alert",
  },
  134: {
    type: "error",
    name: "missing_cove_id",
    title: "Validation Error",
    message: "Missing Cove ID!",
    fieldType: "alert",
  },
  135: {
    type: "error",
    name: "destination_not_found",
    title: "Not Found",
    message: "Destination was not found!",
    fieldType: "alert",
  },
  136: {
    type: "error",
    name: "over_destination_quota",
    title: "Over Quota",
    message: "Over destination subscription quota!",
    fieldType: "alert",
  },
  137: {
    type: "error",
    name: "duplicate_destination",
    title: "Duplicate Destination Not Allowed",
    message: "You already have a destination with this email!",
    fieldType: "alert",
  },
  138: {
    type: "error",
    name: "email_not_found",
    title: "Not Found",
    message: "This email was not found!",
    fieldType: "alert",
  },
  139: {
    type: "error",
    name: "forwarder_not_found",
    title: "Not Found",
    message: "This forwarder was not found!",
    fieldType: "alert",
  },
  140: {
    type: "error",
    name: "over_forwarder_quota",
    title: "Over Quota",
    message: "You are over your cove quota!",
    fieldType: "alert",
  },
  141: {
    type: "error",
    name: "duplicate_forwarder",
    title: "Duplicate Cove",
    message: "You already have a cove with this name!",
    fieldType: "alert",
  },
  142: {
    type: "error",
    name: "cove_cannot_update",
    title: "Now Allowed",
    message:
      "This cove has already been used so it can not be updated anymore!",
    fieldType: "alert",
  },
  143: {
    type: "error",
    name: "namespace_not_found",
    title: "Now Found",
    message: "This cove namespace was not found!",
    fieldType: "alert",
  },
  144: {
    type: "error",
    name: "over_namespace_quota",
    title: "Over Quota",
    message: "You're over you namespace quota!",
    fieldType: "alert",
  },
  145: {
    type: "error",
    name: "duplicate_namespace",
    title: "Duplicate Namespace",
    message: "This namespace is already taken!",
    fieldType: "alert",
  },
  146: {
    type: "error",
    name: "rule_not_found",
    title: "Not Found",
    message: "This rule was not found!",
    fieldType: "alert",
  },
  147: {
    type: "error",
    name: "over_rule_quota",
    title: "Over Quota",
    message: "You're over your quota for rules!",
    fieldType: "alert",
  },
  148: {
    type: "error",
    name: "update_card_error",
    title: "Error Updating Card",
    message: "There was a error updating your card. Please try, again!",
    fieldType: "alert",
  },
  149: {
    type: "error",
    name: "card_error_cvc_failed",
    title: "Card CVC Wrong",
    message: "Your card's security code is incorrect.",
    fieldType: "alert",
  },
  150: {
    type: "error",
    name: "card_declined",
    title: "Card Declined",
    message: "Your card was declined.",
    fieldType: "alert",
  },
  151: {
    type: "error",
    name: "card_expired",
    title: "Card Expired",
    message: "Your card has expired.",
    fieldType: "alert",
  },
  152: {
    type: "error",
    name: "card_processing_error",
    title: "Card Processing Error",
    message:
      "There was a processing error on our side. If this continues please contact us in the bottom right in the chat!",
    fieldType: "alert",
  },
  153: {
    type: "error",
    name: "payment_method_invalid",
    title: "Charge Failed",
    message: "Payment method is invalid!",
    fieldType: "alert",
  },
  154: {
    type: "error",
    name: "card_insufficient_funds",
    title: "Charge Failed",
    message: "Your card has insufficient funds.",
    fieldType: "alert",
  },
  155: {
    type: "error",
    name: "bookmark_not_found",
    title: "Not Found",
    message: "This bookmark was not found.",
    fieldType: "alert",
  },
  156: {
    type: "error",
    name: "over_bookmark_quota",
    title: "Over Quota",
    message: "You're over your bookmark quota!",
    fieldType: "alert",
  },
  157: {
    type: "error",
    name: "box_not_found",
    title: "Not Found",
    message: "This box was not found!",
    fieldType: "alert",
  },
  158: {
    type: "error",
    name: "over_box_quota",
    title: "Over Quota",
    message: "You're over your box quota!",
    fieldType: "alert",
  },
  159: {
    type: "error",
    name: "brand_not_found",
    title: "Not Found",
    message: "This brand was not found!",
    fieldType: "alert",
  },
  160: {
    type: "error",
    name: "over_brand_quota",
    title: "Over Quota",
    message: "You're over your brand quota!",
    fieldType: "alert",
  },
  161: {
    type: "error",
    name: "category_not_found",
    title: "Not Found",
    message: "This category was not found!",
    fieldType: "alert",
  },
  162: {
    type: "error",
    name: "over_category_quota",
    title: "Over Quota",
    message: "You're over your category quota!",
    fieldType: "alert",
  },
  163: {
    type: "error",
    name: "insurance_agency_not_found",
    title: "Not Found",
    message: "This insurance agency was not found!",
    fieldType: "alert",
  },
  164: {
    type: "error",
    name: "over_insurance_agency_quota",
    title: "Over Quota",
    message: "You're over your insurance agency quota!",
    fieldType: "alert",
  },
  165: {
    type: "error",
    name: "insurance_agent_not_found",
    title: "Not Found",
    message: "This insurance agent was not found!",
    fieldType: "alert",
  },
  166: {
    type: "error",
    name: "over_insurance_agent_quota",
    title: "Over Quota",
    message: "You're over your insurance agent quota!",
    fieldType: "alert",
  },
  167: {
    type: "error",
    name: "insurance_company_not_found",
    title: "Not Found",
    message: "This insurance company was not found!",
    fieldType: "alert",
  },
  168: {
    type: "error",
    name: "over_insurance_company_quota",
    title: "Over Quota",
    message: "You're over your insurance company quota!",
    fieldType: "alert",
  },
  169: {
    type: "error",
    name: "over_insurance_policy_quota",
    title: "Over Quota",
    message: "You're over your insurance policy quota!",
    fieldType: "alert",
  },
  170: {
    type: "error",
    name: "over_item_quota",
    title: "Over Quota",
    message: "You're over your item quota!",
    fieldType: "alert",
  },
  171: {
    type: "error",
    name: "over_location_quota",
    title: "Over Quota",
    message: "You're over your location quota!",
    fieldType: "alert",
  },
  172: {
    type: "error",
    name: "qrcode_not_found",
    title: "Not Found",
    message: "This qrcode was not found!",
    fieldType: "alert",
  },
  173: {
    type: "error",
    name: "over_qrcode_quota",
    title: "Over Quota",
    message: "You're over your qrcode quota!",
    fieldType: "alert",
  },
  174: {
    type: "error",
    name: "store_not_found",
    title: "Not Found",
    message: "This store was not found!",
    fieldType: "alert",
  },
  175: {
    type: "error",
    name: "over_store_quota",
    title: "Over Quota",
    message: "You're over your store quota!",
    fieldType: "alert",
  },
  176: {
    type: "error",
    name: "email",
    title: "Duplicated email",
    message: "This email is taken!",
    fieldType: "field",
  },
  177: {
    type: "error",
    name: "category_not_found",
    title: "Not Found",
    message: "This category was not found!",
    fieldType: "alert",
  },
  178: {
    type: "error",
    name: "deal_action_amount_type_not_found",
    title: "Not Found",
    message: "This Deal Action Amount Type was not found!",
    fieldType: "alert",
  },
  179: {
    type: "error",
    name: "deal_action_not_found",
    title: "Not Found",
    message: "This Deal Action was not found!",
    fieldType: "alert",
  },
  180: {
    type: "error",
    name: "deal_action_type_not_found",
    title: "Not Found",
    message: "This Deal Action Type was not found!",
    fieldType: "alert",
  },
  181: {
    type: "error",
    name: "expense_not_found",
    title: "Not Found",
    message: "This Expense was not found!",
    fieldType: "alert",
  },
  182: {
    type: "error",
    name: "goal_not_found",
    title: "Not Found",
    message: "This Goal was not found!",
    fieldType: "alert",
  },
  183: {
    type: "error",
    name: "deal_not_found",
    title: "Not Found",
    message: "This Goal was not found!",
    fieldType: "alert",
  },
  184: {
    type: "error",
    name: "merchant_not_found",
    title: "Not Found",
    message: "This Merchant was not found!",
    fieldType: "alert",
  },
  185: {
    type: "error",
    name: "team_member_not_found",
    title: "Not Found",
    message: "This Team Member was not found!",
    fieldType: "alert",
  },
  186: {
    type: "error",
    name: "could_not_delete_checkin",
    title: "Error",
    message: "Could not delete checkin!",
    fieldType: "alert",
  },
  187: {
    type: "error",
    name: "cannot_share_to_same_user",
    title: "Error",
    message: "Cannot share to same user",
    fieldType: "alert",
  },
  188: {
    type: "error",
    name: "could_not_delete_post",
    title: "Error",
    message: "Cannot delete this post!",
    fieldType: "alert",
  },
  189: {
    type: "error",
    name: "appointment_not_found",
    title: "Error",
    message: "Appointment was not found!",
  },
  190: {
    name: "over_quota_expense_subscriptions",
    title: "Over Quota",
    message: "You're over the expense subscription quota!",
    fieldType: "alert",
  },
  191: {
    name: "contact_note_not_found",
    title: "Note Not Found",
    message: "Contact note was not found!",
    fieldType: "alert",
  },
  192: {
    name: "contact_not_found",
    title: "Contact Not Found",
    message: "Contact was not found!",
    fieldType: "alert",
  },
  193: {
    name: "custom_field_not_found",
    title: "Custom Field Not Found",
    message: "Custom Field was not found!",
    fieldType: "alert",
  },
  194: {
    name: "over_place_quota",
    title: "Over Door Quota",
    message: "Upgrade your membership to get more doors.",
    fieldType: "alert",
  },
  195: {
    name: "not_allowed_to_create_this_place_outside_territory",
    title: "Not Allowed",
    message: "Not allowed to create this door outside of territory",
    fieldType: "alert",
  },
  196: {
    name: "place_not_found",
    title: "Not Found",
    message: "This door was not found!",
    fieldType: "alert",
  },
  197: {
    name: "team_not_found",
    title: "Not Found",
    message: "This team was not found!",
    fieldType: "alert",
  },
  198: {
    name: "team_name_taken",
    title: "Name Taken",
    message: "This team name is taken!",
    fieldType: "alert",
  },
  199: {
    name: "over_custom_status_quota",
    title: "Over Quota",
    message: "Upgrade you subscription to create a new custom status!",
    fieldType: "alert",
  },
  200: {
    name: "unauthorized_grand_fathered_subscription",
    title: "Unauthorized Grand Fathered Subscription",
    message: "Upgrade you subscription to unlock this feature!",
    fieldType: "alert",
  },
  201: {
    name: "custom_status_not_found",
    title: "Not Found",
    message: "Custom status was not found!",
    fieldType: "alert",
  },
  202: {
    name: "no_active_door_session",
    title: "No Active Door Session",
    message: "Create a door session to have a active door session!",
    fieldType: "alert",
  },
  203: {
    name: "user_already_has_door_session",
    title: "A Door Session",
    message: "Is already active!",
    fieldType: "alert",
  },
  204: {
    name: "door_session_not_found",
    title: "Not Found",
    message: "Door session was not found!",
    fieldType: "alert",
  },
  205: {
    name: "over_export_quota",
    title: "Over Quota",
    message: "Upgrade your subscription to create more exports.",
    fieldType: "alert",
  },
  206: {
    name: "over_import_quota",
    title: "Over Quota",
    message: "Upgrade your subscription to create more imports.",
    fieldType: "alert",
  },
  207: {
    name: "free_team_not_allowed_to_import",
    title: "Not Allowed",
    message: "Free teams are not allowed to use import!",
    fieldType: "alert",
  },
  208: {
    name: "import_not_found",
    title: "Not Found",
    message: "Import was not found!",
    fieldType: "alert",
  },
  209: {
    name: "no_import_headings",
    title: "No imported headings",
    message: "You need to link fields to headings.",
    fieldType: "alert",
  },
  210: {
    name: "already_invited",
    title: "Already Invited",
    message: "This person has already been invited!",
    fieldType: "alert",
  },
  211: {
    name: "team_invite_not_found",
    title: "Not Found",
    message: "This team invite was not found!",
    fieldType: "alert",
  },
  212: {
    name: "already_paying_for_user",
    title: "Already Paying",
    message: "You're already paying for this user!",
    fieldType: "alert",
  },
  213: {
    name: "linked_account_not_found",
    title: "Linked Account Not Found",
    message: "Linked account is not found!",
    fieldType: "alert",
  },
  214: {
    name: "objection_not_found",
    title: "Objection Not Found",
    message: "Objection is not found!",
    fieldType: "alert",
  },
  215: {
    name: "product_template_not_found",
    title: "Product Template Not Found",
    message: "Product template is not found!",
    fieldType: "alert",
  },
  216: {
    name: "visit_not_found",
    title: "Visit Not Found",
    message: "Visit is not found!",
    fieldType: "alert",
  },
  217: {
    name: "sale_product_not_found",
    title: "Sale Product Not Found",
    message: "Sale Product is not found!",
    fieldType: "alert",
  },
  218: {
    name: "duplicate_sale_product",
    title: "Duplicated",
    message: "There is a duplicated sale product!",
    fieldType: "alert",
  },
  219: {
    name: "sale_not_found",
    title: "Not Found",
    message: "This sale was not found!",
    fieldType: "alert",
  },
  220: {
    name: "sale_signature_not_found",
    title: "Not Found",
    message: "This sale signature was not found!",
    fieldType: "alert",
  },
  221: {
    name: "space_filter_not_found",
    title: "Not Found",
    message: "This space filter was not found!",
    fieldType: "alert",
  },
  222: {
    name: "space_not_found",
    title: "Not Found",
    message: "This space was not found!",
    fieldType: "alert",
  },
  223: {
    name: "space_module_field_not_found",
    title: "Not Found",
    message: "This space module field was not found!",
    fieldType: "alert",
  },
  224: {
    name: "space_module_field_value_not_found",
    title: "Not Found",
    message: "This space module field value was not found!",
    fieldType: "alert",
  },
  225: {
    name: "space_module_field_filter_not_found",
    title: "Not Found",
    message: "This space module field filter was not found!",
    fieldType: "alert",
  },
  226: {
    name: "battery_not_found",
    title: "Not Found",
    message: "This battery was not found!",
    fieldType: "alert",
  },
  227: {
    name: "device_history_not_found",
    title: "Not Found",
    message: "This device history was not found!",
    fieldType: "alert",
  },
  228: {
    name: "fuel_brand_not_found",
    title: "Not Found",
    message: "This fuel brand was not found!",
    fieldType: "alert",
  },
  229: {
    name: "payment_method_not_found",
    title: "Not Found",
    message: "This payment method was not found!",
    fieldType: "alert",
  },
  230: {
    name: "fuel_octane_type_not_found",
    title: "Not Found",
    message: "This fuel octane type was not found!",
    fieldType: "alert",
  },
  231: {
    name: "fuel_type_not_found",
    title: "Not Found",
    message: "This fuel type was not found!",
    fieldType: "alert",
  },
  232: {
    name: "service_type_not_found",
    title: "Not Found",
    message: "This service type was not found!",
    fieldType: "alert",
  },
  233: {
    name: "vehicle_log_not_found",
    title: "Not Found",
    message: "This vehicle log was not found!",
    fieldType: "alert",
  },
  234: {
    name: "vehicle_not_found",
    title: "Not Found",
    message: "This vehicle was not found!",
    fieldType: "alert",
  },
  235: {
    name: "vehicle_log_type_not_found",
    title: "Not Found",
    message: "This vehicle log type was not found!",
    fieldType: "alert",
  },
  236: {
    name: "expense_subscription_not_found",
    title: "Not Found",
    message: "This expense subscription was not found!",
    fieldType: "alert",
  },
  237: {
    name: "subscription_plan_not_found",
    title: "Not Found",
    message: "This plan was not found!",
    fieldType: "alert",
  },
  238: {
    name: "unauthorized_to_access_team",
    title: "Unauthorized",
    message: "You're not allowed in this team!",
    fieldType: "alert",
  },
  239: {
    name: "task_not_found",
    title: "Task was not found!",
    message: "This task was not found!",
    fieldType: "alert",
  },
  240: {
    name: "territory_not_found",
    title: "Territory was not found!",
    message: "This territory was not found!",
    fieldType: "alert",
  },
  241: {
    name: "area_must_end_with_the_first_intersect",
    title: "Error In Territory!",
    message: "Area must end with the first intersect.",
    fieldType: "alert",
  },
  242: {
    name: "error_updating_territory",
    title: "Error Updating Territory!",
    message:
      "This is an unknown error when updating this territory. Contact Big Bear Support if this persists.",
    fieldType: "alert",
  },
  243: {
    name: "territory_team_member_not_found",
    title: "Not Found",
    message: "This territory team member was not found!",
    fieldType: "alert",
  },
  244: {
    name: "team_name_must_be_unique",
    title: "Validation Error",
    message: "This team name must be unique!",
    fieldType: "alert",
  },
  245: {
    name: "share_users_must_be_in_array",
    title: "Validation Error",
    message: "This users variable was not an array!",
    fieldType: "alert",
  },
  246: {
    name: "notification_not_found",
    title: "Not Found",
    message: "This notification was not found!",
    fieldType: "alert",
  },
  247: {
    name: "lock_not_found",
    title: "Not Found",
    message: "This lock was not found!",
    fieldType: "alert",
  },
  248: {
    name: "activity_log_not_found",
    title: "Not Found",
    message: "This activity log was not found!",
    fieldType: "alert",
  },
  249: {
    name: "note_not_found",
    title: "Not Found",
    message: "This note was not found!",
    fieldType: "alert",
  },
  250: {
    name: "community_not_found",
    title: "Not Found",
    message: "This community was not found!",
    fieldType: "alert",
  },
  251: {
    name: "contact_request_not_found",
    title: "Not Found",
    message: "This contact request was not found!",
    fieldType: "alert",
  },
  252: {
    name: "contact_source_not_found",
    title: "Not Found",
    message: "This contact source was not found!",
    fieldType: "alert",
  },
  253: {
    name: "doc_not_found",
    title: "Not Found",
    message: "This doc was not found!",
    fieldType: "alert",
  },
  254: {
    name: "doc_must_be_a_pdf",
    title: "Validation",
    message: "This uploaded file must be a pdf!",
    fieldType: "alert",
  },
  255: {
    name: "event_not_found",
    title: "Not Found",
    message: "This event was not found!",
    fieldType: "alert",
  },
  256: {
    name: "event_lead_not_found",
    title: "Not Found",
    message: "This event lead was not found!",
    fieldType: "alert",
  },
  257: {
    name: "event_note_not_found",
    title: "Not Found",
    message: "This event note was not found!",
    fieldType: "alert",
  },
  258: {
    name: "flyer_not_found",
    title: "Not Found",
    message: "This flyer was not found!",
    fieldType: "alert",
  },
  259: {
    name: "landing_page_not_found",
    title: "Not Found",
    message: "This landing page was not found!",
    fieldType: "alert",
  },
  260: {
    name: "market_not_found",
    title: "Not Found",
    message: "This market was not found!",
    fieldType: "alert",
  },
  261: {
    name: "market_subscriber_not_found",
    title: "Not Found",
    message: "This market subscriber was not found!",
    fieldType: "alert",
  },
  262: {
    name: "subscribers_must_be_an_array",
    title: "Validation Error",
    message: "Subscribers must be an array!",
    fieldType: "alert",
  },
  263: {
    name: "meeting_not_found",
    title: "Not Found",
    message: "This meeting was not found!",
    fieldType: "alert",
  },
  264: {
    name: "my_property_not_found",
    title: "Not Found",
    message: "This property was not found!",
    fieldType: "alert",
  },
  265: {
    name: "my_property_room_not_found",
    title: "Not Found",
    message: "This room was not found!",
    fieldType: "alert",
  },
  266: {
    name: "my_property_positive_not_found",
    title: "Not Found",
    message: "This my property positive was not found!",
    fieldType: "alert",
  },
  267: {
    name: "my_property_negative_not_found",
    title: "Not Found",
    message: "This my property negative was not found!",
    fieldType: "alert",
  },
  268: {
    name: "open_house_not_found",
    title: "Not Found",
    message: "This open house was not found!",
    fieldType: "alert",
  },
  269: {
    name: "newsletter_campaign_not_found",
    title: "Not Found",
    message: "This newsletter campaign was not found!",
    fieldType: "alert",
  },
  270: {
    name: "newsletter_not_found",
    title: "Not Found",
    message: "This email template was not found!",
    fieldType: "alert",
  },
  271: {
    name: "at_least_one_valid_recipient_is_required",
    title: "Validation",
    message: "At Least One Valid Recipient Is Required",
    fieldType: "alert",
  },
  272: {
    name: "unknown_error",
    title: "Unknown Error",
    message: "Please try again.",
    fieldType: "alert",
  },
  273: {
    name: "newsletter_list_not_found",
    title: "Not Found",
    message: "This newsletter list was not found!",
    fieldType: "alert",
  },
  274: {
    name: "NEWSLETTER_LIST_SUBSCRIBER_NOT_FOUND",
    title: "Not Found",
    message: "This newsletter list subscriber was not found!",
    fieldType: "alert",
  },
  275: {
    name: "newsletter_list_subscribers_must_be_a_array",
    title: "Validation",
    message: "Newsletter subscribers must be a valid array!",
    fieldType: "alert",
  },
  276: {
    name: "pano_lead_not_found",
    title: "Not Found",
    message: "This pano lead was not found!",
    fieldType: "alert",
  },
  277: {
    name: "pano_tour_not_found",
    title: "Not Found",
    message: "This pano tour was not found!",
    fieldType: "alert",
  },
  278: {
    name: "pano_marker_not_found",
    title: "Not Found",
    message: "This pano marker was not found!",
    fieldType: "alert",
  },
  279: {
    name: "pano_scene_not_found",
    title: "Not Found",
    message: "This pano scene was not found!",
    fieldType: "alert",
  },
  280: {
    name: "over_pano_scenes_quota",
    title: "Over Quota",
    message: "Over quota scenes",
    fieldType: "alert",
  },
  281: {
    name: "has_duplicate_pano_tour",
    title: "Duplicate",
    message: "There is already a pano tour with this name!",
    fieldType: "alert",
  },
  282: {
    name: "door_session_does_not_have_doors",
    title: "Validation Error",
    message:
      "You can not end this door session until you door knock on at least one door.",
    fieldType: "alert",
  },
  283: {
    name: "export_not_found",
    title: "Not Found",
    message: "This export was not found!",
    fieldType: "alert",
  },
  284: {
    name: "already_subscribed_on_web",
    title: "Already Subscribed",
    message: "This team already has a subscription on the web app.",
    fieldType: "alert",
  },
  285: {
    name: "no_payment_method_for_geo_import",
    title: "No Payment Method",
    message:
      "You must add at least one payment method to start a geo import. You can add one by going to Team Subscriptions in Admin Console.",
    fieldType: "alert",
  },
  286: {
    name: "no_payment_method_for_uploading_import",
    title: "No Payment Method",
    message:
      "You must add at least one payment method to upload a import. You can add one by going to Team Subscriptions in Admin Console.",
    fieldType: "alert",
  },
  287: {
    name: "unauthenticated",
    title: "Wrong",
    message: "Username OR Password",
    fieldType: "alert",
  },
  288: {
    name: "no_such_coupon",
    title: "No Such Coupon",
    message: "Please try again with a different coupon!",
    fieldType: "alert",
  },
  289: {
    name: "post_not_found",
    title: "No Post Found",
    message: "This post was not found it could be deleted!",
    fieldType: "alert",
  },
  290: {
    name: "checkin_not_found",
    title: "No Checkin Found",
    message: "This checkin was not found it could be deleted!",
    fieldType: "alert",
  },
  291: {
    name: "barcode_not_found",
    title: "Barcode Not Found",
    message: "This barcode was not found on any records!",
    fieldType: "alert",
  },
  292: {
    name: "job_board_post_not_found",
    title: "Job board post Not Found",
    message: "This job board post was not found on any records!",
    fieldType: "alert",
  },
  293: {
    name: "load_not_found",
    title: "Load Not Found",
    message: "This Load was not found on any records!",
    fieldType: "alert",
  },
  294: {
    name: "marketplace_post_not_found",
    title: "Marketplace Post Not Found",
    message: "This Marketplace Post was not found on any records!",
    fieldType: "alert",
  },
  295: {
    name: "post_comment_not_found",
    title: "Post Comment Not Found",
    message: "This Post Comment was not found on any records!",
    fieldType: "alert",
  },
  296: {
    name: "form_validation_error",
    title: "Form validation",
    message: "Check the validation rules!",
    fieldType: "form_validation",
  },
  297: {
    name: "user_does_not_exist",
    title: "User does not exist!",
    message: "This user does not exist!",
    fieldType: "alert",
  },
  298: {
    name: "saved_property_not_found",
    title: "Not found",
    message: "Saved Property not found!",
    fieldType: "alert",
  },
  299: {
    name: "website_lead_not_found",
    title: "Not Found",
    message: "This website lead was not found!",
    fieldType: "alert",
  },
  300: {
    name: "mls_grid_property_not_found",
    title: "Not Found",
    message: "This Mls Grid Property was not found!",
    fieldType: "alert",
  },
  301: {
    name: "saved_search_not_found",
    title: "Not Found",
    message: "This Saved Search was not found!",
    fieldType: "alert",
  },
  302: {
    name: "open_house_note_not_found",
    title: "Not Found",
    message: "This Open House Note was not found!",
    fieldType: "alert",
  },
  303: {
    name: "open_house_lead_not_found",
    title: "Not Found",
    message: "This Open House Lead was not found!",
    fieldType: "alert",
  },
  304: {
    name: "contact_relationship_not_found",
    title: "Not Found",
    message: "This Contact Relationship was not found!",
    fieldType: "alert",
  },
  305: {
    name: "online_user_not_found",
    title: "Not Found",
    message: "This Online User was not found!",
    fieldType: "alert",
  },
  306: {
    name: "online_user_visit_not_found",
    title: "Not Found",
    message: "This Online User Visit was not found!",
    fieldType: "alert",
  },
  307: {
    name: "at_least_one_recipient",
    title: "Error Needs Recipient",
    message:
      "Before you send this newsletter campaign it needs at lead one recipient!",
    fieldType: "alert",
  },
  308: {
    name: "failed_to_resend_email",
    title: "Failed to resend",
    message:
      "This means that Emailcove failed to resend this email because of a server error. If this error persists then please start a chat!",
    fieldType: "alert",
  },
  309: {
    name: "person_not_found",
    title: "Not Found",
    message: "This person was not found!",
    fieldType: "alert",
  },
  310: {
    name: "restaurant_not_found",
    title: "Not Found",
    message: "This restaurant was not found!",
    fieldType: "alert",
  },
  311: {
    name: "checkout_no_found",
    title: "Not Found",
    message: "This checkout was not found!",
    fieldType: "alert",
  },
  312: {
    name: "item_inventory_is_less_then_where_you_are_trying_to_allocate",
    title: "Link failed!",
    message:
      "This items quantity is less then what you are trying to allocate.",
    fieldType: "alert",
  },
  313: {
    name: "brand_name_exists",
    title: "Brand name exists!",
    message: "This brand name is already taken!",
    fieldType: "alert",
  },
  314: {
    name: "category_name_exists",
    title: "Category name exists!",
    message: "This category name is already taken!",
    fieldType: "alert",
  },
  315: {
    name: "store_name_exists",
    title: "Store name exists!",
    message: "This store name is already taken!",
    fieldType: "alert",
  },
  316: {
    name: "video_not_found",
    title: "Video not found!",
    message: "This video was not found!",
    fieldType: "alert",
  },
  317: {
    name: "bunny_stream_video_creation_failed",
    title: "Video creation failed!",
    message: "The video creation failed for the video upload!",
    fieldType: "alert",
  },
  318: {
    name: "bunny_stream_video_upload_failed",
    title: "Video upload failed!",
    message: "The video failed to upload to the streaming server!",
    fieldType: "alert",
  },
  319: {
    name: "cant_move_box_under_the_same_box",
    title: "Validation Error",
    message: "Can't move box under the same box!",
    fieldType: "alert",
  },
  320: {
    name: "pain_not_found",
    title: "Pain Not Found",
    message: "Pain was not discovered!",
    fieldType: "alert",
  },
  321: {
    name: "pain_update_not_found",
    title: "Pain Update Not Found",
    message: "Pain Update was not discovered!",
    fieldType: "alert",
  },
  322: {
    name: "password_confirmation_required",
    title: "Password Confirmation Required",
    message: "Please confirm your password!",
    fieldType: "alert",
  },
  323: {
    name: "two_factor_code_invalid",
    title: "Two Factor Code Invalid",
    message: "The two factor code is invalid!",
    fieldType: "alert",
  },
  324: {
    name: "authentication_log_not_found",
    title: "Authentication Log Not Found",
    message: "Authentication Log was not discovered!",
    fieldType: "alert",
  },
  325: {
    name: "email_is_invalid",
    title: "Email is invalid",
    message: "The email is invalid!",
    fieldType: "alert",
  },
  326: {
    name: "insurance_policy_not_found",
    title: "Insurance Policy Not Found",
    message: "Insurance Policy was not discovered!",
    fieldType: "alert",
  },
  327: {
    name: "profile_not_found",
    title: "Profile Not Found",
    message: "Profile was not discovered!",
    fieldType: "alert",
  },
  328: {
    name: "over_record_quota",
    title: "Over Record Quota",
    message: "You have reached the record quota!",
    fieldType: "alert",
  },
  329: {
    name: "bowel_already_active",
    title: "Bowel Already Active",
    message: "There is already a bowel movement active!",
    fieldType: "alert",
  },
  330: {
    name: "review_not_found",
    title: "Review Not Found",
    message: "This review was not found",
    fieldType: "alert",
  },
  331: {
    name: "review_title_empty",
    title: "Review Title Empty",
    message:
      "Edit the review and add a review title to the review. Then publish the review.",
    fieldType: "alert",
  },
  332: {
    name: "store_account_not_found",
    title: "Store Account Not Found",
    message: "This store account was not found",
    fieldType: "alert",
  },
  333: {
    name: "sign_not_found",
    title: "Sign Not Found",
    message: "This sign was not found",
    fieldType: "alert",
  },
  334: {
    name: "error_db_commit",
    title: "Error DB Commit",
    message: "There was an error committing to the database",
    fieldType: "alert",
  },
  335: {
    name: "agent_not_found",
    title: "Agent Not Found",
    message: "This agent was not found",
    fieldType: "alert",
  },
  336: {
    name: "office_not_found",
    title: "Office Not Found",
    message: "This office was not found",
    fieldType: "alert",
  },
  337: {
    name: "marketplace_module_not_found",
    title: "Marketplace Module Not Found",
    message: "This marketplace module was not found",
    fieldType: "alert",
  },
  338: {
    name: "marketplace_module_not_installed",
    title: "Marketplace Module Not Installed",
    message: "This marketplace module is not installed",
    fieldType: "alert",
  },
  339: {
    name: "mls_grid_member_not_found",
    title: "MLS Grid Member Not Found",
    message: "This MLS grid member was not found",
    fieldType: "alert",
  },
  340: {
    name: "module_not_active",
    title: "Module Not Active",
    message: "This module is not active",
    fieldType: "alert",
  },
  341: {
    name: "invalid_request",
    title: "Invalid Request",
    message: "This request is invalid",
    fieldType: "alert",
  },
  342: {
    name: "call_script_not_found",
    title: "Call Script Not Found",
    message: "This call script was not found",
    fieldType: "alert",
  },
  343: {
    name: "mls_module_is_required",
    title: "MLS Module Is Required",
    message: "The MLS module is required",
    fieldType: "alert",
  },
  344: {
    name: "brands_module_is_required",
    title: "Brands Module Is Required",
    message: "The Brands module is required",
    fieldType: "alert",
  },
  345: {
    name: "website_module_is_required",
    title: "Website Module Is Required",
    message: "The Website module is required",
    fieldType: "alert",
  },
  346: {
    name: "bowel_not_found",
    title: "Bowel Not Found",
    message: "This bowel was not found",
    fieldType: "alert",
  },
  347: {
    name: "bowel_stress_not_found",
    title: "Bowel Stress Not Found",
    message: "This bowel stress was not found",
    fieldType: "alert",
  },
  348: {
    name: "bowel_amount_not_found",
    title: "Bowel Amount Not Found",
    message: "This bowel amount was not found",
    fieldType: "alert",
  },
  349: {
    name: "bowel_blood_not_found",
    title: "Bowel Blood Not Found",
    message: "This bowel blood was not found",
    fieldType: "alert",
  },
  350: {
    name: "bowel_speed_not_found",
    title: "Bowel Speed Not Found",
    message: "This bowel speed was not found",
    fieldType: "alert",
  },
  351: {
    name: "contact_lense_not_found",
    title: "Contact Lense Not Found",
    message: "This contact lense was not found",
    fieldType: "alert",
  },
  352: {
    name: "doctor_not_found",
    title: "Doctor Not Found",
    message: "This doctor was not found",
    fieldType: "alert",
  },
  353: {
    name: "doctor_visit_not_found",
    title: "Doctor Visit Not Found",
    message: "This doctor visit was not found",
    fieldType: "alert",
  },
  354: {
    name: "drink_not_found",
    title: "Drink Not Found",
    message: "This drink was not found",
    fieldType: "alert",
  },
  355: {
    name: "drink_volume_type_not_found",
    title: "Drink Volume Type Not Found",
    message: "This drink volume type was not found",
    fieldType: "alert",
  },
  356: {
    name: "crm_module_is_not_active",
    title: "CRM Module Is Not Active",
    message: "This CRM module is not active",
    fieldType: "alert",
  },
  357: {
    name: "shop_list_not_found",
    title: "Shop List Not Found",
    message: "This shop list was not found",
    fieldType: "alert",
  },
  358: {
    name: "dyte_meeting_creation_failed",
    title: "Dyte Meeting Creation Failed",
    message: "This dyte meeting failed to create",
    fieldType: "alert",
  },
  359: {
    name: "dyte_meeting_add_participant_failed",
    title: "Dyte Meeting Add Participant Failed",
    message: "This dyte meeting failed to add a participant",
    fieldType: "alert",
  },
  360: {
    name: "shop_list_item_not_found",
    title: "Shop List Item Not Found",
    message: "This shop list item was not found",
    fieldType: "alert",
  },
  361: {
    name: "store_already_exists",
    title: "Store Already Exists",
    message: "This store already exists",
    fieldType: "alert",
  },
  362: {
    name: "review_already_exists",
    title: "Review Already Exists",
    message: "This review already exists",
    fieldType: "alert",
  },
  363: {
    name: "listing_agent_not_found",
    title: "Listing Agent Not Found",
    message: "This listing agent was not found",
    fieldType: "alert",
  },
  364: {
    name: "listing_not_found",
    title: "Listing Not Found",
    message: "This listing was not found",
    fieldType: "alert",
  },
  365: {
    name: "showing_not_found",
    title: "Showing Not Found",
    message: "This showing was not found",
    fieldType: "alert",
  },
  366: {
    name: "over_overlap_showing_times_exceeded",
    title: "Over Overlap Showing Times Exceeded",
    message: "Over overlap showing times exceeded",
    fieldType: "alert",
  },
  367: {
    name: "appointment_outside_available_time",
    title: "Appointment Outside Available Time",
    message: "Appointment outside available time",
    fieldType: "alert",
  },
  368: {
    name: "showing_appointment_not_found",
    title: "Showing Appointment Not Found",
    message: "Showing appointment was not found",
    fieldType: "alert",
  },
  369: {
    name: "verification_code_not_found",
    title: "Verification Code Not Found",
    message: "This verification code was not found",
    fieldType: "alert",
  },
  370: {
    name: "verification_code_expired",
    title: "Verification Code Expired",
    message: "This verification code has expired",
    fieldType: "alert",
  },
  371: {
    name: "showing_schedule_restriction_not_found",
    title: "Showing Schedule Restriction Not Found",
    message: "This showing schedule restriction was not found",
    fieldType: "alert",
  },
  372: {
    name: "showing_appointment_conflict",
    title: "Showing Appointment Conflict",
    message: "This showing appointment conflict",
    fieldType: "alert",
  },
  373: {
    name: "mls_grid_property_does_not_belong_to_wealtor_agent",
    title: "Property Does Not Belong To Wealtor Agent",
    message: "This property does not belong to the wealtor agent",
    fieldType: "alert",
  },
  374: {
    name: "net_sheet_not_found",
    title: "Net Sheet Not Found",
    message: "This net sheet does not exist",
    fieldType: "alert",
  },
  375: {
    name: "lock_integration_not_found",
    title: "Lock Integration Not Found",
    message: "This lock integration was not found",
    fieldType: "alert",
  },
  376: {
    name: "populife_not_not_found",
    title: "Populife Not Found",
    message: "This populife was not found",
    fieldType: "alert",
  },
  377: {
    name: "over_quota_store_accounts",
    title: "Over Quota",
    message: "You're over your store account quota!",
    fieldType: "alert",
  },
  378: {
    name: "over_deal_action_quota",
    title: "Over Quota",
    message: "You're over your deal action quota!",
    fieldType: "alert",
  },
  379: {
    name: "over_quota_merchants",
    title: "Over Quota",
    message: "You're over your merchant quota!",
    fieldType: "alert",
  },
};

export default ApiCodeMessages;
