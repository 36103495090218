import BBUIContent from '../../bbui-content/bbui-content'
import BBUIContentHomeItemSeparator from '../../bbui-content-home-item-separator/bbui-content-home-item-separator'
import BBUIListContainer from '../../bbui-list-container/bbui-list-container'
import BBUIListItem from '../../bbui-list-item/bbui-list-item'
import BBUIPage from '../../bbui-page/bbui-page'
import BBUIPageModal from '../../bbui-page-modal/bbui-page-modal'
import BBUITopbarList from '../../bbui-topbar-list/bbui-topbar-list'
import React from 'react'
import { SubActionType } from './sub-action-types'
import useBBUINiceModalStore from '../../bbui-nice-modal-store/bbui-nice-modal-store'
import { useRouter } from 'next/router'

const SubActionsScreen = ({
    componentId,
    title = '',
    hasModal = false,
    isModalOpen = false,
    onClose,
    actions,
}: {
    componentId?: string
    title?: string
    hasModal?: boolean
    isModalOpen?: boolean
    onClose?: () => void
    actions: SubActionType[]
}) => {
    const router = useRouter()
    const { getModal, removeModal, hideModal } = useBBUINiceModalStore()
    const modalRef = React.useRef(null)

    return (
        <BBUIPageModal
            isActive
            isOpen={getModal(componentId).visible}
            onAfterClose={() => {
                removeModal(componentId)
            }}
            onRequestClose={() => {
                hideModal(componentId)
            }}
            modalRef={modalRef}
            isScrollable={false}>
            <BBUIPage
                isScrollable={false}
                topChildren={
                    <>
                        <BBUITopbarList
                            componentId={componentId}
                            title={title}
                            testIDBackButton="topbar-sub-actions-back-button"
                            hasHomeButton={false}
                            isModal
                            onClose={() => {
                                hideModal(componentId)
                            }}
                            router={router}
                        />
                    </>
                }>
                {({}) => {
                    return (
                        <BBUIContent>
                            <BBUIListContainer>
                                {actions.map((action, index) => {
                                    if (action.isSeperator) {
                                        return (
                                            <BBUIContentHomeItemSeparator
                                                title={action.title}
                                            />
                                        )
                                    }
                                    return (
                                        <BBUIListItem
                                            testID={action.testID}
                                            key={action.id}
                                            image={action.image}
                                            icon={action.icon}
                                            title={action.title}
                                            description={action.description}
                                            subDescription={
                                                action.subDescription
                                            }
                                            onPress={() => {
                                                action.onPress({
                                                    componentIdAction: componentId,
                                                    id: action.id,
                                                })
                                            }}
                                        />
                                    )
                                })}
                            </BBUIListContainer>
                        </BBUIContent>
                    )
                }}
            </BBUIPage>
        </BBUIPageModal>
    )
}
export default SubActionsScreen
