import ApiCodeMessages from '../../../../schema/api-code-messages'
import React from 'react'

interface BBUIFormOnErrorProps {
    error: any
    onSetError?: (name, message) => void
    onAlert?: (name, message) => void
    onToast?: (type, title, message) => void
}

const BBUIFormOnError = ({
    error,
    onSetError = null,
    onAlert,
    onToast,
}: BBUIFormOnErrorProps) => {
    let predefinedErrors = ApiCodeMessages
    let errorCode = error?.response?.data?.code
    if (predefinedErrors[errorCode]) {
        if (predefinedErrors[errorCode].fieldType === 'field') {
            if (onSetError === null) {
                onToast(
                    predefinedErrors[errorCode].type,
                    predefinedErrors[errorCode].title,
                    predefinedErrors[errorCode].message,
                )
                return
            }
            onSetError(
                predefinedErrors[errorCode].name,
                predefinedErrors[errorCode].message,
            )
        } else if (predefinedErrors[errorCode].fieldType === 'alert') {
            onAlert(
                predefinedErrors[errorCode].title,
                predefinedErrors[errorCode].message,
            )
        } else {
            onToast(
                predefinedErrors[errorCode].type,
                predefinedErrors[errorCode].title,
                predefinedErrors[errorCode].message,
            )
        }
    } else {
        onToast('error', ':(', 'Unknown error! Please try, again!')
    }
}

export default BBUIFormOnError
