import { Theme } from '@dripsy/core'
import { theme } from './theme'

// make sure you don't mutate the original theme
export const reviewpalTheme: Theme = { ...theme }

reviewpalTheme.colors = {
    $background: '#2aa7f1',
    $authButtonText: '#fff',
    $authButton: '#2aa7f1',
}
