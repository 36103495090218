import { FlatList, Text, TouchableOpacity, View } from 'react-native'

import Icon from 'react-native-vector-icons/FontAwesome5'
import React from 'react'
import { styled } from 'dripsy'

const Container = styled(View)({
    flex: 1,
    flexDirection: 'column',
})

const QuickAction = styled(TouchableOpacity)({
    marginTop: 10,
    marginLeft: 10,
    flexDirection: 'column',
    marginRight: 2,
    width: 95,
})

const IconContainer = styled(View)({
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#fff',
    borderRadius: 10,
    paddingTop: 10,
    paddingBottom: 10,
})

const TitleContainer = styled(View)({
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 10,
})

const TitleText = styled(Text)({
    color: '#fff',
    fontWeight: '600',
})

const ListItemHeader = styled(View)({
    marginTop: 20,
})

const ListItemHeaderText = styled(Text)({
    fontWeight: '600',
    color: '#fff',
    paddingLeft: 10,
    fontSize: 18,
})

interface DataProps {
    testID: string
    id: string
    icon: string
    title: string
    onPress: () => void
    isVisible?: boolean
}

interface Props {
    testID?: string
    data: DataProps[]
}

const BBUIHomeQuickActions: React.FC<Props> = ({
    testID = 'quick-actions',
    data,
}) => {
    return (
        <Container>
            <ListItemHeader>
                <ListItemHeaderText>Quick Actions</ListItemHeaderText>
            </ListItemHeader>
            <FlatList
                testID={testID}
                data={data}
                keyExtractor={(item: DataProps) => item.id}
                horizontal
                showsHorizontalScrollIndicator={false}
                contentContainerStyle={{ paddingBottom: 10 }}
                renderItem={({ item }: { item: DataProps }) => {
                    if (item.isVisible === false) {
                        return null
                    }
                    return (
                        <QuickAction
                            key={item.id}
                            testID={item.testID}
                            onPress={item.onPress}>
                            <IconContainer>
                                <Icon
                                    name={item.icon}
                                    size={25}
                                    color="#000"
                                    style={{
                                        backgroundColor: '#fff',
                                    }}
                                />
                            </IconContainer>
                            <TitleContainer>
                                <TitleText>{item.title}</TitleText>
                            </TitleContainer>
                        </QuickAction>
                    )
                }}
            />
        </Container>
    )
}

export default BBUIHomeQuickActions
