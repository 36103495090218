import React from 'react'
import { styled } from 'dripsy'
import { View } from 'react-native'

const StyledList = styled(View)({
    backgroundColor: '#fff',
})

interface Props {
    children: React.ReactNode
}

const BBUIList: React.FC<Props> = ({ children }) => {
    return <StyledList>{children}</StyledList>
}

export default BBUIList
