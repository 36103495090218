import { Controller, useFormContext } from 'react-hook-form'
import { Text, TextInput, View } from 'react-native'

import DatePicker from 'react-datepicker'
import React from 'react'
import { createPortal } from 'react-dom'
import dayjs from 'dayjs'
import { styled } from 'dripsy'

const ParentContainer = styled(View)({})
const Container = styled(View)({
    flex: 1,
    marginLeft: 10,
    marginRight: 10,
    marginBottom: 10,
})

const TextInputContainer = styled(View)({})
const TextInputField = styled(TextInput)({
    backgroundColor: '#f2f3f4',
    padding: 10,
    borderWidth: 1,
    borderColor: '#a2b09d',
    borderRadius: 5,
    fontSize: 15,
})

const ErrorContainer = styled(View)({})
const ErrorText = styled(Text)({
    paddingTop: 10,
    fontSize: 15,
    color: 'red',
})

const TitleContainer = styled(View)({
    paddingBottom: 10,
})
const TitleText = styled(Text)({
    fontSize: 18,
    fontWeight: 'bold',
    color: '#333',
})

interface Props {
    testID?: string
    title: string
    fieldName: string
    isMultiline?: boolean
}

const BBUIFormDatepicker: React.FC<Props> = ({
    testID,
    title,
    fieldName,
    isMultiline = true,
}) => {
    const {
        control,
        formState: { errors },
    } = useFormContext()
    return (
        <ParentContainer>
            <Container>
                <TitleContainer>
                    <TitleText>{title}</TitleText>
                </TitleContainer>
                <TextInputContainer>
                    <Controller
                        control={control}
                        render={({ field: { onChange, onBlur, value } }) => (
                            <DatePicker
                                className={`input `}
                                name={fieldName}
                                id={fieldName}
                                popperContainer={({ children }) =>
                                    createPortal(children, document.body)
                                }
                                onChange={selected => {
                                    let offsetDate = dayjs(selected)
                                        .startOf('day')
                                        .toISOString()
                                    console.log({ offsetDate })
                                    if (!selected) {
                                        onChange('')
                                    } else {
                                        const date = offsetDate
                                        onChange(date)
                                    }
                                }}
                                showMonthDropdown
                                showYearDropdown
                                // @ts-ignore
                                selected={
                                    value
                                        ? dayjs(value).startOf('day').toDate()
                                        : null
                                }
                            />
                        )}
                        name={fieldName}
                    />
                </TextInputContainer>
                <ErrorContainer>
                    {errors[fieldName] && (
                        <ErrorText ellipsizeMode="tail" numberOfLines={2}>
                            {errors[fieldName]?.message}
                        </ErrorText>
                    )}
                </ErrorContainer>
            </Container>
        </ParentContainer>
    )
}

export default BBUIFormDatepicker
