import React from 'react'

interface Props {
    componentId: string
}

const BBUIUploadingFilesPage = ({ componentId = '' }) => {
    return null
}

export default BBUIUploadingFilesPage
