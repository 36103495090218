import { Text, View } from 'react-native'

import React from 'react'
import { styled } from 'dripsy'

const Container = styled(View)({
    marginBottom: 10,
})
const TitleContainer = styled(View)({
    borderBottomWidth: 1,
    borderBottomColor: '#d7d8db',
    paddingLeft: 10,
    paddingTop: 10,
    paddingBottom: 10,
})
const TitleText = styled(Text)({
    fontWeight: '600',
    fontSize: 19,
})

interface Props {
    title: string
}

const BBUIFormSeparator: React.FC<Props> = ({ title }) => {
    return (
        <Container>
            <TitleContainer>
                <TitleText>{title}</TitleText>
            </TitleContainer>
        </Container>
    )
}

export default BBUIFormSeparator
