import { Theme } from '@dripsy/core'
import { theme } from './theme'

// make sure you don't mutate the original theme
export const bigbearhealthTheme: Theme = { ...theme }

bigbearhealthTheme.colors = {
    $background: '#410FF8',
    $authButtonText: '#fff',
    $authButton: '#410FF8',
}
