import { Image, Text, TouchableOpacity, View } from 'react-native'

import Icon from 'react-native-vector-icons/FontAwesome5'
import React from 'react'
import { styled } from 'dripsy'

const StyledListItemTouchable = styled(TouchableOpacity)({})
const StyledListItemContent = styled(View)({
    flexDirection: 'row',
    padding: 10,
})
const StyledListItemContentLeft = styled(View)({
    flexDirection: 'row',
    alignItems: 'center',
})
const StyledListItemContentMiddle = styled(View)(
    (props: { hasLeft: boolean }) => ({
        flex: 1,
        marginLeft: props.hasLeft ? 10 : 0,
        flexDirection: 'column',
        justifyContent: 'space-evenly',
    }),
)
const StyledListItemContentRight = styled(View)({
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 5,
})
const StyledListItemContentRightButton = styled(TouchableOpacity)({
    padding: 10,
})
const StyledListItemImage = styled(Image)({})
const StyledListItemTitle = styled(Text)({
    fontSize: 16,
    fontWeight: '600',
})
const StyledListItemDescription = styled(Text)({
    fontSize: 14,
    fontWeight: '500',
})
const StyledListItemSubDescription = styled(View)({
    flexDirection: 'row',
    fontSize: 14,
    fontWeight: '300',
})

const StyledListItemSubDescriptionText = styled(Text)({
    flex: 1,
    fontSize: 14,
    fontWeight: '300',
})
const StyledListItemSubDescriptionTime = styled(Text)(
    (props: { hasDescription: boolean }) => ({
        fontSize: 14,
        fontWeight: '200',
        marginLeft: props.hasDescription ? 5 : 0,
    }),
)

interface Props {
    testID?: string
    testIDRightButton?: string
    title: string | undefined
    titleNumberOfLines?: number
    description?: string
    descriptionNumberOfLines?: number
    subDescription?: string
    subDescriptionNumberOfLines?: number
    time?: string
    image?: string | null
    icon?: string
    onPress: () => void
    onLongPress?: () => void
    onPressRight?: () => void
    hasCheckbox?: boolean
    isChecked?: boolean
    disabled?: boolean
}

const BBUIListItem: React.FC<Props> = ({
    testID = 'list-item',
    testIDRightButton = 'right-button',
    title,
    titleNumberOfLines = 1,
    description = null,
    descriptionNumberOfLines = 1,
    subDescription = null,
    subDescriptionNumberOfLines = 1,
    time = null,
    image = null,
    icon = null,
    onPress = () => {},
    onLongPress = () => {},
    onPressRight = null,
    hasCheckbox = false,
    isChecked = false,
    disabled = false,
}) => {
    return (
        <StyledListItemTouchable
            testID={testID}
            onPress={onPress}
            onLongPress={onLongPress}>
            <StyledListItemContent>
                <StyledListItemContentLeft>
                    {hasCheckbox ? (
                        <input
                            type="checkbox"
                            disabled={false}
                            checked={isChecked}
                            style={{ marginRight: 10 }}
                        />
                    ) : null}
                    {image !== null ? (
                        <StyledListItemImage
                            style={{ width: 64, height: 64, borderRadius: 10 }}
                            source={{ uri: image }}
                        />
                    ) : null}
                    {icon !== null ? (
                        <Icon name={icon} size={58} color="grey" />
                    ) : null}
                </StyledListItemContentLeft>
                <StyledListItemContentMiddle
                    hasLeft={image !== null || icon !== null}>
                    <StyledListItemTitle
                        numberOfLines={titleNumberOfLines}
                        ellipsizeMode="middle">
                        {title}
                    </StyledListItemTitle>
                    {description ? (
                        <StyledListItemDescription
                            numberOfLines={descriptionNumberOfLines}
                            ellipsizeMode="middle">
                            {description}
                        </StyledListItemDescription>
                    ) : null}
                    <StyledListItemSubDescription>
                        {subDescription ? (
                            <StyledListItemSubDescriptionText
                                numberOfLines={subDescriptionNumberOfLines}
                                ellipsizeMode="middle">
                                {subDescription}
                            </StyledListItemSubDescriptionText>
                        ) : null}
                        {time ? (
                            <StyledListItemSubDescriptionTime
                                hasDescription={subDescription !== null}>
                                {time}
                            </StyledListItemSubDescriptionTime>
                        ) : null}
                    </StyledListItemSubDescription>
                </StyledListItemContentMiddle>
                <StyledListItemContentRight>
                    {onPressRight ? (
                        <StyledListItemContentRightButton
                            testID={testIDRightButton}
                            onPress={onPressRight}>
                            <Icon name={'ellipsis-h'} size={25} color="grey" />
                        </StyledListItemContentRightButton>
                    ) : null}
                </StyledListItemContentRight>
            </StyledListItemContent>
        </StyledListItemTouchable>
    )
}

export default BBUIListItem
