import React from 'react'
import { styled } from 'dripsy'
import Icon from 'react-native-vector-icons/FontAwesome5'
import { View, Text, TouchableOpacity } from 'react-native'

const BBUIStyledHeader = styled(View)({
    flexDirection: 'row',
    marginTop: 10,
    marginLeft: 10,
    marginRight: 10,
    marginBottom: 10,
})

const BBUIStyledLeft = styled(View)({
    flexDirection: 'row',
})

const BBUIStyledMiddle = styled(View)({
    flex: 1,
    alignItems: 'center',
})

const BBUIStyledRight = styled(View)({
    flexDirection: 'row',
})

const BBUIStyledTitleContainer = styled(Text)({
    flexDirection: 'row',
})

const BBUIStyledTitle = styled(Text)({
    color: '#fff',
    fontSize: 32,
    fontWeight: '600',
})

const BBUISearchButton = styled(TouchableOpacity)({
    marginRight: 10,
})

interface TopbarProps {
    title?: string
    icon?: string
    onPressSearch?: () => void
    testIDSearchButton?: string
}

const BBUITopbarHome: React.FC<TopbarProps> = ({
    title,
    icon,
    onPressSearch,
    testIDSearchButton = 'search-button',
}) => {
    return (
        <>
            <BBUIStyledHeader>
                <BBUIStyledLeft>
                    <Icon name={icon} size={36} color="#fff" />
                    <BBUIStyledTitle>{title}</BBUIStyledTitle>
                </BBUIStyledLeft>
                <BBUIStyledMiddle>
                    <BBUIStyledTitleContainer></BBUIStyledTitleContainer>
                </BBUIStyledMiddle>
                <BBUIStyledRight>
                    {onPressSearch ? (
                        <BBUISearchButton
                            testID={testIDSearchButton}
                            onPress={() => {
                                onPressSearch()
                            }}>
                            <Icon name={'search'} size={30} color="#FFF" />
                        </BBUISearchButton>
                    ) : null}
                </BBUIStyledRight>
            </BBUIStyledHeader>
        </>
    )
}

export default BBUITopbarHome
