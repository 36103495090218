import { Text, TouchableOpacity, View } from 'react-native'

import Icon from 'react-native-vector-icons/FontAwesome5'
import React from 'react'
import { styled } from 'dripsy'

const Container = styled(TouchableOpacity)({
    flex: 1,
})
const TitleContainer = styled(View)({
    borderBottomWidth: 1,
    borderBottomColor: '#d7d8db',
    paddingLeft: 10,
    paddingTop: 10,
    paddingBottom: 10,
})
const TitleText = styled(Text)({
    fontWeight: '600',
    fontSize: 19,
})

interface Props {
    title: string
    onPress?: () => void
    isUpAndDown?: boolean
    isOpen?: boolean
}

const BBUIContentHomeItemSeparator: React.FC<Props> = ({
    title,
    onPress = () => {},
    isUpAndDown = false,
    isOpen = false,
}) => {
    const UpAndDownArrows = () => {
        if (!isUpAndDown) return null

        if (isOpen) {
            return <Icon name="caret-up" size={20} color="#000" />
        } else {
            return <Icon name="caret-down" size={20} color="#000" />
        }
    }

    return (
        <Container onPress={onPress}>
            <TitleContainer>
                <TitleText>
                    {UpAndDownArrows()} {title}
                </TitleText>
            </TitleContainer>
        </Container>
    )
}

export default BBUIContentHomeItemSeparator
