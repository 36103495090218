import React from 'react'
import { View } from 'react-native'
import { styled } from 'dripsy'

const StyledBody = styled(View)({ flex: 1 })

interface Props {
    children: React.ReactNode
}

const BBUIContent: React.FC<Props> = ({ children }) => {
    return <StyledBody>{children}</StyledBody>
}

export default BBUIContent
