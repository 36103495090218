import { BBUIPage, BBUIPageModal } from '../..'
import { SafeAreaView, Text, TouchableOpacity, View } from 'react-native'

import Icon from 'react-native-vector-icons/FontAwesome'
import React from 'react'
import { styled } from 'dripsy'
import useBBUINiceModalStore from '../bbui-nice-modal-store/bbui-nice-modal-store'

const StyledPageContent = styled(SafeAreaView)({
    flex: 1,
    backgroundColor: '$background',
})

const Container = styled(View)({
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
})

const BoxContainer = styled(View)({
    flex: 1,
    flexDirection: 'column',
    marginLeft: 10,
    marginRight: 10,
    backgroundColor: '#fff',
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
})
const Box = styled(View)({
    padding: 20,
})
const BoxTitle = styled(Text)({
    fontSize: 20,
    fontWeight: 'bold',
    paddingBottom: 1,
})
const BoxDescription = styled(Text)({
    fontSize: 15,
    fontWeight: '500',
    color: '#333',
})

const AlertButton = styled(TouchableOpacity)({
    flexDirection: 'row',
    paddingTop: 10,
    borderColor: '#e6e6e6',
    borderTopWidth: 1,
    paddingBottom: 10,
    paddingLeft: 10,
    paddingRight: 20,
    alignItems: 'center',
})
const AlertButtonLeft = styled(View)({
    width: 30,
    justifyContent: 'center',
    alignItems: 'center',
})
const AlertButtonIcon = styled(View)({})
const AlertButtonRight = styled(View)({
    paddingLeft: 10,
})
const AlertButtonText = styled(Text)({
    fontSize: 17,
    fontWeight: '600',
})

interface Button {
    testID?: string
    icon?: string
    text: string
    onPress: () => void
    style?: 'default' | 'cancel' | 'destructive'
}

interface AlertOption {
    cancelable?: boolean
    onDismiss?: () => void
}

interface Props {
    componentId?: string
    icon?: string
    title: string
    message?: string
    buttons?: Button[]
    options?: AlertOption
}

const BBUIAlertModal: React.FC<Props> = ({
    componentId = '',
    icon = null,
    title = '',
    message = '',
    buttons = [],
    options = {},
}) => {
    const { getModal, removeModal, hideModal } = useBBUINiceModalStore()
    const modal = getModal(componentId)
    const modalRef = React.useRef(null)

    return (
        <BBUIPageModal
            isActive
            isOpen={modal.visible}
            onAfterClose={() => {
                removeModal(componentId)
            }}
            onRequestClose={() => {
                hideModal(componentId)
            }}
            modalRef={modalRef}
            isScrollable={false}>
            <BBUIPage isScrollable={false}>
                {() => {
                    return (
                        <StyledPageContent>
                            <Container>
                                <BoxContainer>
                                    <Box>
                                        <BoxTitle>{title}</BoxTitle>
                                        <BoxDescription>
                                            {message}
                                        </BoxDescription>
                                    </Box>

                                    {buttons.map((button, index) => {
                                        return (
                                            <AlertButton
                                                testID={button.testID}
                                                onPress={() => {
                                                    if (
                                                        button.style ===
                                                        'cancel'
                                                    ) {
                                                        hideModal(componentId)
                                                    } else {
                                                        button.onPress()
                                                        hideModal(componentId)
                                                    }
                                                }}>
                                                {icon !== null ? (
                                                    <AlertButtonLeft>
                                                        <AlertButtonIcon>
                                                            <Icon
                                                                name={
                                                                    button.icon
                                                                }
                                                                size={25}
                                                                color="#333"
                                                            />
                                                        </AlertButtonIcon>
                                                    </AlertButtonLeft>
                                                ) : null}
                                                <AlertButtonRight>
                                                    <AlertButtonText>
                                                        {button.text}
                                                    </AlertButtonText>
                                                </AlertButtonRight>
                                            </AlertButton>
                                        )
                                    })}
                                </BoxContainer>
                            </Container>
                        </StyledPageContent>
                    )
                }}
            </BBUIPage>
        </BBUIPageModal>
    )
}
export default BBUIAlertModal
