import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import '@uppy/drag-drop/dist/style.css'
import '@uppy/file-input/dist/style.css'
import '@uppy/progress-bar/dist/style.css'

import BBUIGetAuthToken from '../bbui-get-auth-token/bbui-get-auth-token'
import { DashboardModal } from '@uppy/react'
import React from 'react'
import Uppy from '@uppy/core';
import XHR from '@uppy/xhr-upload';
import useBBUINiceModalStore from '../bbui-nice-modal-store/bbui-nice-modal-store'

interface Props {
    componentId: string
    type: string
    id: string
    allowMultipleUploads?: boolean
    allowedFileTypes?: string[]
    appName?: string
    onUploadComplete?: ( { newMedia } ) => void
}

const BBUIUploadModal: React.FC<Props> = ( {
    componentId = '',
    type,
    id,
    allowMultipleUploads = true,
    allowedFileTypes = [ 'image/*' ],
    appName = '',
    onUploadComplete = () => { },
} ) => {
    let media = []
    const { getModal, removeModal, hideModal } = useBBUINiceModalStore()
    const modal = getModal( componentId )
    const uppy = new Uppy( {
        id: 'bbui-upload-modal',
        allowMultipleUploads: allowMultipleUploads,
        allowMultipleUploadBatches: allowMultipleUploads,
        restrictions: {
            maxNumberOfFiles: allowMultipleUploads ? null : 1,
            allowedFileTypes: allowedFileTypes,
        },
    } )
    uppy.use( XHR, {
        endpoint:
            process.env.NEXT_PUBLIC_APP_BASE_URL + `/${appName}/api/v1/upload`,
        headers: {
            authorization: 'Bearer ' + BBUIGetAuthToken() || null,
        },
        fieldName: 'file',
        formData: true,
    } )
    uppy.on( 'file-added', file => {
        uppy.setFileMeta( file.id, {
            type,
            id,
        } )
    } )
    uppy.on( 'upload-success', ( file, response ) => {
        const httpStatus = response.status // HTTP status code
        const httpBody = response.body // extracted response data

        if ( httpStatus === 200 ) {
            const data = httpBody.data
            if ( type === 'temp' ) {
                media = [ ...media, { id: data.id, url: data.url, real: false } ]
            } else {
                media = [ ...media, { id: data.id, url: data.url } ]
            }
        }

        // do something with file and response
    } )
    return (
        <DashboardModal
            id="bbui-upload-modal"
            uppy={uppy}
            closeModalOnClickOutside
            open={modal?.visible}
            onRequestClose={() => {
                onUploadComplete( { newMedia: media } )
                hideModal( componentId )
                document.body.classList.remove( 'uppy-Dashboard-isFixed' )
                setTimeout( () => {
                    removeModal( componentId )
                }, 300 )
            }}
            plugins={[ 'Webcam' ]}
            proudlyDisplayPoweredByUppy={false}
        />
    )
}
export default BBUIUploadModal
