import { Theme } from '@dripsy/core'
import { theme } from './theme'

// make sure you don't mutate the original theme
export const doorknockerpalTheme: Theme = { ...theme }

doorknockerpalTheme.colors = {
    $background: '#1F96EC',
    $authButtonText: '#fff',
    $authButton: '#1F96EC',
}
