import { Text, TouchableOpacity, View } from 'react-native'

import Icon from 'react-native-vector-icons/FontAwesome5'
import React from 'react'
import { styled } from 'dripsy'

const ParentContainer = styled(View)({})

const Container = styled(View)({
    marginLeft: 10,
    marginRight: 10,
    borderBottomWidth: 1,
    paddingBottom: 10,
    borderBottomColor: '#e6e6e6',
    marginTop: 10,
    marginBottom: 10,
})
const ButtonContainer = styled(View)({
    flexDirection: 'row',
})
const CancelButton = styled(TouchableOpacity)({
    flex: 1,
    backgroundColor: '#920404',
    alignItems: 'center',
    padding: 10,
    borderRadius: 5,
    marginRight: 1,
})
const CancelButtonText = styled(Text)({
    color: '#fff',
})
const SaveButton = styled(TouchableOpacity)({
    flex: 1,
    backgroundColor: '#257403',
    alignItems: 'center',
    padding: 10,
    borderRadius: 5,
    marginLeft: 1,
})
const SaveButtonText = styled(Text)({
    color: '#fff',
})

const Topbar = styled(View)({
    marginTop: 10,
    flexDirection: 'row',
})
const TopbarIcon = styled(View)({
    marginRight: 10,
})
const TopbarIconButton = styled(TouchableOpacity)({
    marginRight: 20,
})
const TopbarTitle = styled(View)({})
const TopbarTitleText = styled(Text)({
    fontSize: 25,
    color: '#fff',
})

const BBUIStyledRight = styled(View)({
    flex: 1,
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
})

const BBUIHelpButton = styled(TouchableOpacity)({
    marginRight: 10,
})

interface Props {
    testIDPrefix?: string
    icon: string
    title: string
    isSaving: boolean
    isFormDirty: boolean
    onCancelPress?: () => void
    onSavePress?: () => void
    onBackPress?: () => void
    onPressHelp?: () => void
    testIDHelpButton?: string
    isBackButtonShowing?: boolean
    isCancelButtonShowing?: boolean
    saveButtonText?: string
    loadingButtonText?: string
    cancelButtonText?: string
}

const BBUITopbarForm: React.FC<Props> = ({
    testIDPrefix = '',
    icon = '',
    title = '',
    isSaving = false,
    isFormDirty = false,
    onCancelPress,
    onSavePress,
    onBackPress,
    onPressHelp,
    testIDHelpButton = 'topbar-help-button',
    isBackButtonShowing = true,
    isCancelButtonShowing = true,
    saveButtonText = 'Save',
    loadingButtonText = 'Saving...',
    cancelButtonText = 'Cancel',
}) => {
    return (
        <ParentContainer>
            <Container>
                <ButtonContainer>
                    {isSaving ? (
                        <SaveButton testID={`${testIDPrefix}saving-button`}>
                            <SaveButtonText>{loadingButtonText}</SaveButtonText>
                        </SaveButton>
                    ) : (
                        <>
                            {isCancelButtonShowing ? (
                                <CancelButton
                                    testID={`${testIDPrefix}cancel-button`}
                                    onPress={onCancelPress}>
                                    <CancelButtonText>
                                        {cancelButtonText}
                                    </CancelButtonText>
                                </CancelButton>
                            ) : null}
                            <SaveButton
                                testID={`${testIDPrefix}save-button`}
                                onPress={onSavePress}>
                                <SaveButtonText>
                                    {saveButtonText}
                                </SaveButtonText>
                            </SaveButton>
                        </>
                    )}
                </ButtonContainer>
                <Topbar>
                    {isBackButtonShowing ? (
                        <TopbarIconButton
                            testID={`${testIDPrefix}back-button`}
                            onPress={onBackPress}>
                            <Icon
                                name={'chevron-left'}
                                size={30}
                                color="#FFF"
                            />
                        </TopbarIconButton>
                    ) : null}
                    <TopbarIcon>
                        <Icon name={icon} size={30} color="#FFF" />
                    </TopbarIcon>
                    <TopbarTitle>
                        <TopbarTitleText>{title}</TopbarTitleText>
                    </TopbarTitle>
                    <BBUIStyledRight>
                        {onPressHelp ? (
                            <BBUIHelpButton
                                testID={testIDHelpButton}
                                onPress={() => {
                                    onPressHelp()
                                }}>
                                <Icon
                                    name={'question-circle'}
                                    size={30}
                                    color="#FFF"
                                />
                            </BBUIHelpButton>
                        ) : null}
                    </BBUIStyledRight>
                </Topbar>
            </Container>
        </ParentContainer>
    )
}

export default BBUITopbarForm
