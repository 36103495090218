import { BBUIPush, BBUIUuidV4 } from '../..'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import { Image, Text, TouchableOpacity, View } from 'react-native'

import BBUIUploadModal from '../bbui-upload-modal/bbui-upload-modal'
import React from 'react'
import { styled } from 'dripsy'
import useBBUINiceModalStore from '../bbui-nice-modal-store/bbui-nice-modal-store'
import { useRouter } from 'next/router'

const Container = styled(View)({
    flex: 0,
    marginLeft: 10,
    marginRight: 10,
    marginBottom: 10,
})

const FileContainer = styled(View)({})
const FilesContainer = styled(View)({
    flexDirection: 'row',
    alignItems: 'flex-start',
    textAlign: 'left',
})
const LeftFilesContainer = styled(View)({
    marginRight: 2.3,
    flexBasis: 'auto',
    flexGrow: 0,
    flexShrink: 0,
})
const PickFilesContainer = styled(View)({
    flexBasis: 'auto',
    flexGrow: 1,
    flexShrink: 1,
    textAlign: 'left',
})
const TakePhotoButton = styled(TouchableOpacity)({
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff',
    padding: 10,
    borderWidth: 1,
    borderColor: '#a2b09d',
    borderRadius: 5,
    fontSize: 15,
    color: '#363636',
    marginRight: 1,
})
const TakePhotoButtonText = styled(Text)({})
const PickButton = styled(TouchableOpacity)({
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff',
    padding: 10,
    borderWidth: 1,
    borderColor: '#a2b09d',
    borderRadius: 5,
    fontSize: 15,
    color: '#363636',
    marginLeft: 1,
})
const PickButtonText = styled(Text)({})

const TitleContainer = styled(View)({
    paddingBottom: 10,
})
const TitleText = styled(Text)({
    fontSize: 18,
    fontWeight: 'bold',
    color: '#333',
})

const ImageContainer = styled(TouchableOpacity)({
    paddingRight: 10,
    paddingBottom: 10,
})
const StyledImage = styled(Image)({
    width: 100,
    height: 100,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: '#a2b09d',
})

interface File {
    id: string
    uri: string
}

interface Props {
    testID?: string
    testIDTakePhoto?: string
    testIDChooseFile?: string
    fieldName?: string
    fieldNamePush?: string // This in the field used to send the ids to api route
    title: string
    onImagePress?: ({
        id,
        files,
        new_files,
    }: {
        id: string
        files: File[]
        new_files: File[]
    }) => void
    onFilePicked?: ({ uri }: { uri: string }) => void
    onDeleteFilePress?: ({ id }: { id: string }) => void
    appName?: string
    allowedFileTypes?: string[]
    uploadType?: 'temp' | 'brand_logo_image'
    uploadId?: number | string
}

const BBUIFormFileInput: React.FC<Props> = ({
    testID = 'list',
    testIDTakePhoto = 'take-photo-button',
    fieldName = 'file',
    fieldNamePush = 'files',
    testIDChooseFile = 'choose-file-button',
    title,
    onImagePress = ({ id }) => {},
    onFilePicked = ({ uri }) => {},
    onDeleteFilePress = ({ id }) => {},
    appName = '',
    allowedFileTypes = ['image/*'],
    uploadType = 'temp',
    uploadId = 0,
}) => {
    const router = useRouter()
    const { addModal, hideModal } = useBBUINiceModalStore()

    const { control, setValue, resetField } = useFormContext()
    const items = useWatch({
        control,
        name: [fieldName], // without supply name will watch the entire form, or ['firstName', 'lastName'] to watch both
    })
    const file = items[0]
    return (
        <Controller
            control={control}
            render={({ field: { value } }) => (
                <Container>
                    <TitleContainer>
                        <TitleText>{title}</TitleText>
                    </TitleContainer>
                    <FileContainer>
                        <FilesContainer>
                            <LeftFilesContainer>
                                <View style={{ height: 100, paddingRight: 15 }}>
                                    {file !== null ? (
                                        <Image
                                            source={{
                                                uri: file
                                                    ? file?.uri
                                                    : file?.uri + '?w=64&h=64',
                                            }}
                                            style={{
                                                width: 64,
                                                height: 64,
                                                borderRadius: 5,
                                            }}
                                        />
                                    ) : null}
                                </View>
                            </LeftFilesContainer>
                            <PickFilesContainer>
                                <PickButton
                                    testID={testIDChooseFile}
                                    onPress={() => {
                                        BBUIPush({
                                            path: BBUIUploadModal,
                                            isModal: true,
                                            addModal,
                                            router,
                                            state: {
                                                appName,
                                                type: uploadType,
                                                id: uploadId,
                                                allowedFileTypes,
                                                onUploadComplete: ({
                                                    newMedia,
                                                }) => {
                                                    let uuidID = BBUIUuidV4()
                                                    if (newMedia.length > 0) {
                                                        setValue(
                                                            fieldNamePush,
                                                            [
                                                                newMedia.length
                                                                    ? newMedia[0]
                                                                          ?.id
                                                                    : '',
                                                            ],
                                                            {
                                                                shouldDirty: true,
                                                            },
                                                        )
                                                        setValue(
                                                            fieldName,
                                                            {
                                                                id: uuidID,
                                                                uri: newMedia.length
                                                                    ? newMedia[0]
                                                                          ?.url
                                                                    : '',
                                                                base64: '',
                                                            },
                                                            {
                                                                shouldDirty: true,
                                                            },
                                                        )
                                                        onFilePicked({
                                                            uri: newMedia.length
                                                                ? newMedia[0]
                                                                      ?.url
                                                                : '',
                                                        })
                                                    }
                                                },
                                            },
                                        })
                                    }}>
                                    <PickButtonText>Choose File</PickButtonText>
                                </PickButton>
                            </PickFilesContainer>
                        </FilesContainer>
                    </FileContainer>
                </Container>
            )}
            name={'new_files'}
        />
    )
}

export default BBUIFormFileInput
