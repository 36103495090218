import React, { useState } from 'react'
import { SafeAreaView, ScrollView, View } from 'react-native'

import { styled } from 'dripsy'

const ParentContainer = styled(View)({
    minHeight: ['100vh', '100vh', '100vh'],
    // marginLeft: 5,
})

const StyledPageContainer = styled(View)({
    flex: 1,
    backgroundColor: '$background',
})

const StyledPageContent = styled(SafeAreaView)({
    flex: 1,
    marginLeft: [0, 50, 80, 100, 300],
    marginRight: [0, 50, 80, 100, 300],
})

const StyledLoginPageContent = styled(SafeAreaView)({
    flex: 1,
    marginLeft: [0, 90, 150, 300, 500],
    marginRight: [0, 90, 150, 300, 500],
})

const StyledPage = styled(View)({
    flex: 1,
    backgroundColor: '$background',
})

const StyledPageScrollable = styled(ScrollView)({
    flex: 1,
    backgroundColor: '$background',
})

interface TopbarHomeProps {
    testID?: string
    topChildren?: React.ReactNode
    children: ({
        setFormDirty,
        setIsLeavingForm,
        isLeavingForm,
        isFormDirty,
    }: {
        setFormDirty?: (dirty: boolean, handleSubmit: any) => void
        setIsLeavingForm?: (isLeavingForm: boolean) => void
        isLeavingForm?: boolean
        isFormDirty?: boolean
    }) => React.ReactNode
    isScrollable?: boolean
    hasForm?: boolean
    hasFormLogin?: boolean
    keyboardShouldPersistTaps?: boolean | 'always' | 'never' | 'handled'
    formExtraHeight?: number
    isModal?: boolean
}

const BBUIPage: React.FC<TopbarHomeProps> = ({
    testID = 'scrollView',
    topChildren = null,
    children,
    isScrollable = true,
    hasForm = false,
    hasFormLogin = false,
    keyboardShouldPersistTaps = 'handled',
    formExtraHeight = 155,
    isModal = false,
}) => {
    const [isFormDirty, setIsFormDirty]: any = useState(false)
    const [isLeavingForm, setIsLeavingForm]: any = useState(false)

    if (hasFormLogin) {
        return (
            <ParentContainer>
                <StyledPageContainer>
                    <StyledLoginPageContent>
                        {topChildren}
                        <StyledPageScrollable testID={testID}>
                            {children({
                                setFormDirty: (
                                    dirty: boolean,
                                    handleSubmit: any,
                                ) => {
                                    setIsFormDirty(dirty)
                                },
                                setIsLeavingForm: (isLeavingForm: boolean) => {
                                    setIsLeavingForm(isLeavingForm)
                                },
                                isLeavingForm: isLeavingForm,
                                isFormDirty: isFormDirty,
                            })}
                        </StyledPageScrollable>
                    </StyledLoginPageContent>
                </StyledPageContainer>
            </ParentContainer>
        )
    }

    if (hasForm) {
        return (
            <ParentContainer>
                <StyledPageContainer>
                    <StyledPageContent>
                        {topChildren}
                        <StyledPageScrollable testID={testID}>
                            {children({
                                setFormDirty: (
                                    dirty: boolean,
                                    handleSubmit: any,
                                ) => {
                                    setIsFormDirty(dirty)
                                },
                                setIsLeavingForm: (isLeavingForm: boolean) => {
                                    setIsLeavingForm(isLeavingForm)
                                },
                                isLeavingForm: isLeavingForm,
                                isFormDirty: isFormDirty,
                            })}
                        </StyledPageScrollable>
                    </StyledPageContent>
                </StyledPageContainer>
            </ParentContainer>
        )
    }

    if (isScrollable) {
        return (
            <ParentContainer>
                <StyledPageContainer>
                    <StyledPageContent>
                        {topChildren}
                        <StyledPageScrollable testID={testID}>
                            {children({})}
                        </StyledPageScrollable>
                    </StyledPageContent>
                </StyledPageContainer>
            </ParentContainer>
        )
    }

    return (
        <ParentContainer>
            <StyledPageContainer>
                <StyledPageContent>
                    {topChildren}
                    <StyledPage testID={testID}>{children({})}</StyledPage>
                </StyledPageContent>
            </StyledPageContainer>
        </ParentContainer>
    )
}

export default BBUIPage
