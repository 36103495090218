import React, { useCallback, useRef } from 'react'

import InfiniteScroll from 'react-infinite-scroll-component'

interface BBUICustomFlatListProps<T> {
    data: T[]
    renderItem: ({ item, index }: { item: T; index: number }) => JSX.Element
    keyExtractor: (item: T, index: number) => string
    ItemSeparatorComponent?: React.FC | React.ReactElement | null
    ListEmptyComponent?: React.FC | React.ReactElement | null
    ListHeaderComponent?: React.FC | React.ReactElement | null
    ListFooterComponent?: React.FC | React.ReactElement | null
    onEndReached?: () => void
    onEndReachedThreshold?: number
    onRefresh?: () => void
    refreshing?: boolean
    style?: React.CSSProperties
    contentContainerStyle?: React.CSSProperties
    testID?: string
    canFetchMore?: boolean
    page?: number
    emptyMessage?: string
}

const BBUICustomFlatList = <T,>({
    data,
    renderItem,
    keyExtractor,
    ItemSeparatorComponent,
    ListEmptyComponent,
    ListHeaderComponent,
    ListFooterComponent,
    onEndReached,
    onEndReachedThreshold = 0.1,
    onRefresh,
    refreshing,
    style,
    contentContainerStyle,
    testID,
    canFetchMore = false,
    page = 2,
    emptyMessage,
}: BBUICustomFlatListProps<T>) => {
    const containerRef = useRef<HTMLDivElement>(null)

    const renderList = () => {
        return (
            <div style={{ ...contentContainerStyle }}>
                {data.length > 0 &&
                    data.map((item, index) => {
                        const key = keyExtractor(item, index)
                        const separator =
                            index !== data.length - 1 &&
                            ItemSeparatorComponent ? (
                                // @ts-ignore
                                <ItemSeparatorComponent
                                    key={`${key}-separator`}
                                />
                            ) : null
                        return (
                            <React.Fragment key={key}>
                                {renderItem({ item, index })}
                                {separator}
                            </React.Fragment>
                        )
                    })}
                {!data.length && ListEmptyComponent ? (
                    <div style={{ textAlign: 'center' }}>
                        <p
                            style={{
                                margin: '20px 0',
                                color: 'black',
                                fontSize: '20px',
                            }}>
                            {emptyMessage}
                        </p>
                    </div>
                ) : null}
                {/* {ListHeaderComponent && <ListHeaderComponent />} */}
                {/* {ListFooterComponent && <ListFooterComponent />} */}
                {/* {refreshing && (
                    <div style={{ textAlign: 'center', padding: '10px' }}>
                        <span>Loading...</span>
                    </div>
                )} */}
            </div>
        )
    }

    return (
        <InfiniteScroll
            dataLength={data.length}
            next={onEndReached}
            hasMore={canFetchMore}
            loader={
                <div
                    key={0}
                    style={{
                        textAlign: 'center',
                        color: 'black',
                        fontSize: '20px',
                        padding: '10px',
                        margin: '20px 0',
                    }}>
                    <span>Loading...</span>
                </div>
            }
            endMessage={
                <>
                    {data.length > 0 && (
                        <p
                            style={{
                                textAlign: 'center',
                                color: 'black',
                                fontSize: '17px',
                                padding: '10px',
                                margin: '20px 0',
                            }}>
                            <b>End of results</b>
                        </p>
                    )}
                </>
            }>
            <div style={{ ...style }} ref={containerRef} data-testid={testID}>
                {renderList()}
            </div>
        </InfiniteScroll>
    )
}

export default BBUICustomFlatList
