import { TouchableOpacity, View } from 'react-native'

import { CheckBox } from 'react-native-web'
import React from 'react'
import { styled } from 'dripsy'

const Container = styled(View)({
    flex: 0,
    flexDirection: 'row',
    marginLeft: 10,
    marginRight: 10,
    marginBottom: 10,
})
const TextContainer = styled(TouchableOpacity)({ marginLeft: 10 })

interface Props {
    testID?: string
    title: string
    onChange?: () => void
    value?: boolean
}

const BBUIFormCheckbox: React.FC<Props> = ({
    testID = 'checkbox',
    title,
    onChange = () => {},
    value = '',
}) => {
    return (
        <Container>
            <CheckBox
                testID={testID}
                label={title}
                onValueChange={onChange}
                value={value}
                color="#1DA1F2"
                id={testID}
            />
            <TextContainer onPress={onChange}>{title}</TextContainer>
        </Container>
    )
}

export default BBUIFormCheckbox
