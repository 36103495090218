import { Text, TouchableOpacity, View } from 'react-native'

import Icon from 'react-native-vector-icons/FontAwesome5'
import React from 'react'
import { styled } from 'dripsy'

const ListItemContainer = styled(TouchableOpacity)({
    flexDirection: 'row',
    paddingBottom: 15,
})
const ListIconContainer = styled(View)({
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 10,
    width: 30,
})
const ListItemContentContainer = styled(View)({
    flex: 1,
    flexDirection: 'column',
})
const ListItemTitleText = styled(Text)({
    fontSize: 16,
    fontWeight: 'bold',
})
const ListItemDescriptionText = styled(Text)({
    fontSize: 14,
    color: '#999',
})

const ListRight = styled(View)({
    alignItems: 'center',
    justifyContent: 'center',
})
const ListRightIcon = styled(View)({})

interface Props {
    componentId: string | undefined
    testID?: string
    onPress?: () => void
    icon?: string
    title?: string
    description?: string
}

const BBUISearchRecentlyUsedItem: React.FC<Props> = ({
    componentId,
    testID,
    onPress,
    icon,
    title,
    description,
}) => {
    return (
        <ListItemContainer testID={testID} onPress={onPress}>
            <ListIconContainer>
                <Icon name={icon} size={25} color="#000" />
            </ListIconContainer>
            <ListItemContentContainer>
                <ListItemTitleText ellipsizeMode="tail" numberOfLines={1}>
                    {title}
                </ListItemTitleText>
                <ListItemDescriptionText ellipsizeMode="tail" numberOfLines={3}>
                    {description}
                </ListItemDescriptionText>
            </ListItemContentContainer>
            <ListRight>
                <ListRightIcon>
                    <Icon name={'chevron-right'} size={25} color="#000" />
                </ListRightIcon>
            </ListRight>
        </ListItemContainer>
    )
}

export default BBUISearchRecentlyUsedItem
