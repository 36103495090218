import React from 'react'
import { View } from 'react-native'
import { styled } from 'dripsy'

const Container = styled(View)((props: { hasTabs?: boolean }) => ({
    flex: 1,
    flexGrow: 1,
    flexDirection: 'column',
    marginLeft: 10,
    marginRight: 10,
    marginBottom: 40,
    backgroundColor: '#f2f3f4',
    borderTopLeftRadius: props.hasTabs ? 0 : 20,
    borderTopRightRadius: props.hasTabs ? 0 : 20,
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    paddingTop: 20,
    paddingBottom: 20,
    minHeight: 700,
}))

interface Props {
    children: React.ReactNode
    hasTabs?: boolean
}

const BBUIForm: React.FC<Props> = ({ children, hasTabs = false }) => {
    return <Container hasTabs={hasTabs}>{children}</Container>
}

export default BBUIForm
