import * as yup from 'yup'

import {
    ActivityIndicator,
    KeyboardAvoidingView,
    Platform,
    Switch,
    Text,
    TextInput,
    TouchableOpacity,
    View,
} from 'react-native'
import {
    BBUIAlertError,
    BBUIAxiosQuery,
    BBUIFormOnError,
    BBUIPush,
} from '../..'
import {
    Container,
    Content,
    Description,
    Email,
    EmailTitle,
    Footer,
    FormErrorText,
    Header,
    LegalButtons,
    LoginButton,
    LoginButtonText,
    Name,
    NameTitle,
    Password,
    PasswordTitle,
    PrivacyButton,
    PrivacyButtonText,
    RegisterButton,
    RegisterButtonText,
    Title,
    TosButton,
    TosButtonText,
    TosTitle,
} from './RegisterStyledComponents'
import { Controller, useForm } from 'react-hook-form'
import React, { useEffect, useRef, useState } from 'react'

import { AuthPaths } from '../../index'
import BBUIPage from '../bbui-page/bbui-page'
import BBUISaveLogin from '../bbui-save-login/bbui-save-login'
import qs from 'qs'
import useBBUINiceModalStore from '../bbui-nice-modal-store/bbui-nice-modal-store'
import { useMutation } from '@tanstack/react-query'
import { useRouter } from 'next/router'
import { yupResolver } from '@hookform/resolvers/yup'

const schema = yup.object().shape({
    name: yup.string().required('Name is a required field'),
    email: yup
        .string()
        .email('Email must be a valid email')
        .required('Email is a required field'),
    password: yup
        .string()
        .min(6, 'Password must be at least 6 characters')
        .required('Password is a required field'),
    terms: yup.boolean().oneOf([true], 'Must Accept Terms and Privacy Policy'),
})

interface Props {
    componentId: string
    appName: string
    appUrl: string
    year: string
    productId: number
    params: any
    onSuccess: any
}

const BBUIRegisterPage: React.FC<Props> = ({
    componentId,
    appName,
    appUrl,
    year = '2022',
    productId,
    params,
    onSuccess,
}) => {
    const router = useRouter()
    const { addModal } = useBBUINiceModalStore()

    const [showPassword, setShowPassword] = useState(false)

    useEffect(() => {
        nameRef.current?.focus()
    }, [])

    const query = router.query
    // @ts-ignore
    const queryParsed = qs.parse(query)

    const registerUser = useMutation(
        async ({
            name,
            email,
            password,
        }: AuthPaths['/bigbearaccounts/api/v1/register']['post']['requestBody']['application/json']) => {
            const data: AuthPaths['/bigbearaccounts/api/v1/register']['post']['responses']['200']['application/json'] = await BBUIAxiosQuery(
                {
                    url: `/bigbearaccounts/api/v1/register`,
                    data: {
                        ...{ name, email, password, product_id: productId },
                        ...params,
                    },
                    method: 'POST',
                },
            )
            return data.data
        },
    )

    const {
        control,
        handleSubmit,
        formState: { errors, isValid },
        setError,
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            name: '',
            email: '',
            password: '',
            terms: false,
        },
        mode: 'onChange',
    })

    const nameRef = useRef<TextInput>(null)
    const emailRef = useRef<TextInput>(null)
    const passwordRef = useRef<TextInput>(null)

    const onSubmit = data => {
        // setFormError(null)
        registerUser.mutate(
            {
                name: data.name,
                email: data.email,
                password: data.password,
            },
            {
                onSuccess: (
                    res: AuthPaths['/bigbearaccounts/api/v1/register']['post']['responses']['200']['application/json']['data'],
                ) => {
                    BBUISaveLogin(res.auth_token.access_token)
                    onSuccess({
                        username: data.email,
                        password: data.password,
                        magic_link: res.magic_link ? res.magic_link : null,
                        accept_token: queryParsed.inviteId,
                        deny_token: queryParsed.deny_token,
                        product_id: queryParsed.product_id,
                        redirect: queryParsed.redirect,
                        bigbeartoken: res.auth_token.access_token,
                    })
                },
                onError: err => {
                    BBUIFormOnError({
                        error: err,
                        onSetError: (name, message) => {
                            setError(name, {
                                type: 'manual',
                                message,
                            })
                        },
                        onAlert: (name, message) => {
                            BBUIAlertError({
                                title: name,
                                text: message,
                                addModal,
                                router,
                            })
                        },
                        onToast: (type, title, message) => {
                            // toast({ message: title, subMessage: message, intent: "ERROR" });
                        },
                    })
                },
            },
        )
    }
    return (
        <KeyboardAvoidingView
            behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
            style={{ flex: 1 }}>
            <BBUIPage testID="scrollView" isScrollable={false} hasFormLogin>
                {({}) => {
                    return (
                        <Container>
                            <Header>
                                <Title>BigBearApps</Title>
                                <Description>
                                    Register a new BigBearAccount
                                </Description>
                            </Header>
                            <Content>
                                <NameTitle>Name</NameTitle>
                                <Controller
                                    control={control}
                                    render={({
                                        field: { onChange, onBlur, value },
                                    }) => (
                                        <Name
                                            testID="name-input"
                                            ref={nameRef}
                                            autoCapitalize={'none'}
                                            keyboardType="default"
                                            returnKeyType="next"
                                            onSubmitEditing={() => {
                                                emailRef.current?.focus()
                                            }}
                                            onBlur={onBlur}
                                            onChangeText={onChange}
                                            value={value}
                                            accessibilityLabel="Name input"
                                            accessibilityHint="Enter your full name"
                                        />
                                    )}
                                    name="name"
                                />
                                {errors.name && (
                                    <FormErrorText>
                                        {errors.name.message}
                                    </FormErrorText>
                                )}
                                <EmailTitle>Email</EmailTitle>
                                <Controller
                                    control={control}
                                    render={({
                                        field: { onChange, onBlur, value },
                                    }) => (
                                        <Email
                                            testID="email-input"
                                            ref={emailRef}
                                            autoCapitalize={'none'}
                                            keyboardType="email-address"
                                            returnKeyType="next"
                                            onSubmitEditing={() => {
                                                passwordRef.current?.focus()
                                            }}
                                            onBlur={onBlur}
                                            onChangeText={onChange}
                                            value={value}
                                            accessibilityLabel="Email input"
                                            accessibilityHint="Enter your email address"
                                        />
                                    )}
                                    name="email"
                                />
                                {errors.email && (
                                    <FormErrorText>
                                        {errors.email.message}
                                    </FormErrorText>
                                )}
                                <PasswordTitle>Password</PasswordTitle>
                                <View
                                    style={{
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                    }}>
                                    <Controller
                                        control={control}
                                        render={({
                                            field: { onChange, onBlur, value },
                                        }) => (
                                            <Password
                                                testID="password-input"
                                                ref={passwordRef}
                                                keyboardType="default"
                                                autoCorrect={false}
                                                autoComplete="password"
                                                secureTextEntry={!showPassword}
                                                autoCapitalize={'none'}
                                                returnKeyType="done"
                                                onBlur={onBlur}
                                                onChangeText={onChange}
                                                value={value}
                                                accessibilityLabel="Password input"
                                                accessibilityHint="Enter your password"
                                                style={{ flex: 1 }}
                                            />
                                        )}
                                        name="password"
                                    />
                                    <TouchableOpacity
                                        onPress={() =>
                                            setShowPassword(!showPassword)
                                        }
                                        style={{
                                            padding: 10,
                                            marginBottom: 10,
                                        }}
                                        accessibilityLabel={
                                            showPassword
                                                ? 'Hide password'
                                                : 'Show password'
                                        }
                                        accessibilityHint={
                                            showPassword
                                                ? 'Tap to hide password'
                                                : 'Tap to show password'
                                        }>
                                        <Text>
                                            {showPassword ? 'Hide' : 'Show'}
                                        </Text>
                                    </TouchableOpacity>
                                </View>
                                {errors.password && (
                                    <FormErrorText>
                                        {errors.password.message}
                                    </FormErrorText>
                                )}
                                <TosTitle>
                                    Do you agree to the TOS & Privacy Policy?
                                </TosTitle>
                                <Controller
                                    control={control}
                                    render={({
                                        field: { onChange, value },
                                    }) => (
                                        <View
                                            style={{
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                            }}>
                                            <Switch
                                                testID="term-checkbox"
                                                onValueChange={newValue =>
                                                    onChange(newValue)
                                                }
                                                value={value}
                                            />
                                            <Text style={{ marginLeft: 8 }}>
                                                I agree
                                            </Text>
                                        </View>
                                    )}
                                    name="terms"
                                />
                                {errors.terms && (
                                    <FormErrorText>
                                        {errors.terms.message}
                                    </FormErrorText>
                                )}
                                <RegisterButton
                                    testID="sign-up-button"
                                    onPress={handleSubmit(onSubmit)}
                                    disabled={
                                        registerUser.isLoading || !isValid
                                    }
                                    accessibilityLabel="Register"
                                    accessibilityHint="Press to create your account">
                                    {registerUser.isLoading ? (
                                        <ActivityIndicator color="white" />
                                    ) : (
                                        <RegisterButtonText>
                                            Register
                                        </RegisterButtonText>
                                    )}
                                </RegisterButton>
                            </Content>
                            <Footer>
                                <LoginButton
                                    testID="login-button"
                                    onPress={() => {
                                        BBUIPush({
                                            componentId,
                                            path: '/accounts/login',
                                            reactNativePath:
                                                'registerComponentName',
                                            router,
                                            query: {
                                                redirect: queryParsed.redirect,
                                                type: queryParsed.type
                                                    ? queryParsed.type
                                                    : null,
                                                id: queryParsed.id
                                                    ? queryParsed.id
                                                    : null,
                                            },
                                        })
                                    }}
                                    accessibilityLabel="Login"
                                    accessibilityHint="Go to the login page">
                                    <LoginButtonText>Login</LoginButtonText>
                                </LoginButton>
                                <LegalButtons>
                                    <TosButton
                                        testID="tos-button"
                                        onPress={() => {
                                            BBUIPush({
                                                componentId,
                                                path: '/legal/tos',
                                                reactNativePath:
                                                    'registerComponentName',
                                                router,
                                            })
                                        }}
                                        accessibilityLabel="Terms of Service"
                                        accessibilityHint="View the Terms of Service">
                                        <TosButtonText>
                                            Terms of Service
                                        </TosButtonText>
                                    </TosButton>
                                    <PrivacyButton
                                        testID="privacy-button"
                                        onPress={() => {
                                            BBUIPush({
                                                componentId,
                                                path: '/legal/privacy',
                                                reactNativePath:
                                                    'registerComponentName',
                                                router,
                                            })
                                        }}
                                        accessibilityLabel="Privacy Policy"
                                        accessibilityHint="View the Privacy Policy">
                                        <PrivacyButtonText>
                                            Privacy Policy
                                        </PrivacyButtonText>
                                    </PrivacyButton>
                                </LegalButtons>
                            </Footer>
                        </Container>
                    )
                }}
            </BBUIPage>
        </KeyboardAvoidingView>
    )
}

export default BBUIRegisterPage
