import { NextRouter, useRouter } from 'next/router'
import { Text, TouchableOpacity, View } from 'react-native'

import Icon from 'react-native-vector-icons/FontAwesome5'
import MaterialCommunityIconsIcon from 'react-native-vector-icons/MaterialCommunityIcons'
import React from 'react'
import { styled } from 'dripsy'

const BBUIStyledHeader = styled(View)({
    flexDirection: 'row',
    padding: 10,
    borderBottomWidth: 1,
    borderBottomColor: '#f2f3f4',
})

const BBUIStyledLeft = styled(View)({
    flexDirection: 'row',
    marginRight: 12,
    alignItems: 'center',
    justifyContent: 'center',
})

const BBUIStyledMiddle = styled(View)({
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
})

const BBUIStyledRight = styled(View)({
    flexDirection: 'row',
})

const BBUIStyledTitleContainer = styled(View)({
    flex: 1,
    flexDirection: 'row',
})

const BBUIStyledTitle = styled(Text)({
    flex: 1,
    color: '#fff',
    fontSize: 28,
    fontWeight: '600',
})

const BBUIBackButton = styled(TouchableOpacity)({})
const BBUIHelpButton = styled(TouchableOpacity)({
    marginRight: 10,
})
const BBUISearchButton = styled(TouchableOpacity)({
    marginRight: 10,
})
const BBUICheckboxButton = styled(TouchableOpacity)({
    marginRight: 10,
})
const BBUIHomeButton = styled(TouchableOpacity)({})

interface TopbarProps {
    componentId: string | undefined
    testIDBackButton?: string
    testIDHomeButton?: string
    testIDSearchButton?: string
    testIDHelpButton?: string
    title?: string
    icon?: string
    iconType?: 'font-awesome' | 'material-community'
    rightButtons?: React.ReactNode
    onPressCheckbox?: () => void
    onPressSearch?: () => void
    onPressHelp?: () => void
    hasBackButton?: boolean
    hasHomeButton?: boolean
    isModal?: boolean
    onClose?: () => void
    router: NextRouter
}

const BBUITopbarList: React.FC<TopbarProps> = ({
    componentId,
    testIDBackButton = 'topbar-back-button',
    testIDHomeButton = 'topbar-home-button',
    testIDSearchButton = 'topbar-search-button',
    testIDHelpButton = 'topbar-help-button',
    title,
    icon,
    iconType = 'font-awesome',
    rightButtons,
    onPressCheckbox = null,
    onPressSearch = null,
    onPressHelp = null,
    hasBackButton = true,
    hasHomeButton = true,
    isModal = false,
    onClose = () => {},
    router,
}) => {
    const home_url = '/' + router.pathname.split('/')[1]
    // const home_url = '/'
    return (
        <BBUIStyledHeader>
            <BBUIStyledLeft>
                {hasBackButton ? (
                    <BBUIBackButton
                        testID={testIDBackButton}
                        onPress={async () => {
                            if (isModal) {
                                onClose()
                            } else {
                                router.back()
                            }
                        }}>
                        <Icon name={'chevron-left'} size={30} color="#FFF" />
                    </BBUIBackButton>
                ) : null}
            </BBUIStyledLeft>
            <BBUIStyledMiddle>
                <BBUIStyledTitleContainer>
                    {iconType === 'font-awesome' ? (
                        <Icon name={icon} size={36} color="#fff" />
                    ) : null}
                    {iconType === 'material-community' ? (
                        <MaterialCommunityIconsIcon
                            name={icon}
                            size={36}
                            color="#fff"
                        />
                    ) : null}
                    <BBUIStyledTitle ellipsizeMode="middle" numberOfLines={1}>
                        {title}
                    </BBUIStyledTitle>
                </BBUIStyledTitleContainer>
            </BBUIStyledMiddle>
            <BBUIStyledRight>
                {rightButtons}
                {onPressCheckbox ? (
                    <BBUICheckboxButton
                        testID={testIDBackButton}
                        onPress={() => {
                            onPressCheckbox()
                        }}>
                        <Icon name={'check-square'} size={30} color="#FFF" />
                    </BBUICheckboxButton>
                ) : null}
                {onPressSearch ? (
                    <BBUISearchButton
                        testID={testIDSearchButton}
                        onPress={() => {
                            onPressSearch()
                        }}>
                        <Icon name={'search'} size={30} color="#FFF" />
                    </BBUISearchButton>
                ) : null}
                {onPressHelp ? (
                    <BBUIHelpButton
                        testID={testIDHelpButton}
                        onPress={() => {
                            onPressHelp()
                        }}>
                        <Icon name={'question-circle'} size={30} color="#FFF" />
                    </BBUIHelpButton>
                ) : null}
                {hasHomeButton ? (
                    <BBUIHomeButton
                        testID={testIDHomeButton}
                        onPress={() => {
                            router.replace(home_url)
                        }}>
                        <Icon name={'home'} size={30} color="#FFF" />
                    </BBUIHomeButton>
                ) : null}
            </BBUIStyledRight>
        </BBUIStyledHeader>
    )
}

export default BBUITopbarList
