import { Controller, useFormContext, useWatch } from 'react-hook-form'
import React, { useState } from 'react'
import { Text, TextInput, TouchableOpacity, View } from 'react-native'

import { HexColorPicker } from 'react-colorful'
import { styled } from 'dripsy'

const ParentContainer = styled(View)({})
const Container = styled(View)({
    flex: 1,
    marginLeft: 10,
    marginRight: 10,
    marginBottom: 10,
})

const TextInputContainer = styled(View)({})
const TextInputField = styled(TextInput)({
    backgroundColor: '#fff',
    padding: 10,
    borderWidth: 1,
    borderColor: '#a2b09d',
    fontSize: 15,
    color: '#363636',
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
})

const ErrorContainer = styled(View)({})
const ErrorText = styled(Text)({
    paddingTop: 10,
    fontSize: 15,
    color: 'red',
})

const TitleContainer = styled(View)({
    paddingBottom: 10,
})
const TitleText = styled(Text)({
    fontSize: 18,
    fontWeight: 'bold',
    color: '#333',
})

const ButtonContainer = styled(View)({})
const ColorContainer = styled(View)({
    marginTop: 10,
})
const Button = styled(TouchableOpacity)({
    alignItems: 'center',
    borderColor: '#a2b09d',
    borderTopWidth: 0,
    borderWidth: 1,
    padding: 10,
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    backgroundColor: '#fff',
})
const ButtonText = styled(Text)({
    fontSize: 15,
    fontWeight: 'bold',
    color: '#333',
})

interface Props {
    testID?: string
    testIDButton?: string
    title: string
    fieldName: string
    isMultiline?: boolean
    onPress?: () => void
    buttonText?: string
}

const BBUIFormTextInputColorButton: React.FC<Props> = ({
    testID = 'input',
    testIDButton = 'button',
    title,
    fieldName,
    isMultiline = false,
    onPress = () => {},
    buttonText = '',
}) => {
    const [isVisible, setIsVisible] = useState(false)
    const {
        control,
        setValue,
        formState: { errors },
    } = useFormContext()
    const colorValue = useWatch({
        control,
        name: fieldName, // without supply name will watch the entire form, or ['firstName', 'lastName'] to watch both
    })
    return (
        <ParentContainer>
            <Container>
                <TitleContainer>
                    <TitleText>{title}</TitleText>
                </TitleContainer>
                <TextInputContainer>
                    <Controller
                        control={control}
                        render={({ field: { onChange, onBlur, value } }) => (
                            <TextInputField
                                testID={testID}
                                multiline={isMultiline}
                                onBlur={onBlur}
                                onChangeText={onChange}
                                value={value}
                            />
                        )}
                        name={fieldName}
                    />
                </TextInputContainer>
                <ButtonContainer>
                    <Button
                        testID={testIDButton}
                        onPress={() => {
                            setIsVisible(!isVisible)
                        }}>
                        <ButtonText>
                            {isVisible
                                ? 'Close Color Picker'
                                : 'Open Color Picker'}
                        </ButtonText>
                    </Button>
                </ButtonContainer>
                <ColorContainer>
                    {isVisible ? (
                        <HexColorPicker
                            color={colorValue}
                            onChange={color => {
                                setValue(fieldName, color)
                            }}
                        />
                    ) : null}
                </ColorContainer>
                {errors[fieldName] && (
                    <ErrorContainer>
                        <ErrorText ellipsizeMode="tail" numberOfLines={2}>
                            {errors[fieldName]?.message}
                        </ErrorText>
                    </ErrorContainer>
                )}
            </Container>
        </ParentContainer>
    )
}

export default BBUIFormTextInputColorButton
