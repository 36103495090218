import React from 'react'
import Cookies from 'js-cookie'

// Check if the user is logged in
const BBUIGetAuthToken = () => {
    try {
        const value = Cookies.get('BigBearAccount')
        // const value = localStorage.getItem("@BigBearAccounts:token");
        if (value !== null) {
            // We have data!!
            return value
        }
        return false
    } catch (error) {
        // Error getting data
        return false
    }
}

export default BBUIGetAuthToken
