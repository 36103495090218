import React from 'react'
import { styled } from 'dripsy'
import { View } from 'react-native'

const GroupStyled = styled(View)({
    flexDirection: 'row',
})

interface Props {
    children: React.ReactNode
}

const BBUIButtonGroup: React.FC<Props> = ({ children }) => {
    return <GroupStyled>{children}</GroupStyled>
}

export default BBUIButtonGroup
