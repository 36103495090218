import { Text, TextInput, TouchableOpacity, View } from 'react-native'

import { styled } from 'dripsy'

export const Container = styled(View)({
    flex: 1,
})
export const Header = styled(View)({
    marginTop: 15,
    marginBottom: 15,
    marginLeft: 10,
    marginRight: 10,
})
export const Title = styled(Text)({
    fontSize: 35,
    fontWeight: 'bold',
    color: '#FFF',
})
export const Description = styled(Text)({
    fontSize: 16,
    color: '#FFF',
})
export const UsernameTitle = styled(Text)({
    fontSize: 16,
    marginBottom: 10,
})
export const Username = styled(TextInput)({
    backgroundColor: '#e5e6e4',
    borderRadius: 5,
    padding: 14,
    marginBottom: 10,
    borderColor: '#6e6f6d',
    borderWidth: 1,
    fontSize: 20,
})
export const PasswordTitle = styled(Text)({
    fontSize: 16,
    marginBottom: 10,
})
export const Password = styled(TextInput)({
    backgroundColor: '#e5e6e4',
    borderRadius: 5,
    padding: 15,
    borderColor: '#6e6f6d',
    borderWidth: 1,
    marginBottom: 10,
})
export const Content = styled(View)({
    flex: 1,
    backgroundColor: 'white',
    borderTopLeftRadius: 25,
    borderTopRightRadius: 25,
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 20,
    paddingRight: 20,
    marginLeft: 10,
    marginRight: 10,
})
export const Footer = styled(View)({
    backgroundColor: 'white',
    borderTopWidth: 1,
    borderTopColor: '#e5e6e4',
    borderBottomLeftRadius: 25,
    borderBottomRightRadius: 25,
    paddingTop: 5.1,
    paddingBottom: 20,
    paddingLeft: 20,
    paddingRight: 20,
    marginLeft: 10,
    marginRight: 10,
})
export const LoginButton = styled(TouchableOpacity)({
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '$authButton',
    borderRadius: 10,
    padding: 15,
})
export const LoginButtonText = styled(Text)({
    color: '$authButtonText',
    fontSize: 17,
})
export const ForgotButton = styled(TouchableOpacity)({
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '$authButton',
    borderRadius: 10,
    marginTop: 15,
    padding: 15,
})
export const ForgotButtonText = styled(Text)({
    color: '$authButtonText',
    fontSize: 17,
})
export const RegisterButton = styled(TouchableOpacity)({
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '$authButton',
    borderRadius: 10,
    marginTop: 15,
    padding: 15,
})
export const RegisterButtonText = styled(Text)({
    color: 'white',
    fontSize: 17,
})

export const LegalButtons = styled(View)({
    flex: 1,
    flexDirection: 'row',
})

export const TosButton = styled(TouchableOpacity)({
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '$authButton',
    borderRadius: 10,
    marginTop: 15,
    padding: 15,
    marginRight: 10,
})
export const TosButtonText = styled(Text)({
    color: '$authButtonText',
    fontSize: 17,
})
export const PrivacyButton = styled(TouchableOpacity)({
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '$authButton',
    borderRadius: 10,
    marginTop: 15,
    padding: 15,
})
export const PrivacyButtonText = styled(Text)({
    color: '$authButtonText',
    fontSize: 17,
})

export const FormErrorText = styled(Text)({
    color: 'red',
    fontSize: 17,
    marginBottom: 10,
})
