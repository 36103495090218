import { Controller, useFormContext, useWatch } from 'react-hook-form'
import { FlatList, Image, Text, TouchableOpacity, View } from 'react-native'

import BBUIAlertModal from '../bbui-alert-modal/bbui-alert-modal'
import BBUIPush from '../bbui-push/bbui-push'
import BBUIUploadModal from '../bbui-upload-modal/bbui-upload-modal'
import React from 'react'
import collect from 'collect.js'
import { styled } from 'dripsy'
import useBBUINiceModalStore from '../bbui-nice-modal-store/bbui-nice-modal-store'
import { useRouter } from 'next/router'

const ParentContainer = styled(View)({})
const Container = styled(View)({
    flex: 1,
    marginLeft: 10,
    marginRight: 10,
    marginBottom: 10,
})

const FileContainer = styled(View)({})
const FilesContainer = styled(View)({})
const ButtonsContainer = styled(View)({
    flexDirection: 'row',
})
const TakePhotoButton = styled(TouchableOpacity)({
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff',
    padding: 10,
    borderWidth: 1,
    borderColor: '#a2b09d',
    borderRadius: 5,
    fontSize: 15,
    color: '#363636',
    marginRight: 1,
})
const TakePhotoButtonText = styled(Text)({})
const PickButton = styled(TouchableOpacity)({
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff',
    padding: 10,
    borderWidth: 1,
    borderColor: '#a2b09d',
    borderRadius: 5,
    fontSize: 15,
    color: '#363636',
})
const PickButtonText = styled(Text)({})

const TitleContainer = styled(View)({
    paddingBottom: 10,
})
const TitleText = styled(Text)({
    fontSize: 18,
    fontWeight: 'bold',
    color: '#333',
})

const ImageContainer = styled(TouchableOpacity)({
    paddingRight: 10,
    paddingBottom: 10,
})
const StyledImage = styled(Image)({
    width: 100,
    height: 100,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: '#a2b09d',
})

interface File {
    id: string
    uri: string
}

interface Props {
    testIDList?: string
    testIDTakePhoto?: string
    testIDChooseFile?: string
    title: string
    onImagePress?: ({
        id,
        files,
        new_files,
    }: {
        id: string
        files: File[]
        new_files: File[]
    }) => void
    onFilePicked?: ({
        uri,
        files,
        new_files,
    }: {
        uri: string
        files: File[]
        new_files: File[]
    }) => void
    onDeleteFilePress?: ({ id }: { id: string }) => void
    appName?: string
}

const BBUIFormFileUpload: React.FC<Props> = ({
    testIDList = 'list',
    testIDTakePhoto = 'take-photo-button',
    testIDChooseFile = 'choose-file-button',
    title,
    onImagePress = ({ id }) => {},
    onFilePicked = ({ uri, new_files }) => {},
    onDeleteFilePress = ({ id }) => {},
    appName = '',
}) => {
    const router = useRouter()
    const { addModal } = useBBUINiceModalStore()
    const { control, setValue, resetField } = useFormContext()
    const files = useWatch({
        control,
        name: 'files', // without supply name will watch the entire form, or ['firstName', 'lastName'] to watch both
    })
    return (
        <Controller
            control={control}
            render={({ field: { value } }) => (
                <ParentContainer>
                    <Container>
                        <TitleContainer>
                            <TitleText>{title}</TitleText>
                        </TitleContainer>
                        <FileContainer>
                            <FilesContainer>
                                <FlatList
                                    testID={testIDList}
                                    data={[...value, ...files]}
                                    keyExtractor={(item: { id: string }) =>
                                        item.id
                                    }
                                    horizontal
                                    showsHorizontalScrollIndicator={false}
                                    contentContainerStyle={{}}
                                    renderItem={({
                                        item,
                                        index,
                                    }: {
                                        item: File
                                        index: number
                                    }) => {
                                        return (
                                            <ImageContainer
                                                key={item.id}
                                                onPress={() => {
                                                    let existingFile: any = collect(
                                                        files,
                                                    )
                                                        .where('id', item.id)
                                                        .first()
                                                    let newFile: any = collect(
                                                        value,
                                                    )
                                                        .where('id', item.id)
                                                        .first()
                                                    BBUIPush({
                                                        path: BBUIAlertModal,
                                                        isModal: true,
                                                        addModal,
                                                        router,
                                                        state: {
                                                            title:
                                                                'Are you sure?',
                                                            message:
                                                                'Do you want to delete this file?',
                                                            buttons: [
                                                                {
                                                                    text:
                                                                        'Cancel',
                                                                    onPress: () => {
                                                                        return
                                                                    },
                                                                    style:
                                                                        'cancel',
                                                                },
                                                                {
                                                                    text: 'Yes',
                                                                    onPress: () => {
                                                                        if (
                                                                            existingFile !==
                                                                            undefined
                                                                        ) {
                                                                            const collection = collect(
                                                                                files,
                                                                            )
                                                                            const filtered = collection.filter(
                                                                                (
                                                                                    value,
                                                                                    key,
                                                                                ) =>
                                                                                    item.id !==
                                                                                    value.id,
                                                                            )
                                                                            onDeleteFilePress(
                                                                                {
                                                                                    id:
                                                                                        existingFile.id,
                                                                                },
                                                                            )
                                                                            setValue(
                                                                                'files',
                                                                                filtered?.items,
                                                                                {
                                                                                    shouldValidate: false,
                                                                                    shouldDirty: false,
                                                                                    shouldTouch: false,
                                                                                },
                                                                            )
                                                                        } else if (
                                                                            newFile !==
                                                                            undefined
                                                                        ) {
                                                                            const collection = collect(
                                                                                value,
                                                                            )
                                                                            const filtered = collection.filter(
                                                                                (
                                                                                    value,
                                                                                    key,
                                                                                ) =>
                                                                                    item.id !==
                                                                                    value.id,
                                                                            )
                                                                            if (
                                                                                filtered.all()
                                                                                    .length >
                                                                                0
                                                                            ) {
                                                                                setValue(
                                                                                    'new_files',
                                                                                    filtered,
                                                                                )
                                                                            } else {
                                                                                resetField(
                                                                                    'new_files',
                                                                                    {
                                                                                        keepDirty: false,
                                                                                        keepTouched: false,
                                                                                        keepError: false,
                                                                                        defaultValue: [],
                                                                                    },
                                                                                )
                                                                            }
                                                                        }
                                                                        onImagePress(
                                                                            {
                                                                                id:
                                                                                    item.id,
                                                                                files,
                                                                                new_files: value,
                                                                            },
                                                                        )
                                                                    },
                                                                },
                                                            ],
                                                            options: {
                                                                cancelable: false,
                                                            },
                                                        },
                                                    })
                                                }}>
                                                <StyledImage
                                                    source={{ uri: item.uri }}
                                                />
                                            </ImageContainer>
                                        )
                                    }}
                                />
                            </FilesContainer>
                            <ButtonsContainer>
                                <PickButton
                                    testID={testIDChooseFile}
                                    onPress={async () => {
                                        BBUIPush({
                                            path: BBUIUploadModal,
                                            isModal: true,
                                            addModal,
                                            router,
                                            state: {
                                                appName,
                                                type: 'temp',
                                                id: 0,
                                                onUploadComplete: ({
                                                    newMedia,
                                                }) => {
                                                    setValue(
                                                        'new_files',
                                                        [
                                                            ...newMedia.map(
                                                                media => {
                                                                    return {
                                                                        id:
                                                                            media.id,
                                                                        uri:
                                                                            media.url,
                                                                    }
                                                                },
                                                            ),
                                                            ...value,
                                                        ],
                                                        {
                                                            shouldDirty: true,
                                                        },
                                                    )
                                                    // onFilePicked({
                                                    //     uri: newMedia.url,
                                                    //     files,
                                                    //     new_files: value,
                                                    // });
                                                },
                                            },
                                        })
                                    }}>
                                    <PickButtonText>Choose File</PickButtonText>
                                </PickButton>
                            </ButtonsContainer>
                        </FileContainer>
                    </Container>
                </ParentContainer>
            )}
            name={'new_files'}
        />
    )
}

export default BBUIFormFileUpload
