import React from 'react'

interface BBUIUploadingTopbarProps {
    componentId: string
}

const BBUIUploadingTopbar: React.FC<BBUIUploadingTopbarProps> = ({
    componentId,
}) => {
    return null
}

export default BBUIUploadingTopbar
