import create, { GetState, SetState } from 'zustand'

import { BBUIUuidV4 } from '../..'
import { persist } from 'zustand/middleware'

interface Modal {
    componentId: string | undefined
    component: any
    props: any
    visible: boolean
}

interface ModalStore {
    modals: Modal[]
    getModal: (componentId: string | undefined) => Modal
    addModal: (component: any, props: any, visible: boolean) => void
    removeModal: (componentId) => void
    showModal: (componentId) => void
    hideModal: (componentId) => void
    dismissAllModals: () => void
}

const useBBUINiceModalStore = create<ModalStore>(
    (set: SetState<ModalStore>, get: GetState<ModalStore>) => ({
        modals: [],
        getModal: componentId => {
            return get().modals.find(state => state.componentId === componentId)
        },
        addModal: (component, props, visible = false) => {
            const uuid = BBUIUuidV4()
            return set(state => ({
                modals: [
                    {
                        componentId: `component${uuid}`,
                        component,
                        props,
                        visible,
                    },
                    ...state.modals,
                ],
            }))
        },
        removeModal: componentId =>
            set(state => ({
                modals: state.modals.filter(
                    state => state.componentId !== componentId,
                ),
            })),
        showModal: componentId =>
            set(state => ({
                modals: state.modals.map(state =>
                    state.componentId === componentId
                        ? ({
                              ...state,
                              visible: true,
                          } as Modal)
                        : state,
                ),
            })),
        hideModal: componentId =>
            set(state => ({
                modals: state.modals.map(state =>
                    state.componentId === componentId
                        ? ({
                              ...state,
                              visible: false,
                          } as Modal)
                        : state,
                ),
            })),
        dismissAllModals: () =>
            set(state => ({
                modals: [],
            })),
    }),
)

export default useBBUINiceModalStore
