import { Controller, useFormContext } from 'react-hook-form'
import { Text, TextInput, View } from 'react-native'

import NumberFormat from 'react-number-format'
import React from 'react'
import { styled } from 'dripsy'

const ParentContainer = styled(View)({})
const Container = styled(View)({
    flex: 1,
    marginLeft: 10,
    marginRight: 10,
    marginBottom: 10,
})

const TextInputContainer = styled(View)({})
const TextInputField = styled(TextInput)({
    backgroundColor: '#fff',
    padding: 10,
    borderWidth: 1,
    borderColor: '#a2b09d',
    borderRadius: 5,
    fontSize: 15,
})

const ErrorContainer = styled(View)({})
const ErrorText = styled(Text)({
    paddingTop: 10,
    fontSize: 15,
    color: 'red',
})

const TitleContainer = styled(View)({
    paddingBottom: 10,
})
const TitleText = styled(Text)({
    fontSize: 18,
    fontWeight: 'bold',
    color: '#333',
})

interface Props {
    testID?: string
    title: string
    fieldName: string
    placeholder?: string
}

const BBUIFormDecimal: React.FC<Props> = ({
    testID,
    title,
    fieldName,
    placeholder = '',
}) => {
    let _myDatetimeField
    const {
        control,
        formState: { errors },
    } = useFormContext()
    return (
        <ParentContainer>
            <Container>
                <TitleContainer>
                    <TitleText>{title}</TitleText>
                </TitleContainer>
                <TextInputContainer>
                    <Controller
                        control={control}
                        render={({ field: { onChange, onBlur, value } }) => (
                            <NumberFormat
                                className={`input `}
                                suffix={''}
                                name={fieldName}
                                id={fieldName}
                                onValueChange={values => {
                                    const { formattedValue, value } = values
                                    onChange(value)
                                }}
                                value={value}
                            />
                        )}
                        name={fieldName}
                    />
                </TextInputContainer>
                <ErrorContainer>
                    {errors[fieldName] && (
                        <ErrorText ellipsizeMode="tail" numberOfLines={2}>
                            {errors[fieldName]?.message}
                        </ErrorText>
                    )}
                </ErrorContainer>
            </Container>
        </ParentContainer>
    )
}

export default BBUIFormDecimal
