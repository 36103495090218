import BBUIGetAuthToken from '../bbui-get-auth-token/bbui-get-auth-token'
import BBUIRedirectToWeb from '../bbui-redirect-to-web/bbui-redirect-to-web'
import { Linking } from 'react-native'
import { NextRouter } from 'next/router'

interface BBUIPushProps {
    path?: any
    reactNativePath?: string
    directToWeb?: boolean
    isModal?: boolean
    addModal?: any
    replace?: boolean
    state?: any
    query?: any
    componentId?: string
    hasInertia?: boolean
    inertiaMethod?: string
    inertiaPreserveState?: boolean
    inertiaPreserveScroll?: boolean
    router: NextRouter
    push?: boolean
}

const BBUIPush = async ({
    path,
    reactNativePath = '',
    directToWeb = false,
    isModal = false,
    addModal = null,
    replace = false,
    state = {},
    query = {},
    componentId = '',
    hasInertia = false,
    inertiaMethod = 'get',
    inertiaPreserveState = false,
    inertiaPreserveScroll = false,
    router,
    push = false,
}: BBUIPushProps) => {
    if (directToWeb) {
        const home_url = '/' + router.pathname.split('/')[1]
        try {
            const token = await BBUIGetAuthToken()
            const url = BBUIRedirectToWeb(`${home_url}${path}`, token)
            Linking.openURL(url)
        } catch (error) {
            //   Alert.alert(error.message);
        }
    } else if (isModal) {
        addModal(
            path,
            {
                ...state,
            },
            true,
        )
    } else {
        console.log({
            path,
            reactNativePath,
            directToWeb,
            replace,
        })
        if (replace) {
            router.replace({ pathname: path, query })
        } else {
            router.push({
                pathname: path,
                query,
            })
        }
    }
}

export default BBUIPush
