import * as yup from 'yup'

import { BBUIAlertError, BBUIAxiosQuery, BBUIPush } from '../..'
import { Controller, useForm } from 'react-hook-form'
import {
    NativeMethods,
    Text,
    TextInput,
    TouchableOpacity,
    View,
} from 'react-native'
import React, { useRef } from 'react'

import { AuthPaths } from '../../index'
import BBUIPage from '../bbui-page/bbui-page'
import qs from 'qs'
import { styled } from 'dripsy'
import useBBUINiceModalStore from '../bbui-nice-modal-store/bbui-nice-modal-store'
import { useMutation } from '@tanstack/react-query'
import { useRouter } from 'next/router'
import { yupResolver } from '@hookform/resolvers/yup'

const Container = styled(View)({
    flex: 1,
})
const Header = styled(View)({
    marginTop: 15,
    marginBottom: 15,
    marginLeft: 10,
    marginRight: 10,
})
const Title = styled(Text)({
    fontSize: 35,
    fontWeight: 'bold',
    color: '#FFF',
})
const Description = styled(Text)({
    fontSize: 16,
    color: '#FFF',
})
const NameTitle = styled(Text)({
    fontSize: 16,
    marginBottom: 10,
})
const Name = styled(TextInput)({
    backgroundColor: '#e5e6e4',
    borderRadius: 5,
    padding: 14,
    marginBottom: 10,
    borderColor: '#6e6f6d',
    borderWidth: 1,
    fontSize: 20,
})
const EmailTitle = styled(Text)({
    fontSize: 16,
    marginBottom: 10,
})
const Email = styled(TextInput)({
    backgroundColor: '#e5e6e4',
    borderRadius: 5,
    padding: 14,
    marginBottom: 10,
    borderColor: '#6e6f6d',
    borderWidth: 1,
    fontSize: 20,
})
const PasswordTitle = styled(Text)({
    fontSize: 16,
    marginBottom: 10,
})
const Password = styled(TextInput)({
    backgroundColor: '#e5e6e4',
    borderRadius: 5,
    padding: 15,
    borderColor: '#6e6f6d',
    borderWidth: 1,
    marginBottom: 10,
})
const TosTitle = styled(Text)({
    fontSize: 16,
    marginBottom: 10,
})
const Content = styled(View)({
    flex: 1,
    backgroundColor: 'white',
    borderTopLeftRadius: 25,
    borderTopRightRadius: 25,
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 20,
    paddingRight: 20,
    marginLeft: 10,
    marginRight: 10,
})
const Footer = styled(View)({
    backgroundColor: 'white',
    borderTopWidth: 1,
    borderTopColor: '#e5e6e4',
    borderBottomLeftRadius: 25,
    borderBottomRightRadius: 25,
    paddingTop: 5.1,
    paddingBottom: 20,
    paddingLeft: 20,
    paddingRight: 20,
    marginLeft: 10,
    marginRight: 10,
})
const RegisterButton = styled(TouchableOpacity)({
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '$authButton',
    borderRadius: 10,
    padding: 15,
    marginTop: 10,
})
const RegisterButtonText = styled(Text)({
    color: '$authButtonText',
    fontSize: 17,
})
const LoginButton = styled(TouchableOpacity)({
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '$authButton',
    borderRadius: 10,
    marginTop: 15,
    padding: 15,
})
const LoginButtonText = styled(Text)({
    color: 'white',
    fontSize: 17,
})

const LegalButtons = styled(View)({
    flex: 1,
    flexDirection: 'row',
})

const TosButton = styled(TouchableOpacity)({
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '$authButton',
    borderRadius: 10,
    marginTop: 15,
    padding: 15,
    marginRight: 10,
})
const TosButtonText = styled(Text)({
    color: '$authButtonText',
    fontSize: 17,
})
const PrivacyButton = styled(TouchableOpacity)({
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '$authButton',
    borderRadius: 10,
    marginTop: 15,
    padding: 15,
})
const PrivacyButtonText = styled(Text)({
    color: '$authButtonText',
    fontSize: 17,
})

const FormErrorText = styled(Text)({
    color: 'red',
    fontSize: 17,
    marginBottom: 10,
})

const schema = yup.object().shape({
    email: yup
        .string()
        .email('Email must be a valid email')
        .required('Email is a required field'),
})

interface Props {
    componentId: string
    appName: string
    appUrl: string
    year: string
    productId: number
    onSuccess: any
}

const BBUIForgotPage: React.FC<Props> = ({
    componentId,
    appName,
    appUrl,
    year = '2022',
    productId,
    onSuccess,
}) => {
    const router = useRouter()
    const { addModal } = useBBUINiceModalStore()

    const query = router.query
    const queryParsed = qs.parse(query)

    const forgotPassword = useMutation(
        async ({
            email,
        }: AuthPaths['/bigbearaccounts/api/v1/forgot/password']['post']['requestBody']['application/json']) => {
            const data: AuthPaths['/bigbearaccounts/api/v1/forgot/password']['post']['responses']['200']['application/json'] = await BBUIAxiosQuery(
                {
                    url: `/bigbearaccounts/api/v1/forgot/password`,
                    data: {
                        email,
                    },
                    method: 'POST',
                },
            )
            return data.data
        },
    )
    const {
        control,
        handleSubmit,
        formState: { errors },
        setError,
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            name: '',
            email: '',
            password: '',
            terms: false,
        },
    })
    const name: { current: NativeMethods } = useRef(null)
    const email: { current: NativeMethods } = useRef(null)
    const password: { current: NativeMethods } = useRef(null)
    const onSubmit = data => {
        forgotPassword.mutate(
            {
                email: data.email,
            },
            {
                onSuccess: (
                    res: AuthPaths['/bigbearaccounts/api/v1/forgot/password']['post']['responses']['200']['application/json']['data'],
                ) => {
                    console.log({ res })
                    BBUIAlertError({
                        title: 'Successfully sent reset password email',
                        text:
                            'If this email address is correct, you will receive an email with instructions on how to reset your password. Check your spam folder.',
                        okButtonText: 'OK',
                        addModal,
                        router,
                    })
                    onSuccess({
                        username: data.email,
                        accept_token: queryParsed.inviteId,
                        deny_token: queryParsed.deny_token,
                        product_id: queryParsed.product_id,
                        redirect: queryParsed.redirect,
                    })
                },
                onError: err => {
                    console.log({ err })
                    BBUIAlertError({
                        title: 'Email is incorrect',
                        text: 'Try to refresh the page and try, again!',
                        okButtonText: 'OK',
                        onOkPress: () => {
                            window.location.reload()
                        },
                        addModal,
                        router,
                    })
                },
            },
        )
    }
    return (
        <BBUIPage testID="scrollView" isScrollable={false} hasFormLogin>
            {({}) => {
                return (
                    <Container>
                        <Header>
                            <Title>BigBearApps</Title>
                            <Description>Forgot Password</Description>
                        </Header>
                        <Content>
                            <EmailTitle>Email</EmailTitle>
                            <Controller
                                control={control}
                                render={({
                                    field: { onChange, onBlur, value },
                                }) => (
                                    <Email
                                        testID="email-input"
                                        // ref={email}
                                        autoCapitalize={'none'}
                                        keyboardType="email-address"
                                        returnKeyType="next"
                                        onSubmitEditing={() => {
                                            password.current.focus()
                                        }}
                                        onBlur={onBlur}
                                        onChangeText={onChange}
                                        value={value}
                                    />
                                )}
                                name="email"
                            />
                            {errors.email && (
                                <FormErrorText>
                                    {errors.email.message}
                                </FormErrorText>
                            )}

                            {!forgotPassword.isLoading ? (
                                <RegisterButton
                                    testID="forgot-button"
                                    onPress={handleSubmit(onSubmit)}>
                                    <RegisterButtonText>
                                        Send Password Reset
                                    </RegisterButtonText>
                                </RegisterButton>
                            ) : (
                                <RegisterButton>
                                    <RegisterButtonText>
                                        Loading...
                                    </RegisterButtonText>
                                </RegisterButton>
                            )}
                        </Content>
                        <Footer>
                            <LoginButton
                                testID="login-button"
                                onPress={() => {
                                    BBUIPush({
                                        componentId,
                                        path: '/accounts/login',
                                        reactNativePath:
                                            'registerComponentName',
                                        router,
                                    })
                                }}>
                                <LoginButtonText>Login</LoginButtonText>
                            </LoginButton>
                            <LegalButtons>
                                <TosButton
                                    testID="tos-button"
                                    onPress={() => {
                                        BBUIPush({
                                            componentId,
                                            path: '/legal/tos',
                                            reactNativePath:
                                                'registerComponentName',
                                            router,
                                        })
                                    }}>
                                    <TosButtonText>
                                        Terms of Service
                                    </TosButtonText>
                                </TosButton>
                                <PrivacyButton
                                    testID="privacy-button"
                                    onPress={() => {
                                        BBUIPush({
                                            componentId,
                                            path: '/legal/privacy',
                                            reactNativePath:
                                                'registerComponentName',
                                            router,
                                        })
                                    }}>
                                    <PrivacyButtonText>
                                        Privacy Policy
                                    </PrivacyButtonText>
                                </PrivacyButton>
                            </LegalButtons>
                        </Footer>
                    </Container>
                )
            }}
        </BBUIPage>
    )
}

export default BBUIForgotPage
