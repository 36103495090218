import { Theme } from '@dripsy/core'
import { theme } from './theme'

// make sure you don't mutate the original theme
export const bigbearappsTheme: Theme = { ...theme }

bigbearappsTheme.colors = {
    $background: '#854E0F',
    $authButtonText: '#fff',
    $authButton: '#854E0F',
}
