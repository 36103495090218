import { Controller, useFormContext } from 'react-hook-form'
import { Text, TextInput, View } from 'react-native'

import GooglePlacesAutocomplete from 'react-google-places-autocomplete'
import React from 'react'
import { styled } from 'dripsy'

const ParentContainer = styled(View)({})
const Container = styled(View)({
    // flex: 1,
    marginLeft: 10,
    marginRight: 10,
    marginBottom: 10,
})

const TextInputContainer = styled(View)({})
const SelectContainer = styled(View)({})

const ErrorContainer = styled(View)({})
const ErrorText = styled(Text)({
    paddingTop: 10,
    fontSize: 15,
    color: 'red',
})
const PoweredByText = styled(Text)({
    zIndex: 1,
    paddingTop: 10,
    fontSize: 15,
    color: 'blue',
})

const TitleContainer = styled(View)({
    paddingBottom: 10,
})
const TitleText = styled(Text)({
    fontSize: 18,
    fontWeight: 'bold',
    color: '#333',
})

interface LatLng {
    lat: number
    lng: number
}

interface AutocompletionRequest {
    bounds?: [LatLng, LatLng]
    componentRestrictions?: { country: string | string[] }
    location?: LatLng
    offset?: number
    radius?: number
    types?: string[]
}

interface Props {
    testID?: string
    title: string
    fieldName: string
    isMultiline?: boolean
    onChangeText?: (place: any) => void
    googlePlacesApiKey?: string
    autocompletionRequest?: AutocompletionRequest
}

const libraries: any = ['geometry', 'drawing', 'places']

const BBUIFormGooglePlacesInput: React.FC<Props> = ({
    testID = 'input',
    title,
    fieldName,
    isMultiline = false,
    onChangeText,
    googlePlacesApiKey,
    autocompletionRequest,
}) => {
    const {
        control,
        formState: { errors },
        setValue,
    } = useFormContext()
    return (
        <ParentContainer>
            <Container>
                <TitleContainer>
                    <TitleText>{title} (Powered by Google)</TitleText>
                </TitleContainer>
                <TextInputContainer>
                    <Controller
                        control={control}
                        render={({ field: { onChange, onBlur, value } }) => (
                            <SelectContainer testID={testID}>
                                <GooglePlacesAutocomplete
                                    apiKey={googlePlacesApiKey}
                                    apiOptions={{
                                        libraries,
                                    }}
                                    debounce={500}
                                    minLengthAutocomplete={4}
                                    selectProps={{
                                        onChange: place => {
                                            console.log({ place })
                                            onChangeText(place)
                                        },
                                        inputId: testID,
                                        ['aria-label']:
                                            'google-places-autocomplete',
                                        className:
                                            'google-places-autocomplete-select',
                                    }}
                                    autocompletionRequest={
                                        autocompletionRequest
                                    }
                                />
                            </SelectContainer>
                        )}
                        name={fieldName}
                    />
                </TextInputContainer>
                <ErrorContainer>
                    {errors[fieldName] && (
                        <ErrorText ellipsizeMode="tail" numberOfLines={2}>
                            {errors[fieldName]?.message}
                        </ErrorText>
                    )}
                </ErrorContainer>
            </Container>
        </ParentContainer>
    )
}

export default BBUIFormGooglePlacesInput
