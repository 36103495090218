import * as yup from 'yup'

import { BBUIAlertError, BBUIAxiosQuery, BBUIPage } from '../..'
import {
    Container,
    Content,
    Description,
    Footer,
    ForgotButton,
    ForgotButtonText,
    FormErrorText,
    Header,
    LegalButtons,
    LoginButton,
    LoginButtonText,
    Password,
    PasswordTitle,
    PrivacyButton,
    PrivacyButtonText,
    RegisterButton,
    RegisterButtonText,
    Title,
    TosButton,
    TosButtonText,
    Username,
    UsernameTitle,
} from './LoginStyledComponents'
import { Controller, useForm } from 'react-hook-form'
import {
    KeyboardAvoidingView,
    Platform,
    Text,
    TextInput,
    TouchableOpacity,
    View,
} from 'react-native'
import React, { useEffect, useRef, useState } from 'react'

import { AuthPaths } from '../../index'
import BBUIPush from '../bbui-push/bbui-push'
import BBUISaveLogin from '../bbui-save-login/bbui-save-login'
import qs from 'qs'
import useBBUINiceModalStore from '../bbui-nice-modal-store/bbui-nice-modal-store'
import { useMutation } from '@tanstack/react-query'
import { useRouter } from 'next/router'
import { yupResolver } from '@hookform/resolvers/yup'

const schema = yup.object().shape({
    username: yup
        .string()
        .email('Email must be a valid email')
        .required('Email is a required field'),
    password: yup
        .string()
        .min(6, 'Password must be at least 6 characters')
        .required('Password is a required field'),
})

interface Props {
    componentId: string
    appName: string
    appUrl: string
    year: string
    productId: number
    params?: any
    onSuccess: any
    loggedInComponentName: string
    registerComponentName: string
}

const BBUILoginPage: React.FC<Props> = ({
    componentId,
    appName,
    appUrl,
    year = '2022',
    productId,
    params,
    onSuccess,
    loggedInComponentName = 'AuthLoading',
    registerComponentName = 'Register',
}) => {
    const router = useRouter()
    const { addModal } = useBBUINiceModalStore()

    const [showPassword, setShowPassword] = useState(false)

    // Focus on username input
    useEffect(() => {
        usernameRef.current?.focus()
    }, [])

    const query = router.query
    // @ts-ignore
    const queryParsed = qs.parse(query)

    const loginUser = useMutation(
        async ({
            email,
            password,
        }: AuthPaths['/bigbearaccounts/api/v1/login']['post']['requestBody']['application/json']) => {
            const data: AuthPaths['/bigbearaccounts/api/v1/login']['post']['responses']['200']['application/json'] = await BBUIAxiosQuery(
                {
                    url: `/bigbearaccounts/api/v1/login`,
                    data: {
                        ...{
                            email,
                            password,
                            product_id: productId,
                        },
                        ...params,
                    },
                    method: 'POST',
                },
            )
            return data.data
        },
    )
    const {
        control,
        handleSubmit,
        formState: { errors, isValid },
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            username: '',
            password: '',
        },
        mode: 'onChange', // This will make isValid update on each change
    })

    const usernameRef = useRef<TextInput>(null)
    const passwordRef = useRef<TextInput>(null)

    const onSubmit = data => {
        loginUser.mutate(
            {
                email: data.username,
                password: data.password,
            },
            {
                onSuccess: (
                    res: AuthPaths['/bigbearaccounts/api/v1/login']['post']['responses']['200']['application/json']['data'],
                ) => {
                    BBUISaveLogin(res.auth_token.access_token)
                    onSuccess({
                        username: data.username,
                        password: data.password,
                        magic_link: res.magic_link ? res.magic_link : null,
                        accept_token: queryParsed.inviteId,
                        deny_token: queryParsed.deny_token,
                        product_id: queryParsed.product_id,
                        redirect: queryParsed.redirect,
                        bigbeartoken: res.auth_token.access_token,
                        is_two_factor_enabled: res.is_two_factor_enabled,
                    })
                },
                onError: err => {
                    let errorMessage =
                        'An unexpected error occurred. Please try again.'
                    // @ts-ignore
                    if (err.response) {
                        // @ts-ignore
                        switch (err.response.status) {
                            case 401:
                                errorMessage = 'Email or password is incorrect!'
                                break
                            case 429:
                                errorMessage =
                                    'Too many attempts. Please try again later.'
                                break
                            case 500:
                                errorMessage =
                                    'Server error. Please try again later.'
                                break
                        }
                    }
                    BBUIAlertError({
                        title: ':(',
                        text: errorMessage,
                        okButtonText: 'OK',
                        addModal,
                        router,
                    })
                },
            },
        )
    }
    return (
        <KeyboardAvoidingView
            behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
            style={{ flex: 1 }}>
            <BBUIPage testID="scrollView" isScrollable={false} hasFormLogin>
                {({}) => {
                    return (
                        <Container>
                            <Header>
                                <Title>One Login For All</Title>
                                <Description>
                                    Login with your BigBearAccount
                                </Description>
                            </Header>
                            <Content>
                                <UsernameTitle>Email</UsernameTitle>
                                <Controller
                                    control={control}
                                    rules={{
                                        required: true,
                                    }}
                                    render={({
                                        field: { onChange, onBlur, value },
                                    }) => (
                                        <Username
                                            testID="email-input"
                                            ref={usernameRef}
                                            autoCapitalize={'none'}
                                            keyboardType="email-address"
                                            returnKeyType="next"
                                            onSubmitEditing={() => {
                                                passwordRef.current?.focus()
                                            }}
                                            onBlur={onBlur}
                                            onChangeText={onChange}
                                            value={value}
                                        />
                                    )}
                                    name="username"
                                />
                                {errors.username && (
                                    <FormErrorText>
                                        {errors.username.message}
                                    </FormErrorText>
                                )}
                                <PasswordTitle>Password</PasswordTitle>
                                <View
                                    style={{
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                    }}>
                                    <Controller
                                        control={control}
                                        render={({
                                            field: { onChange, onBlur, value },
                                        }) => (
                                            <Password
                                                testID="password-input"
                                                ref={passwordRef}
                                                keyboardType="default"
                                                autoCorrect={false}
                                                autoComplete="password"
                                                secureTextEntry={!showPassword}
                                                autoCapitalize={'none'}
                                                returnKeyType="done"
                                                onBlur={onBlur}
                                                onChangeText={onChange}
                                                value={value}
                                                accessibilityLabel="Password input"
                                                accessibilityHint="Enter your password"
                                                style={{ flex: 1 }}
                                            />
                                        )}
                                        name="password"
                                    />
                                    <TouchableOpacity
                                        onPress={() =>
                                            setShowPassword(!showPassword)
                                        }
                                        style={{
                                            padding: 10,
                                            marginBottom: 10,
                                        }}
                                        accessibilityLabel={
                                            showPassword
                                                ? 'Hide password'
                                                : 'Show password'
                                        }
                                        accessibilityHint={
                                            showPassword
                                                ? 'Tap to hide password'
                                                : 'Tap to show password'
                                        }>
                                        <Text>
                                            {showPassword ? 'Hide' : 'Show'}
                                        </Text>
                                    </TouchableOpacity>
                                </View>
                                {errors.password && (
                                    <FormErrorText>
                                        {errors.password.message}
                                    </FormErrorText>
                                )}
                                {!loginUser.isLoading ? (
                                    <LoginButton
                                        testID="sign-in-button"
                                        onPress={handleSubmit(onSubmit)}
                                        disabled={
                                            loginUser.isLoading || !isValid
                                        }
                                        accessibilityLabel="Login"
                                        accessibilityHint="Press to submit your login information">
                                        <LoginButtonText>Login</LoginButtonText>
                                    </LoginButton>
                                ) : (
                                    <LoginButton disabled>
                                        <LoginButtonText>
                                            Loading...
                                        </LoginButtonText>
                                    </LoginButton>
                                )}
                            </Content>
                            <Footer>
                                <RegisterButton
                                    testID="register-button"
                                    onPress={() => {
                                        console.log({ queryParsed })
                                        BBUIPush({
                                            componentId,
                                            path: `/accounts/register`,
                                            reactNativePath:
                                                'registerComponentName',
                                            router,
                                            query: {
                                                redirect: queryParsed.redirect,
                                                type: queryParsed.type
                                                    ? queryParsed.type
                                                    : null,
                                                id: queryParsed.id
                                                    ? queryParsed.id
                                                    : null,
                                            },
                                        })
                                    }}>
                                    <RegisterButtonText>
                                        New here?
                                    </RegisterButtonText>
                                </RegisterButton>
                                <ForgotButton
                                    testID="forgot-button"
                                    onPress={() => {
                                        BBUIPush({
                                            componentId,
                                            path: '/accounts/forgot',
                                            reactNativePath:
                                                'registerComponentName',
                                            router,
                                        })
                                    }}>
                                    <ForgotButtonText>
                                        Forgot Password
                                    </ForgotButtonText>
                                </ForgotButton>

                                <LegalButtons>
                                    <TosButton
                                        testID="tos-button"
                                        onPress={() => {
                                            BBUIPush({
                                                componentId,
                                                path: '/legal/tos',
                                                reactNativePath:
                                                    'registerComponentName',
                                                router,
                                            })
                                        }}>
                                        <TosButtonText>
                                            Terms of Service
                                        </TosButtonText>
                                    </TosButton>
                                    <PrivacyButton
                                        testID="privacy-button"
                                        onPress={() => {
                                            BBUIPush({
                                                componentId,
                                                path: '/legal/privacy',
                                                reactNativePath:
                                                    'registerComponentName',
                                                router,
                                            })
                                        }}>
                                        <PrivacyButtonText>
                                            Privacy Policy
                                        </PrivacyButtonText>
                                    </PrivacyButton>
                                </LegalButtons>
                            </Footer>
                        </Container>
                    )
                }}
            </BBUIPage>
        </KeyboardAvoidingView>
    )
}

export default BBUILoginPage
