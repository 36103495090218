import React from 'react'
import { ScrollView, View } from 'react-native'
import { styled } from 'dripsy'

const StyledListItemContainerScroll = styled(ScrollView)({
    backgroundColor: '#fff',
    marginLeft: 10,
    marginRight: 10,
})

const StyledListItemContainerView = styled(View)({
    flex: 1,
    backgroundColor: '#fff',
    marginLeft: 10,
    marginRight: 10,
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
})

interface Props {
    children: React.ReactNode
    isScrollable?: boolean
}

const BBUIListContainer: React.FC<Props> = ({
    children,
    isScrollable = true,
}) => {
    if (isScrollable) {
        return (
            <StyledListItemContainerScroll>
                {children}
            </StyledListItemContainerScroll>
        )
    }
    return <StyledListItemContainerView>{children}</StyledListItemContainerView>
}

export default BBUIListContainer
