import { BBUIDayjs } from '../bbui-dayjs/bbui-dayjs'
import create from 'zustand'
import { persist } from 'zustand/middleware'

interface FileParameters {
    id: string
    type: string
}

interface File {
    uploadId: string
    progress: number
    path: string
    parameters: FileParameters
    started: boolean
    completed: boolean
    completedAt: string
    hasError: boolean
    error: Error
}

interface FileStore {
    files: File[]
    addFile: (uploadId, progress, path, parameters) => void
    retryUpload: (uploadId) => void
    updateUploadId: (uploadId) => void
    startUpload: (uploadId) => void
    updateFileProgress: (uploadId, progress) => void
    uploadFileComplete: (uploadId) => void
    removeUpload: (uploadId) => void
    clearCompleted: () => void
    uploadFileError: (uploadId, error) => void
}

const useBBUIStore = create<FileStore>()(
    persist(
        (set, get) => ({
            files: [],
            addFile: (uploadId, progress, path, parameters) =>
                set(state => ({
                    files: [
                        {
                            uploadId: uploadId,
                            progress: progress,
                            path: path,
                            parameters: parameters,
                            started: false,
                            completed: false,
                            completedAt: null,
                            hasError: false,
                            error: null,
                        },
                        ...state.files,
                    ],
                })),
            retryUpload: uploadId => {
                set(state => ({
                    files: state.files.map(file =>
                        file.uploadId === uploadId
                            ? ({
                                  ...file,
                                  progress: 0,
                                  started: false,
                                  completed: false,
                                  hasError: false,
                                  error: null,
                              } as File)
                            : file,
                    ),
                }))
            },
            updateUploadId: uploadId => {
                set(state => ({
                    files: state.files.map(file =>
                        file.uploadId === uploadId
                            ? ({ ...file, uploadId } as File)
                            : file,
                    ),
                }))
            },
            startUpload: uploadId => {
                set(state => ({
                    files: state.files.map(file =>
                        file.uploadId === uploadId
                            ? ({ ...file, started: true } as File)
                            : file,
                    ),
                }))
            },
            updateFileProgress: (uploadId, progress) => {
                set(state => ({
                    files: state.files.map(file =>
                        file.uploadId === uploadId
                            ? ({ ...file, progress } as File)
                            : file,
                    ),
                }))
            },
            uploadFileComplete: uploadId => {
                set(state => ({
                    files: state.files.map(file =>
                        file.uploadId === uploadId
                            ? ({
                                  ...file,
                                  completed: true,
                                  completedAt: BBUIDayjs().toISOString(),
                              } as File)
                            : file,
                    ),
                }))
            },
            removeUpload: uploadId =>
                set(state => ({
                    files: state.files.filter(
                        state => state.uploadId !== uploadId,
                    ),
                })),
            clearCompleted: () =>
                set(state => ({
                    files: state.files.filter(state => !state.completed),
                })),
            uploadFileError: (uploadId, error) => {
                set(state => ({
                    files: state.files.map(file =>
                        file.uploadId === uploadId
                            ? ({ ...file, error, hasError: true } as File)
                            : file,
                    ),
                }))
            },
        }),
        {
            name: 'bbui-files', // unique name
            getStorage: () => localStorage, // (optional) by default, 'localStorage' is used
        },
    ),
)

export default useBBUIStore
