import React from 'react'
import { View } from 'react-native'
import { styled } from 'dripsy'

const StyledContainer = styled(View)({
    padding: 10,
    paddingBottom: 0,
    marginLeft: 10,
    marginRight: 10,
    backgroundColor: '#fff',
    borderRadius: 5,
    marginBottom: 10,
})

interface Props {
    children: any
}

const BBUISearchBox: React.FC<Props> = ({ children }) => {
    return <StyledContainer>{children}</StyledContainer>
}

export default BBUISearchBox
