import { Text, View } from 'react-native'

import React from 'react'
import { styled } from 'dripsy'

const Container = styled(View)({
    flex: 1,
    alignItems: 'center',
    paddingTop: 10,
    backgroundColor: '#fff',
    padding: 10,
    marginLeft: 10,
    marginRight: 10,
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    marginBottom: 40,
})
const TitleContainer = styled(View)({
    marginBottom: 10,
})
const TitleText = styled(Text)({
    fontSize: 16,
    color: '#000',
})
const VersionContainer = styled(View)({})
const VersionText = styled(Text)({
    fontSize: 14,
    color: '#000',
})

interface Props {
    title: string
    version: string | undefined
}

const BBUIFooterHome: React.FC<Props> = ({ title, version }) => {
    return (
        <Container>
            <TitleContainer>
                <TitleText>{title}</TitleText>
            </TitleContainer>
            <VersionContainer>
                <VersionText>{version}</VersionText>
            </VersionContainer>
        </Container>
    )
}

export default BBUIFooterHome
