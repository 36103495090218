import 'react-toastify/dist/ReactToastify.css'
import 'react-datepicker/src/stylesheets/datepicker.scss'
import '../lib/styles.scss'
import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import '@uppy/drag-drop/dist/style.css'
import '@uppy/file-input/dist/style.css'
import '@uppy/progress-bar/dist/style.css'

import { DripsyProvider, Theme } from 'dripsy'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ToastContainer, toast } from 'react-toastify'

import React from 'react'
import Script from 'next/script'
import { bigbearappsTheme } from '@bigbearui/index'

// Create a client
const queryClient = new QueryClient()

const App = ( { Component, pageProps } ) => {
    let customTheme: Theme = bigbearappsTheme
    return (
        <>
            {process.env.NEXT_PUBLIC_UMAMI_SCRIPT_URL &&
                process.env.NEXT_PUBLIC_UMAMI_WEBSITE_ID && (
                    <Script
                        src={process.env.NEXT_PUBLIC_UMAMI_SCRIPT_URL}
                        data-website-id={
                            process.env.NEXT_PUBLIC_UMAMI_WEBSITE_ID
                        }
                        strategy="lazyOnload"
                    />
                )}
            <QueryClientProvider client={queryClient}>
                <DripsyProvider
                    ssr
                    ssrPlaceholder={null} // optional
                    theme={customTheme}>
                    <Component {...pageProps} />

                    <ToastContainer
                        position={toast.POSITION.BOTTOM_RIGHT}
                        limit={2}
                    />
                </DripsyProvider>
            </QueryClientProvider>
        </>
    )
}

export default App
