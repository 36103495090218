import React from 'react'
import jwtDecode, { JwtPayload } from 'jwt-decode'

// Check if the user is logged in
// Check if the user is logged in
const BBUIGetUserId = async () => {
    try {
        const value = localStorage.getItem('@BigBearAccounts:token')
        if (value !== null) {
            let jwt = jwtDecode<JwtPayload>(value)
            // We have data!!
            return jwt.sub
        }
        return false
    } catch (error) {
        console.log(error)
        // Error getting data
        return false
    }
}

export default BBUIGetUserId
