import React from 'react'
import { TouchableOpacity, Text } from 'react-native'
import { styled } from 'dripsy'

const BBUIWelcomeButton = styled(TouchableOpacity)({
    marginLeft: 10,
})
const BBUIWelcomeButtonText = styled(Text)({
    color: '#fff',
    fontSize: 14,
    fontWeight: '500',
})

interface TopbarProps {
    name?: string
}

const BBUIWelcomeHome: React.FC<TopbarProps> = ({ name = '' }) => {
    return (
        <BBUIWelcomeButton>
            <BBUIWelcomeButtonText>Welcome, {name}</BBUIWelcomeButtonText>
        </BBUIWelcomeButton>
    )
}

export default BBUIWelcomeHome
