import { Theme } from '@dripsy/core'
import { theme } from './theme'

// make sure you don't mutate the original theme
export const mealinvyTheme: Theme = { ...theme }

mealinvyTheme.colors = {
    $background: '#FF3435',
    $authButtonText: '#fff',
    $authButton: '#FF3435',
}
