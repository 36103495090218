import { Controller, useFormContext } from 'react-hook-form'
import { TextInput, TouchableOpacity, View } from 'react-native'

import Icon from 'react-native-vector-icons/FontAwesome5'
import React from 'react'
import { styled } from 'dripsy'

const StyledContainer = styled(View)({
    marginTop: 20,
    marginLeft: 10,
    marginRight: 10,
    flexDirection: 'row',
    paddingBottom: 10,
})
const Left = styled(View)({
    alignItems: 'center',
    justifyContent: 'center',
})
const LeftBackButton = styled(TouchableOpacity)({})
const Right = styled(View)({
    flex: 1,
})
const SearchContainer = styled(View)({
    marginLeft: 10,
    backgroundColor: '#fff',
    borderRadius: 100,
    flexDirection: 'row',
})
const SearchInput = styled(TextInput)({
    flex: 1,
    fontSize: 20,
    padding: 1,
    paddingLeft: 20,
    paddingRight: 20,
})
const SearchIcon = styled(TouchableOpacity)({
    alignItems: 'center',
    justifyContent: 'center',
    paddingRight: 1,
})

interface Props {
    componentId: string | undefined
    placeholder?: string
    onBackPress?: () => void
    isMainSearch?: boolean
    isAutoFocusOn?: boolean
}

const BBUISearchHeader: React.FC<Props> = ({
    componentId,
    placeholder = 'Search',
    onBackPress,
    isMainSearch = true,
    isAutoFocusOn = true,
}) => {
    const { control, setValue } = useFormContext()
    return (
        <StyledContainer>
            <Left>
                <LeftBackButton
                    testID="search-back-button"
                    onPress={onBackPress}>
                    <Icon name={'chevron-left'} size={25} color="#FFF" />
                </LeftBackButton>
            </Left>
            <Right>
                <SearchContainer>
                    <Controller
                        control={control}
                        render={({ field: { onChange, onBlur, value } }) => (
                            <>
                                <SearchInput
                                    testID="search-input"
                                    placeholder={placeholder}
                                    returnKeyType="search"
                                    onChangeText={value => {
                                        // if (isMainSearch) {
                                        //     setValue('type', null)
                                        // }
                                        onChange(value)
                                    }}
                                    autoFocus={isAutoFocusOn}
                                    value={value}
                                />
                                {value !== '' && (
                                    <SearchIcon
                                        onPress={() => {
                                            onChange('')
                                            if (isMainSearch) {
                                                setValue('type', null)
                                            }
                                        }}>
                                        <Icon
                                            name={'times-circle'}
                                            size={25}
                                            color="#999"
                                        />
                                    </SearchIcon>
                                )}
                            </>
                        )}
                        name={'query'}
                    />
                </SearchContainer>
            </Right>
        </StyledContainer>
    )
}

export default BBUISearchHeader
