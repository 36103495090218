import { FlatList, Text, TouchableOpacity, View } from 'react-native'

import React from 'react'
import { styled } from 'dripsy'

const StyledContainer = styled(View)({
    marginLeft: 10,
    marginRight: 10,
})

const StyledPill = styled(TouchableOpacity)((props: { active: boolean }) => ({
    backgroundColor: props.active ? '#fff' : '#fff',
    borderWidth: props.active ? 2 : 2,
    borderColor: props.active ? '#444' : '#fff',
    borderRadius: 50,
    padding: 15,
    marginRight: 10,
    marginBottom: 10,
}))

const StyledPillText = styled(Text)({
    color: '#444',
    fontSize: 13,
})

interface Action {
    id: string
    title: string
    onPress: () => void
    active?: boolean
}

interface Props {
    actions: Action[]
}

const BBUIActionPills: React.FC<Props> = ({ actions }) => {
    return (
        <StyledContainer>
            <FlatList
                data={actions}
                keyExtractor={(item: { id: string }) => item.id}
                horizontal
                renderItem={({ item, index }: any) => {
                    return (
                        <StyledPill
                            key={index}
                            onPress={item.onPress}
                            active={item.active}>
                            <StyledPillText>{item.title}</StyledPillText>
                        </StyledPill>
                    )
                }}></FlatList>
        </StyledContainer>
    )
}

export default BBUIActionPills
