import Hashids from 'hashids'

const boxinvyHashids = new Hashids('BoxinvyHashIds', 13)
const doorknockerpalHashids = new Hashids('DoorKnockerPalHashIds', 13)
const wealtorHashids = new Hashids('Wealtor', 13)
const bigbearhealthHashids = new Hashids('BigBearHealthHashIds', 13)
const emailcoveHashids = new Hashids('EmailCoveHashIds', 13)
const mealinvyHashids = new Hashids('BigBearMealinvyHashIds', 13)
const bigbearmomentsHashids = new Hashids('BigBearMomentsHashIds', 13)
const goalpenseHashids = new Hashids('Goalpense', 13)
const panelbayHashids = new Hashids('eDdDfsVjso8NfPy4GfB8', 13)
const reviewpalHashids = new Hashids('4gcVHPzRKQcZMv9bimsJ', 13)

const BBUIDecode = (id, app = '') => {
    if (app === 'boxinvy') {
        return String(boxinvyHashids.decode(id)[0])
    }
    if (app === 'doorknockerpal') {
        return String(doorknockerpalHashids.decode(id)[0])
    }
    if (app === 'wealtor') {
        return String(wealtorHashids.decode(id)[0])
    }
    if (app === 'bigbearhealth') {
        return String(bigbearhealthHashids.decode(id)[0])
    }
    if (app === 'emailcove') {
        return String(emailcoveHashids.decode(id)[0])
    }
    if (app === 'mealinvy') {
        return String(mealinvyHashids.decode(id)[0])
    }
    if (app === 'bigbearmoments') {
        return String(bigbearmomentsHashids.decode(id)[0])
    }
    if (app === 'goalpense') {
        return String(goalpenseHashids.decode(id)[0])
    }
    if (app === 'panelbay') {
        return String(panelbayHashids.decode(id)[0])
    }
    if (app === 'wealtor') {
        return String(wealtorHashids.decode(id)[0])
    }
    if (app === 'reviewpal') {
        return String(reviewpalHashids.decode(id)[0])
    }
    return id
}

export default BBUIDecode
