import { Alert } from 'react-native'
import BBUIAlertModal from '../bbui-alert-modal/bbui-alert-modal'
import BBUIPush from '../bbui-push/bbui-push'
import { NextRouter } from 'next/router'
import React from 'react'

interface BBUIAlertConfirmProps {
    title: string
    text: string
    addModal: any
    cancelButtonText?: string
    onCancelPress?: () => void
    okButtonText?: string
    onOkPress?: () => void
    router: NextRouter
}

const BBUIAlertConfirm = ({
    title,
    text,
    addModal,
    cancelButtonText = 'Cancel',
    onCancelPress = () => {},
    okButtonText = 'Yes',
    onOkPress = () => {},
    router,
}: BBUIAlertConfirmProps) => {
    BBUIPush({
        path: BBUIAlertModal,
        isModal: true,
        addModal,
        router,
        state: {
            title,
            message: text,
            buttons: [
                {
                    testID: 'alert-cancel-button',
                    text: cancelButtonText,
                    onPress: () => {
                        onCancelPress()
                    },
                    style: 'cancel',
                },
                {
                    testID: 'alert-ok-button',
                    text: okButtonText,
                    onPress: () => {
                        onOkPress()
                    },
                },
            ],
            options: { cancelable: false },
        },
    })
}

export default BBUIAlertConfirm
