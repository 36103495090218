import React from 'react'
import { SubActionType } from './sub-actions/sub-action-types'
import SubActionsScreen from './sub-actions/sub-actions-screen'

const BigBearAppsSubActionsModalPage = ({
    componentId,
    title = '',
    hasModal = false,
    isModalOpen = false,
    onClose,
    actions,
}: {
    componentId?: string
    title?: string
    hasModal?: boolean
    isModalOpen?: boolean
    onClose?: () => void
    actions: SubActionType[]
}) => {
    return (
        <SubActionsScreen
            componentId={componentId}
            title={title}
            hasModal={hasModal}
            isModalOpen={isModalOpen}
            onClose={onClose}
            actions={actions}
        />
    )
}
export default BigBearAppsSubActionsModalPage
