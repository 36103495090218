import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { styled } from 'dripsy'
import { Text, TextInput, View } from 'react-native'
import ColorPalette from 'react-native-color-palette'

const Container = styled(View)({
    flex: 0,
    marginLeft: 10,
    marginRight: 10,
    marginBottom: 10,
})

const TextInputContainer = styled(View)({})
const TextInputField = styled(TextInput)({
    backgroundColor: '#fff',
    padding: 10,
    borderWidth: 1,
    borderColor: '#a2b09d',
    borderRadius: 5,
    fontSize: 15,
    color: '#363636',
})

const ErrorContainer = styled(View)({})
const ErrorText = styled(Text)({
    paddingTop: 10,
    fontSize: 15,
    color: 'red',
})

const TitleContainer = styled(View)({
    paddingBottom: 10,
})
const TitleText = styled(Text)({
    fontSize: 18,
    fontWeight: 'bold',
    color: '#333',
})

interface Props {
    testID?: string
    title: string
    fieldName: string
    colors?: string[]
}

const BBUIFormColor: React.FC<Props> = ({
    testID = 'color-input',
    title,
    fieldName,
    colors = [],
}) => {
    const {
        control,
        formState: { errors },
        setValue,
    } = useFormContext()
    return (
        <Container>
            <TitleContainer>
                <TitleText>{title}</TitleText>
            </TitleContainer>
            <TextInputContainer>
                <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                        <ColorPalette
                            onChange={color => onChange(color)}
                            value={value}
                            colors={colors}
                            title={null}
                            scaleToWindow={true}
                        />
                    )}
                    name={fieldName}
                />
            </TextInputContainer>
            <ErrorContainer>
                {errors[fieldName] && (
                    <ErrorText ellipsizeMode="tail" numberOfLines={2}>
                        {errors[fieldName]?.message}
                    </ErrorText>
                )}
            </ErrorContainer>
        </Container>
    )
}

export default BBUIFormColor
