import { Controller, useFormContext } from 'react-hook-form'
import React, { useEffect, useRef } from 'react'
import { Text, TextInput, View } from 'react-native'

import { BBUIGetAuthToken } from '../..'
import { Editor } from '@tinymce/tinymce-react'
import { styled } from 'dripsy'
import { toast } from 'react-toastify'

const request = require('superagent')

const ParentContainer = styled(View)({})
const Container = styled(View)({
    flex: 1,
    marginLeft: 10,
    marginRight: 10,
    marginBottom: 10,
})

const TextInputContainer = styled(View)({})
const TextInputField = styled(TextInput)({
    backgroundColor: '#fff',
    padding: 10,
    borderWidth: 1,
    borderColor: '#a2b09d',
    borderRadius: 5,
    fontSize: 15,
})

const ErrorContainer = styled(View)({})
const ErrorText = styled(Text)({
    paddingTop: 10,
    fontSize: 15,
    color: 'red',
})

const TitleContainer = styled(View)({
    paddingBottom: 10,
})
const TitleText = styled(Text)({
    fontSize: 18,
    fontWeight: 'bold',
    color: '#333',
})

interface Props {
    testID?: string
    title: string
    fieldName: string
}

const BBUIFormTinymce: React.FC<Props> = ({
    testID = 'input',
    title,
    fieldName,
}) => {
    const [isLoading, setIsLoading] = React.useState(true)
    const toastId = useRef(null)
    const editorRef = useRef(null)

    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false)
        }, 300)
    }, [])

    const {
        control,
        formState: { errors },
        setValue,
    } = useFormContext()

    const startUpload = () => {
        toastId.current = toast('Uploading file...', {
            autoClose: false,
            closeButton: false, // Remove the closeButton
        })
    }

    const update = progress => {
        toast.update(toastId.current, {
            type: toast.TYPE.INFO,
            progress,
        })
    }

    const done = () => {
        toast.done(toastId.current)
    }

    return (
        <ParentContainer>
            <Container>
                <TitleContainer>
                    <TitleText>{title}</TitleText>
                </TitleContainer>
                <TextInputContainer>
                    <Controller
                        control={control}
                        render={({ field: { onChange, onBlur, value } }) => (
                            <Editor
                                apiKey="tlgivm25xe6y47zh82w6k2goexv70yexx7jd3kgg0xb2b8k7"
                                value={value}
                                id={testID}
                                onInit={(evt, editor) =>
                                    (editorRef.current = editor)
                                }
                                onEditorChange={(newText, editor) => {
                                    if (!isLoading) {
                                        onChange(newText)
                                    }
                                }}
                                init={{
                                    menubar: false,
                                    plugins:
                                        'link image code autosave advlist fullscreen wordcount textcolor colorpicker paste',
                                    toolbar:
                                        'undo redo | bold italic strikethrough forecolor backcolor fontsize removeformat h1 h2 h3 | link image | alignleft aligncenter alignright alignjustify | code restoredraft',
                                    font_size_formats:
                                        '8pt 10pt 12pt 14pt 16pt 18pt 24pt 36pt 48pt',
                                    branding: false,
                                    autosave_interval: '20s',
                                    height: 400,
                                    paste_data_images: true,
                                    images_upload_handler: (
                                        blobInfo,
                                        progress,
                                    ) =>
                                        new Promise((resolve, reject) => {
                                            const xhr = new XMLHttpRequest()
                                            xhr.withCredentials = false
                                            xhr.open(
                                                'POST',
                                                `${process.env.NEXT_PUBLIC_APP_BASE_URL}/wealtor/api/v1/upload/tinymce`,
                                            )

                                            xhr.setRequestHeader(
                                                'Authorization',
                                                `Bearer ${BBUIGetAuthToken()}`,
                                            )

                                            xhr.upload.onprogress = e => {
                                                progress(
                                                    (e.loaded / e.total) * 100,
                                                )
                                            }

                                            xhr.onload = () => {
                                                if (xhr.status === 403) {
                                                    reject({
                                                        message:
                                                            'HTTP Error: ' +
                                                            xhr.status,
                                                        remove: true,
                                                    })
                                                    return
                                                }

                                                if (
                                                    xhr.status < 200 ||
                                                    xhr.status >= 300
                                                ) {
                                                    reject(
                                                        'HTTP Error: ' +
                                                            xhr.status,
                                                    )
                                                    return
                                                }

                                                const json = JSON.parse(
                                                    xhr.responseText,
                                                )

                                                if (
                                                    !json ||
                                                    typeof json.location !=
                                                        'string'
                                                ) {
                                                    reject(
                                                        'Invalid JSON: ' +
                                                            xhr.responseText,
                                                    )
                                                    return
                                                }

                                                console.log(json.location)

                                                resolve(json.location)
                                            }

                                            xhr.onerror = () => {
                                                reject(
                                                    'Image upload failed due to a XHR Transport error. Code: ' +
                                                        xhr.status,
                                                )
                                            }

                                            const formData = new FormData()
                                            formData.append(
                                                'file',
                                                blobInfo.blob(),
                                                blobInfo.filename(),
                                            )

                                            xhr.send(formData)
                                        }),
                                }}
                            />
                        )}
                        name={fieldName}
                    />
                </TextInputContainer>
                <ErrorContainer>
                    {errors[fieldName] && (
                        <ErrorText ellipsizeMode="tail" numberOfLines={2}>
                            {errors[fieldName]?.message}
                        </ErrorText>
                    )}
                </ErrorContainer>
            </Container>
        </ParentContainer>
    )
}

export default BBUIFormTinymce
