import { Theme } from '@dripsy/core'
import { theme } from './theme'

// make sure you don't mutate the original theme
export const bigbearmomentsTheme: Theme = { ...theme }

bigbearmomentsTheme.colors = {
    $background: '#CC432F',
    $authButtonText: '#fff',
    $authButton: '#CC432F',
}
