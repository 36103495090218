import React from 'react'
import { View } from 'react-native'
import { styled } from 'dripsy'

const StyledBody = styled(View)({
    flex: 1,
    flexDirection: 'column',
})

const StyledBodyWithoutQuickActions = styled(View)({
    flex: 1,
    flexDirection: 'column',
    marginTop: 15,
})

const Overlay = styled(View)({
    flex: 1,
    backgroundColor: 'white',
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    marginLeft: 10,
    marginRight: 10,
})

interface Props {
    children: React.ReactNode
    withOutQuickActions?: boolean
}

const BBUIContentHome: React.FC<Props> = ({
    children,
    withOutQuickActions = false,
}) => {
    if (withOutQuickActions) {
        return (
            <StyledBodyWithoutQuickActions>
                <Overlay>{children}</Overlay>
            </StyledBodyWithoutQuickActions>
        )
    }
    return (
        <StyledBody>
            <Overlay>{children}</Overlay>
        </StyledBody>
    )
}

export default BBUIContentHome
