import { BBUIPush, BigBearAppsSubActionsModalPage } from '../..'
import { Controller, useFormContext } from 'react-hook-form'
import { Text, TextInput, TouchableOpacity, View } from 'react-native'

import React from 'react'
import collect from 'collect.js'
import { styled } from 'dripsy'
import useBBUINiceModalStore from '../bbui-nice-modal-store/bbui-nice-modal-store'
import { useRouter } from 'next/router'

const getLabel = (options, value) => {
    if (value === null) {
        return collect(options).whereNull('value').first()
    }
    return collect(options).where('value', '==', value).first()
}

const Container = styled(View)({
    flex: 0,
    marginLeft: 10,
    marginRight: 10,
    marginBottom: 10,
})

const TextInputContainer = styled(View)({})
const TextInputField = styled(TextInput)({
    backgroundColor: '#fff',
    padding: 10,
    borderWidth: 1,
    borderColor: '#a2b09d',
    borderRadius: 5,
    fontSize: 15,
    color: '#363636',
})

const ErrorContainer = styled(View)({})
const ErrorText = styled(Text)({
    paddingTop: 10,
    fontSize: 15,
    color: 'red',
})

const TitleContainer = styled(View)({
    paddingBottom: 10,
})
const TitleText = styled(Text)({
    fontSize: 18,
    fontWeight: 'bold',
    color: '#333',
})

const ButtonContainer = styled(TouchableOpacity)({
    alignItems: 'center',
    borderColor: '#a2b09d',
    borderWidth: 1,
    padding: 10,
    borderRadius: 5,
    backgroundColor: '#fff',
})
const ButtonText = styled(Text)({
    fontSize: 15,
    fontWeight: 'bold',
    color: '#333',
})

interface Option {
    testID?: string
    label: string
    name: string
    description?: string
    value: string | number | boolean
    img?: string
    style?: {
        fontFamily: string
    }
    styles?: {
        fontFamily: string
    }
}

interface Props {
    componentId?: string
    testID?: string
    title: string
    fieldName: string
    options: Option[]
    onChangeValue?: (value: any) => void
}

const BBUIFormSelect: React.FC<Props> = ({
    componentId,
    testID,
    title,
    fieldName,
    options,
    onChangeValue = value => {},
}) => {
    const {
        control,
        formState: { errors },
    } = useFormContext()
    const { addModal, hideModal } = useBBUINiceModalStore()
    const router = useRouter()

    return (
        <Container>
            <TitleContainer>
                <TitleText>{title}</TitleText>
            </TitleContainer>
            <TextInputContainer>
                <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                        <ButtonContainer
                            testID={testID}
                            onPress={() => {
                                BBUIPush({
                                    componentId,
                                    path: BigBearAppsSubActionsModalPage,
                                    reactNativePath:
                                        'SubActionsModalPage.screenName',
                                    isModal: true,
                                    addModal,
                                    router,
                                    state: {
                                        title,
                                        actions: [
                                            ...options.map(option => ({
                                                testID: option.testID,
                                                title: option.label,
                                                description: option.description,
                                                onPress: ({
                                                    componentIdAction,
                                                }) => {
                                                    onChange(option.value)
                                                    onChangeValue(option.value)
                                                    hideModal(componentIdAction)
                                                },
                                            })),
                                        ],
                                    },
                                })
                            }}>
                            <ButtonText>
                                {getLabel(options, value)?.label}
                            </ButtonText>
                        </ButtonContainer>
                    )}
                    name={fieldName}
                />
            </TextInputContainer>
            <ErrorContainer>
                {errors[fieldName] && (
                    <ErrorText ellipsizeMode="tail" numberOfLines={2}>
                        {errors[fieldName]?.message}
                    </ErrorText>
                )}
            </ErrorContainer>
        </Container>
    )
}

export default BBUIFormSelect
