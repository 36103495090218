import { Alert, Linking } from 'react-native'

import BBUIGetAuthToken from '../bbui-get-auth-token/bbui-get-auth-token'
import BBUIRedirectToWeb from '../bbui-redirect-to-web/bbui-redirect-to-web'

const openUrlInBrowser = url => {
    Linking.canOpenURL(url).then(supported => {
        if (supported) {
            Linking.openURL(url)
        } else {
            Alert.alert(
                'Error opening url',
                `Please just type in or paste ${url} in your browser of choice!`,
                [
                    {
                        text: 'Copy & close',
                        onPress: () => {
                            // copy
                        },
                    },
                ],
                { cancelable: false },
            )
        }
    })
}

const BBUIGoToWebApp = async moduleUrl => {
    // const home_url = "/" + window.location.pathname.split("/")[1];
    const home_url = '/'
    const token = await BBUIGetAuthToken()
    const url = BBUIRedirectToWeb(`https://${home_url}/${moduleUrl}`, token)

    openUrlInBrowser(url)
}

export default BBUIGoToWebApp
