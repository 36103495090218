import { Text, TouchableOpacity, View } from 'react-native'

import React from 'react'
import { styled } from 'dripsy'

const Container = styled(View)({
    marginLeft: 10,
    marginRight: 10,
    flexDirection: 'column',
})

const TitleContainer = styled(View)({
    marginTop: 10,
    marginBottom: 10,
})
const TitleText = styled(Text)({
    color: '#fff',
    fontSize: 18,
    fontWeight: 'bold',
})

const ListContainer = styled(View)({
    backgroundColor: '#fff',
    borderRadius: 5,
    paddingTop: 15,
    paddingLeft: 10,
    paddingRight: 10,
})

const ListItemContainer = styled(TouchableOpacity)({
    flexDirection: 'row',
    paddingBottom: 10,
})
const ListIconContainer = styled(View)({
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 10,
})
const ListItemContentContainer = styled(View)({
    flex: 1,
    flexDirection: 'column',
})
const ListItemTitleText = styled(Text)({
    fontSize: 16,
    fontWeight: 'bold',
})
const ListItemDescriptionText = styled(Text)({
    fontSize: 14,
    color: '#999',
})

const ListRight = styled(View)({
    alignItems: 'center',
    justifyContent: 'center',
})
const ListRightIcon = styled(View)({})

interface Props {
    componentId: string | undefined
    title: string
    data: any
    children
}

const BBUISearchRecentlyUsed: React.FC<Props> = ({
    componentId,
    title = '',
    data,
    children,
}) => {
    return (
        <Container>
            <TitleContainer>
                <TitleText>{title}</TitleText>
            </TitleContainer>
            <ListContainer>
                {data.map((item, index) => {
                    return children({ item, index })
                })}
            </ListContainer>
        </Container>
    )
}

export default BBUISearchRecentlyUsed
