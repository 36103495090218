import { BBUIBack, BBUIBackForm, BBUITopbarForm } from '../..'
import React, { useState } from 'react'

import useBBUINiceModalStore from '../bbui-nice-modal-store/bbui-nice-modal-store'
import { useFormContext } from 'react-hook-form'
import { useQueryClient } from '@tanstack/react-query'
import { useRouter } from 'next/router'

interface Props {
    componentId?: string
    onSubmit: (data: any) => void
    isLoading: boolean
    title?: string
}

const BBUIPromptTopbar: React.FC<Props> = ({
    componentId,
    onSubmit,
    isLoading,
    title,
}) => {
    const router = useRouter()
    const { addModal, hideModal } = useBBUINiceModalStore()

    const [isLeavingForm, setIsLeavingForm] = useState(false)
    const queryCache = useQueryClient()

    const {
        handleSubmit,
        formState: { isDirty },
    } = useFormContext()

    return (
        <BBUITopbarForm
            icon="pencil-alt"
            title={title}
            isSaving={isLoading}
            isFormDirty={isDirty}
            onSavePress={handleSubmit(onSubmit)}
            onCancelPress={() => {
                BBUIBack(componentId, router)
            }}
            onBackPress={() => {
                BBUIBackForm({
                    componentId,
                    isFormDirty: isDirty,
                    router,
                    addModal,
                })
            }}
            // onPressHelp={() => {
            //   BBUIGoToWebUrl(
            //     `https://bigbeartechhub.com/books/boxinvy/page/brands#bkmrk-create-brand-screen`,
            //   );
            // }}
        />
    )
}

export default BBUIPromptTopbar
