import { makeTheme } from '@dripsy/core'

const theme = makeTheme({
    // your theme
    colors: {
        $background: '#fff',
        $authButtonText: '#fff',
        $authButton: '#804004',
    },
    types: {
        reactNativeTypesOnly: true,
    },
})

type MyTheme = typeof theme

declare module 'dripsy' {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface DripsyCustomTheme extends MyTheme {
        // your theme
        colors: {
            $background: string
            $authButtonText: string
            $authButton: string
        }
    }
}

const ToastTheme = {
    space: [0, 4, 8, 12, 16, 20, 24, 32, 40, 48],
    colors: {
        text: '#0A0A0A',
        background: '#FFF',
        border: '#E2E8F0',
        muted: '#F0F1F3',
        success: '#7DBE31',
        error: '#FC0021',
        info: '#00FFFF',
    },
}

export { theme, ToastTheme }
