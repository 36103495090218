import React from 'react'
import { styled } from 'dripsy'
import { Text, TouchableOpacity } from 'react-native'

const MarginLeftDecider = (props: { side: 'full' | 'left' | 'right' }) => {
    if (props.side === 'full') {
        return 10
    } else if (props.side === 'left') {
        return 10
    }
    return 0
}
const MarginRightDecider = (props: { side: 'full' | 'left' | 'right' }) => {
    if (props.side === 'full') {
        return 10
    } else if (props.side === 'right') {
        return 10
    }
    return 0
}

const StyledButton = styled(TouchableOpacity)(
    (props: { side: 'full' | 'left' | 'right'; hasTopRounded: boolean }) => ({
        backgroundColor: 'white',
        alignItems: 'center',
        paddingTop: 13,
        paddingBottom: 13,
        flex: props.side === 'left' || props.side === 'right' ? 1 : 0,
        marginLeft: MarginLeftDecider(props),
        marginRight: MarginRightDecider(props),
        borderTopLeftRadius: props.hasTopRounded ? 4 : 0,
        borderTopRightRadius: props.hasTopRounded ? 4 : 0,
        borderColor: '#e6e6e6',
        borderWidth: 1,
    }),
)

const StyledButtonText = styled(Text)({
    fontSize: 18,
})

interface Props {
    testID?: string
    title?: string
    onPress: () => void
    hasTopRounded?: boolean
    side?: 'full' | 'left' | 'right'
}

// this is used on list views

const BBUIContentFilterButton: React.FC<Props> = ({
    testID = 'sort-by-button',
    title = 'Filter',
    onPress = () => {},
    hasTopRounded = true,
    side = 'full',
}) => {
    return (
        <StyledButton
            testID={testID}
            onPress={onPress}
            hasTopRounded={hasTopRounded}
            side={side}>
            <StyledButtonText>{title}</StyledButtonText>
        </StyledButton>
    )
}

export default BBUIContentFilterButton
